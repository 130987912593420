import {Icon} from "../../../../common";
import {ROUTE_REDIRECT} from "../../../../../constants/define";
import React from "react";
import {moneyFormatter} from "../../../../../utils/commonUtils";

export const properties = [
    {
        title: "select/unselect all",
        type: "checkbox",
    },
    {
        title: "Rank",
        type: "image",
        key: "uid",
    },
    {
        title: "Address",
        type: "street_name",
        childs: [
            {
                title: "City",
                key: "city",
            },
            {
                title: "Zipcode",
                key: "zipcode",
            },
            {
                title: "County",
                key: "county",
            },
        ],
    },
    {
        title: "Property Type",
        key: "prop_type",
        childs: [
            {
                title: "House type",
                key: "house_type",
            },
            {
                title: "House style",
                key: "house_style",
            },
            {
                title: "HOA frequency / dues",
                key: ["hoa_frequency", "hoa_amount"],
            },
        ],
    },
    {
        title: "Price per square foot",
        type: "price_per_square_foot",
    },
    {
        title: "Distance (miles)",
        type: "distance",
    },
    {
        title: "Recency (days)",
        type: "recency_day",
    },
    {
        title: "Subdivision",
        key: "subdivision",
    },
    {
        title: "School district",
        key: "school_district",
        childs: [
            {
                title: "Elementary School",
                key: "school_name1",
            },
            {
                title: "Middle School",
                key: "school_name2",
            },
            {
                title: "High School",
                key: "school_name3",
            },
        ],
    },
    {
        title: "Days on market",
        key: "dom",
    },
    {
        title: "Seller type",
        key: "seller_type",
    },
    {
        title: "List Price",
        key: "list_price",
        type: "money",
        childs: [
            {
                title: "List Date",
                key: "list_date",
                type: "date"
            },
            {
                title: "Original list price",
                key: "list_price_orig",
                type: "money"
            },
            {
                title: "List price to original list price",
                key: "list_price_orig",
                type: "list_price_orig"
            },
        ],
    },
    {
        title: "Sold Price",
        key: "sold_price",
        type: "money",
        childs: [
            {
                title: "Sold Date",
                key: "sold_date",
                type: "date"
            },
        ],
    },
    {
        title: "Seller Contribution",
        key: "seller_contribution",
        null: "N/A"
    },
    {
        title: "Year built",
        key: "year_built",
    },

    {
        title: "Construction type",
        key: "construction_type",
        childs: [
            {
                title: "Roof",
                key: "roof",
            },
            {
                title: "Foundation",
                key: "foundation",
            },
        ],
    },
    {
        title: 'Square feet',
        key: 'square_feet'
    },
    {
        title: 'Bed /bath',
        key: ['bedroom', 'bathroom_total']
    },
    {
        title: 'Stories',
        key: 'storie'
    },
    {
        title: 'Pool',
        key: 'pool'
    },
    {
        title: 'Grage / carport',
        key: ['garage', 'carport']
    },
    {
        title: 'Fireplace',
        key: 'fireplace'
    },
    {
        title: 'Lot size',
        key: 'acre',
        childs: [
            {
                title: 'Lot size (acres)',
                key: 'acres'
            },
            {
                title: 'Lot description',
                key: 'lot_description'
            }
        ]
    },
    {
        title: 'MLS #',
        key: 'mls_num',
        childs: [
            {
                title: 'Public remark',
                key: 'public_remark'
            },
            {
                title: 'Agent Name',
                key: 'listing_agent_name'
            },
            {
                title: 'Agent Phone Number',
                key: 'agent_listing_appt_phone'
            },
            {
                title: 'Showing instruction',
                key: 'showing_instruction'
            }
        ]
    }
];

export const FIELD_COMPARA = [
    // {
    //     title: "Rank",
    //     type: "image",
    //     key: "uid",
    //     fixed: 'left',
    // },
    {
        title: "Address",
        type: "street_name",
        dataIndex: "street_name",
        isNote: true
    },
    {
        title: "City",
        key: "city",
        dataIndex: "city",
    },
    {
        title: "Zipcode",
        key: "zipcode",
        dataIndex: "zipcode",
    },
    {
        title: "County",
        key: "county",
        dataIndex: "county",
    },
    {
        title: "Property Type",
        key: "prop_type",
        dataIndex: "prop_type",
    },
    {
        title: "House type",
        key: "house_type",
        dataIndex: "house_type",
    },
    {
        title: "House style",
        key: "house_style",
        dataIndex: "house_style",
    },
    {
        title: "HOA frequency / dues",
        key: ["hoa_frequency", "hoa_amount"],
        dataIndex: "hoa_frequency",
        isNote: true
    },
    {
        title: "Price per square foot",
        type: "price_per_square_foot",
        dataIndex: "price_per_square_foot",
        isNote: true
    },
    {
        title: "Distance (miles)",
        type: "distance",
        dataIndex: "distance",
        isNote: true
    },
    {
        title: "Recency (days)",
        type: "recency_day",
        dataIndex: "recency_day",
        isNote: true
    },
    {
        title: "Subdivision",
        key: "subdivision",
        dataIndex: "subdivision",
    },
    {
        title: "School district",
        key: "school_district",
        dataIndex: "school_district",
    },
    {
        title: "Elementary School",
        key: "school_name1",
        dataIndex: "school_name1",
    },
    {
        title: "Middle School",
        key: "school_name2",
        dataIndex: "school_name2",
    },
    {
        title: "High School",
        key: "school_name3",
        dataIndex: "school_name3",
    },
    {
        title: "Days on market",
        key: "dom",
        dataIndex: "dom",
    },
    {
        title: "Seller type",
        key: "seller_type",
        dataIndex: "seller_type",
    },
    {
        title: "List Price",
        key: "list_price",
        dataIndex: "list_price",
        type: "money",
        isNote: true
    },
    {
        title: "List Date",
        key: "list_date",
        dataIndex: "list_date",
        type: "date",
        isNote: true
    },
    {
        title: "Original list price",
        key: "list_price_orig",
        dataIndex: "list_price_orig",
        type: "money",
        isNote: true
    },
    {
        title: "List price to original list price",
        key: "list_price_orig",
        dataIndex: "list_price_orig",
        type: "list_price_orig",
        isNote: true
    },
    {
        title: "Sold Price",
        key: "sold_price",
        dataIndex: "sold_price",
        type: "money",
        isNote: true
    },
    {
        title: "Sold Date",
        key: "sold_date",
        dataIndex: "sold_date",
        type: "date",
        isNote: true
    },
    {
        title: "Seller Contribution",
        key: "seller_contribution",
        dataIndex: "seller_contribution",
        null: "N/A",
        isNote: true
    },
    {
        title: "Year built",
        key: "year_built",
        dataIndex: "year_built",
    },

    {
        title: "Construction type",
        key: "construction_type",
        dataIndex: "construction_type",
    },
    {
        title: "Roof",
        key: "roof",
        dataIndex: "roof",
    },
    {
        title: "Foundation",
        key: "foundation",
        dataIndex: "foundation",
    },
    {
        title: 'Square feet',
        key: 'square_feet',
        dataIndex: 'square_feet'
    },
    {
        title: 'Bed /bath',
        key: ['bedroom', 'bathroom_total'],
        dataIndex: "bedroom",
        isNote: true
    },
    {
        title: 'Stories',
        key: 'storie',
        dataIndex: 'storie'
    },
    {
        title: 'Pool',
        key: 'pool',
        dataIndex: 'pool'
    },
    {
        title: 'Grage / carport',
        key: ['garage', 'carport'],
        dataIndex: "garage",
        isNote: true
    },
    {
        title: 'Fireplace',
        key: 'fireplace',
        dataIndex: 'fireplace'
    },
    {
        title: 'Lot size',
        key: 'acre',
        dataIndex: 'acre',
    },
    {
        title: 'Lot size (acres)',
        key: 'acres',
        dataIndex: 'acres'
    },
    {
        title: 'Lot description',
        key: 'lot_description',
        dataIndex: 'lot_description'
    },
    // {
    //     title: 'MLS #',
    //     key: 'mls_num',
    //     dataIndex: 'mls_num',
    // },
    // {
    //     title: 'Public remark',
    //     key: 'public_remark',
    //     dataIndex: 'public_remark'
    // },
    {
        title: 'Agent Name',
        key: 'listing_agent_name',
        dataIndex: 'listing_agent_name'
    },
    {
        title: 'Agent Phone Number',
        key: 'agent_listing_appt_phone',
        dataIndex: 'agent_listing_appt_phone'
    },
    {
        title: 'Showing instruction',
        key: 'showing_instruction',
        dataIndex: 'showing_instruction'
    }
]

export const COLUMN_PRIMARY = [
    {
        title: "Properties",
        type: "street_name",
        dataIndex: "street_name",
        isNote: true,
        render: (text, record) => [record["street_box_num"], record["street_name"], record["street_type"]].join(' '),
    },
    {
        title: "Price per square foot",
        type: "price_per_square_foot",
        dataIndex: "price_per_square_foot",
        isNote: true,
        sorter: true,
        render: (text, record) => <span><span
            className="currency">$</span>{Math.round((record['sold_price'] || record['list_price']) / record['square_feet'] * 100) / 100}</span>,
    },
    {
        title: "Distance (miles)",
        type: "distance",
        dataIndex: "distance",
        isNote: true,
        sorter: true,
        render: (text, record) => Math.round((record['dist'] || 0) * 0.00062137 * 100) / 100,
    },
    {
        title: "Recency (days)",
        type: "recency_day",
        dataIndex: "recency_day",
        sorter: true,
        render: (text, record) => Math.round((((new Date()) - (new Date(record['sold_date'] || record['modified_date']))) / (24 * 60 * 60 * 1000))),
    },
    {
        title: "Subdivision",
        key: "subdivision",
        dataIndex: "subdivision",
        render: (text, record) => text ? text : "N/A"
    },
    {
        title: "Days on market",
        key: "dom",
        dataIndex: "dom",
        sorter: true,
    },
    {
        title: "Sold Price",
        key: "sold_price",
        dataIndex: "sold_price",
        sorter: true,
        render: (text, record) => moneyFormatter.format(text)
    },
]
export const COLUMN_SECONDARY = [
    {
        title: "Properties",
        type: "street_name",
        dataIndex: "street_name",
        isNote: true,
        render: (text, record) => [record["street_box_num"], record["street_name"], record["street_type"]].join(' '),
    },
    {
        title: "School district",
        key: "school_district",
        dataIndex: "school_district",
        render: (text, record) => text ? text : "N/A",
    },
    {
        title: "Seller Contribution",
        key: "seller_contribution",
        dataIndex: "seller_contribution",
        null: "N/A",
        render: (text, record) => text ? text : "N/A",
        isNote: true
    },
    {
        title: "List Price & Date",
        key: "list_price",
        dataIndex: "list_price",
        type: "money",
        render: (text, record) => `${moneyFormatter.format(text)} (${record?.list_date})`,
    },
    {
        title: "Original List Price",
        key: "list_price_orig",
        dataIndex: "list_price_orig",
        type: "money",
        render: (text, record) => moneyFormatter.format(text)
    },
    {
        title: "Seller type",
        key: "seller_type",
        dataIndex: "seller_type",
        render: (text, record) => text ? text : "N/A"
    },
    {
        title: "Sold Price & Date",
        key: "sold_price",
        dataIndex: "sold_price",
        type: "money",
        isNote: true,
        render: (text, record) => `${moneyFormatter.format(text)} (${record?.sold_date})`,
    },
]
export const HOUSE_CHARACTERISTICS = [
    {
        title: "Properties",
        type: "street_name",
        dataIndex: "street_name",
        isNote: true,
        render: (text, record) => [record["street_box_num"], record["street_name"], record["street_type"]].join(' '),
    },
    {
        title: "Year built",
        key: "year_built",
        dataIndex: "year_built",
    },
    {
        title: 'Square feet',
        key: 'square_feet',
        dataIndex: 'square_feet'
    },
    {
        title: 'Bed /bath',
        key: ['bedroom', 'bathroom_total'],
        dataIndex: "bedroom",
        isNote: true,
        render: (text, record) => `${text}/${record?.bathroom_total}`,
    },
    {
        title: 'Stories',
        key: 'storie',
        dataIndex: 'storie',
        render: (text, record) => text ? text : "N/A"
    },
    {
        title: 'Pool',
        key: 'pool',
        dataIndex: 'pool'
    },
    {
        title: 'Grage / carport',
        key: ['garage', 'carport'],
        dataIndex: "garage",
        isNote: true,
        render: (text, record) => `${text}/${record?.carport}`,
    },
    {
        title: "Construction type",
        key: "construction_type",
        dataIndex: "construction_type",
    },
    {
        title: "Roof",
        key: "roof",
        dataIndex: "roof",
    },
    {
        title: "Foundation",
        key: "foundation",
        dataIndex: "foundation",
    },
    {
        title: 'Fireplace',
        key: 'fireplace',
        dataIndex: 'fireplace',
        render: (text, record) => text ? text : "N/A"
    },
    {
        title: 'Lot size',
        key: 'acre',
        dataIndex: 'acre',
    },
    {
        title: 'Lot description',
        key: 'lot_description',
        dataIndex: 'lot_description'
    }
]

export const TAB_COMPARABLES = [
    {
        title: "Primary information",
        key: "primary",
        column: COLUMN_PRIMARY
    }, {
        title: "Secondary information",
        key: "secondary",
        column: COLUMN_SECONDARY
    }, {
        title: "House characteristics",
        key: "house",
        column: HOUSE_CHARACTERISTICS
    }, {
        title: "MLS",
        key: "mls",
        column: []
    },
]

