import { useCallback } from "react";
import { useDispatch } from "react-redux";

import * as HookAction from "../actions/fastCMA";

export const ActionAppendResponse = () => {
    const dispatch = useDispatch()
    return useCallback(
        (filters) => dispatch(HookAction.find_comparables_success(filters)),
        [dispatch]
    )
}

export const ActionInit = () => {
    const dispatch = useDispatch()
    return useCallback(
        (filters) => dispatch(HookAction.cmd_init(filters)),
        [dispatch]
    )
}
