import {pick} from "lodash";
import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define"
import {convertBooleanToInt, formatInt, removeObjectNullFull} from "../../../utils/functions";

export default class ComparablesRequestRequest {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
    }
}

export const common = {
    ACTION_FIELD: {
        name: "name",
    },
}

export const filtersParams = (filters) => {
    return removeObjectNullFull({
        address: filters?.address || "",
        source: filters?.source || "",
        page_size: filters?.page_size || 10,
        page_index: filters?.page_index || 1,
    })
}

