export const GG_MAP_LOOKUP = "GG_MAP_LOOKUP";

export const ADDRESS_LOOKUP = "ADDRESS_LOOKUP";
export const ADDRESS_LOOKUP_RESET = "ADDRESS_LOOKUP_RESET";
export const ADDRESS_LOOKUP_SUCCESS = "ADDRESS_LOOKUP_SUCCESS";
export const ADDRESS_LOOKUP_FAIL = "ADDRESS_LOOKUP_FAIL";

export const FILTER_COMPARABLES = "FILTER_COMPARABLES";
export const FIND_COMPARABLES = "FIND_COMPARABLES";
export const FIND_COMPARABLES_SUCCESS = "FIND_COMPARABLES_SUCCESS";
export const FIND_COMPARABLES_ERROR = "FIND_COMPARABLES_ERROR";

export const CHANGE_MARKET_CHANNEL = "CHANGE_MARKET_CHANNEL";

export const MLS_LOOKUP = "MLS_LOOKUP";
export const SWITCH_TYPE = "SWITCH_TYPE";
export const GOOGLE_SPACES_SESSION_TOKEN = "GOOGLE_SPACES_SESSION_TOKEN";

export const RUN_CMA = "RUN_CMA";
export const CMA_SET_ACTIVE_STATUS = "CMA_SET_ACTIVE_STATUS";
export const CMA_SET_SELECTED_PROPERTY = "CMA_SET_SELECTED_PROPERTY";

export const SHOW_CMA_REPORTS = "SHOW_CMA_REPORTS";
export const EXPORT_CMA_REPORT_PDF = "EXPORT_CMA_REPORT_PDF";
export const EXPORT_CMA_ERROR = "EXPORT_CMA_ERROR";

export const CMA_LOAD_PROFILES = "CMA_LOAD_PROFILES";
export const CMA_LOAD_PROFILES_SUCCESS = "CMA_LOAD_PROFILES_SUCCESS";
export const CMA_LOAD_PROFILES_FAIL = "CMA_LOAD_PROFILES_FAIL";
export const CMA_SELECT_PROFILE = "CMA_SELECT_PROFILE";
export const CMA_UPDATE_PROFILE = "CMA_UPDATE_PROFILE";
export const CMA_UPDATE_PROFILE_SUCCESS = "CMA_UPDATE_PROFILE_SUCCESS";
export const CMA_UPDATE_PROFILE_FAIL = "CMA_UPDATE_PROFILE_FAIL";
export const CMA_UPDATE_PROFILE_ERROR = "CMA_UPDATE_PROFILE_ERROR";

export const CMA_DELETE_PROFILE = "CMA_DELETE_PROFILE";
export const CMA_DELETE_PROFILE_SUCCESS = "CMA_DELETE_PROFILE_SUCCESS"
export const CMA_DELETE_PROFILE_FAIL = "CMA_DELETE_PROFILE_FAIL"

export const CMA_INIT = "CMA_INIT"
