import {all} from 'redux-saga/effects';
import {authSaga} from './authSaga';
import {fastCMASaga} from './fastCMASaga'
import {comparablesRequestSaga} from './comparablesRequestSaga'

export default function* rootSaga() {
    yield all([
        authSaga(),
        fastCMASaga(),
        comparablesRequestSaga(),
    ])
}
