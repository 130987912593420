import HeaderAction from "../../common/layout/HeaderAction";
import {Button, Form, Input, Space} from "antd"
import {ActionGetItem, ActionGetList} from "../../../data/hooks/comparablesRequest";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {
    selectFetching,
    selectItem,
    selectList,
    selectStatusAction
} from "../../../data/reselects/comparablesRequestSelector";
import ComparablesRequestResponse, {
    column,
    filtersResponse
} from "../../../data/mapping/Response/ComparablesRequestResponse";
import {
    columnsTable,
    genaratePaginateFilterSort,
    getDataSource,
    getTitleTable, removeObjectNullFull,
    setParamsUrlFilter
} from "../../../utils/functions";
import {ROUTE_REDIRECT} from "../../../constants/define";
import ListComponent from "../../common/ListComponent";
import {omit} from "lodash";
import queryString from "query-string";
import {Icon} from "../../common";

const ModelFields = Object.getOwnPropertyNames(new ComparablesRequestResponse());
const Index = props => {
    const namePage = "comparables_request",
        [initData, setInitData] = useState({
            titleItemTable: getTitleTable(column, ['action'], []),
            modelItem: ModelFields,
        }),
        [paramsFilters, setPramsFilters] = useState({}),
        componentTable = {
            action: {
                width: 100,
                align: 'center',
                key: 'action',
                fixed: 'right',
                render: (text, record) => <Icon type="eye-outlined" className="cursorPointer"
                                                onClick={() => (record?.status === 2) ? props.history.push((ROUTE_REDIRECT.COMPARABLES_REQUEST.UPDATE).replace(":id", record.id)) : {}}/>,
            }
        };
    const [form] = Form.useForm(),
        [isFetching, setIsFetching] = useState(false),
        [resultList, setResultList] = useState([]),
        [pagination, setPagination] = useState({}),
        actionGetList = ActionGetList(),
        actionGetItem = ActionGetItem(),
        itemList = useSelector(selectList()),
        itemFetching = useSelector(selectFetching()),
        itemStatusAction = useSelector(selectStatusAction())

    useEffect(() => {
        setIsFetching(itemFetching)
    }, [itemFetching]);

    useEffect(() => {
        if (Object.keys(queryString.parse(props.location.search)).length > 0) {
            let paramsFilters = queryString.parse(props.location.search, {arrayFormat: 'comma'});
            paramsFilters = removeObjectNullFull(paramsFilters)
            setPramsFilters(paramsFilters)
            form.setFieldsValue(paramsFilters)
            actionGetList(paramsFilters);
        } else {
            actionGetList();
        }
    }, []);

    const onSearch = val => {
        let getFilters = filtersResponse(removeObjectNullFull({
            ...initData.filters, ...paramsFilters, ...val,
            page_index: 1
        }));
        setPramsFilters(getFilters);
        setInitData({...initData, filters: paramsFilters})
        props.history.replace(setParamsUrlFilter(ROUTE_REDIRECT.COMPARABLES_REQUEST.HOME, getFilters))
        actionGetList(removeObjectNullFull(getFilters));
    }

    const onTableChange = (pagination, filters, sorter) => {
        // let currentFilters = getCurrentFilters();
        filters = omit({...paramsFilters, ...filters}, ['page_index', 'page_size']);
        let newFilters = genaratePaginateFilterSort(pagination, filters, sorter);
        setPramsFilters(newFilters)
        setPramsFilters(newFilters)
        form.setFieldsValue(newFilters)
        setInitData({
            ...initData,
            filters: newFilters
        });
        props.history.replace(setParamsUrlFilter(ROUTE_REDIRECT.COMPARABLES_REQUEST.HOME, newFilters))
        actionGetList(newFilters);
    };

    useEffect(() => {
        setResultList(itemList?.result || [])
        setPagination({
            total: itemList?.pagination?.total || 0,
            totalPage: itemList?.pagination?.total_page || 0,
            defaultCurrent: itemList?.pagination?.page_index || 1,
            pageSize: itemList?.pagination?.page_size || 10
        })
    }, [itemList]);

    return (
        <>
            <HeaderAction
                isActionSignlePage
                title="Template List"
                disabledFormatUpperCaseTitle
                // isCreate
                // onClick={e => onCreate(e)}
                isCustomize
            />
            <p className="color--red text-end px-4 py-0 my-0">{itemList?.usage_count}</p>
            <div className="container-fluid px-4">
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onSearch}
                    // onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <div className="row bg-white py-1 align-items-center">
                        <div className="col-4">
                            <Form.Item
                                label="Address"
                                name="address"
                            >
                                <Input allowClear/>
                            </Form.Item>
                        </div>
                        <div className="col-12 text-end">
                            <Form.Item className="mb-0" label="">
                                <Button type="primary" htmlType="submit">
                                    Search
                                    {/*{isFetching && <Icon type="sync-outlined" spin/>}*/}
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            </div>
            <ListComponent
                scroll={{x: 1000}}
                classParent="m-0 p-0 col-md-12"
                className="custom-table"
                marginTop={'0'} marginBottom={'0'}
                loading={{spinning: isFetching, size: 'large'}}
                rowKey="id"
                dataSource={getDataSource(resultList || [], initData.modelItem)}
                columns={columnsTable({
                    titleTable: initData.titleItemTable,
                    component: componentTable,
                    itemName: namePage
                })}
                pagination={{
                    total: pagination.total,
                    totalPage: pagination.totalPage,
                    current: pagination.defaultCurrent,
                    pageSize: pagination.pageSize,
                    responsive: true
                }}
                onChange={onTableChange}
                componentTableList={{}}
            />
        </>
    )
}

export default Index
