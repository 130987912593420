import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define";
import {removeObjectNullFull} from "../../../utils/functions";
import {checkNumber} from "../../../utils/commonUtils";

export default class AuthResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.hash = data?.hash || "";
    }

    exportPasswordRequest() {
        return {
            hash: this.hash
        }
    }
}
