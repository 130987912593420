import React, {Component, useState} from "react";
import {Link, withRouter} from "react-router-dom";
import {Avatar, Button, Dropdown, Form, Input, Menu, message, Modal, Space} from "antd";
import * as LocalStorage from "../../../utils/localStorage";
import {CONFIG_LOCAL_STORAGE} from "../../../constants/define";
import {Icon} from "../index";
import {useSelector} from "react-redux";
import {getFetchingAuth, getProfile} from "../../../data/reselects/authSelector";
import {ActionUpdateProfile} from "../../../data/hooks/auth";

import {
    AppstoreOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    PieChartOutlined,
    DesktopOutlined,
    ContainerOutlined,
    MailOutlined,
} from '@ant-design/icons';
import HeaderMenu from "./HeaderMenu";

const Header = (props) => {
    const agent = "RealEstateIQ",
        [showProfile, setShowProfile] = useState(false),
        getUpdateProfile = ActionUpdateProfile(),
        itemFetching = useSelector(getFetchingAuth()),
        itemProfile = useSelector(getProfile());
    const menu = () => (
        <Menu>
            <Menu.Item onClick={() => setShowProfile(true)}>
                <i className="far fa-user me-2"/> {(itemProfile?.first_name || "") + " " + (itemProfile?.last_name || "")}
            </Menu.Item>
            <Menu.Item className="border-top"><i className="fas fa-history me-2"/><Link to="/my-subscription"
                                                                                        className="text-decoration-none fs--14px">My
                subscription</Link></Menu.Item>
            <Menu.Item className="border-top"><i className="fas fa-history me-2"/><Link to="/my-transaction"
                                                                                        className="text-decoration-none fs--14px">My
                transaction</Link></Menu.Item>
            <Menu.Item className="border-top"><i className="fas fa-cubes me-2"/><Link to="/package-subscription"
                                                                                      className="text-decoration-none fs--14px">Package
                subscription</Link></Menu.Item>
            <Menu.Item className="border-top" onClick={() => onLogout()}><i
                className="fas fa-sign-out-alt me-2"/> Logout</Menu.Item>
        </Menu>
    );

    const onLogout = () => {
        const token = LocalStorage.get(CONFIG_LOCAL_STORAGE.ACCESS_TOKEN);
        if (token) {
            LocalStorage.remove(CONFIG_LOCAL_STORAGE.PROFILE);
            LocalStorage.remove(CONFIG_LOCAL_STORAGE.EXPIRES);
            LocalStorage.remove(CONFIG_LOCAL_STORAGE.REFRESH_TOKEN);
            LocalStorage.remove(CONFIG_LOCAL_STORAGE.ACCESS_TOKEN);
        }
        window.location = `${process.env.REACT_APP_AUTH0}/auth/verify?callback=${process.env.REACT_APP_BASE_URL}/auth/loyalty&agent=${agent}&action=logout`
        // props.history.go(`${process.env.REACT_APP_AUTH0}/auth/verify?callback=${process.env.REACT_APP_BASE_URL}/auth/loyalty&agent=${agent}&action=logout`)
    }

    const updateProfile = (val) => {
        if (val.password || val.confirm_password) {
            if (val.password === val.confirm_password) {
                getUpdateProfile({...val, id: props?.profile?.id})
            } else {
                message.error("Password does not match")
            }
        } else {
            getUpdateProfile({...val, id: props?.profile?.id})
        }
    }

    return (
        <>
            <Modal title="My Profile" destroyOnClose visible={showProfile} onOk={() => setShowProfile(false)}
                   onCancel={() => setShowProfile(false)} footer={null}>
                <Form
                    layout="vertical"
                    initialValues={{
                        email: itemProfile?.email,
                        first_name: itemProfile?.first_name,
                        last_name: itemProfile?.last_name,
                    }}
                    onFinish={updateProfile}
                    // onValuesChange={onRequiredTypeChange}
                    // requiredMark={requiredMark}
                >
                    <div className="row">
                        <div className="col-12">
                            <Form.Item label="Email" name="email">
                                <Input disabled/>
                            </Form.Item>
                        </div>
                        <div className="col-6">
                            <Form.Item
                                rules={[{required: true, message: 'Please input your first name'}]}
                                label="First Name"
                                name="first_name"
                            >
                                <Input/>
                            </Form.Item>
                        </div>
                        <div className="col-6">
                            <Form.Item
                                rules={[{required: true, message: 'Please input your last name'}]}
                                label="Last Name"
                                name="last_name"
                            >
                                <Input/>
                            </Form.Item>
                        </div>
                        <div className="col-6">
                            <Form.Item
                                label="Password"
                                name="password"
                                rules={[{type: "string", min: 6}, {whitespace: true}, ({getFieldValue}) => ({
                                    validator(rule, value) {
                                        if (value && (value.length > 1)) {
                                            if (!/^\s+|\s+$/g.test(value[0]) && !/^\s+|\s+$/g.test(value[value.length - 1])) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject("'password' cannot be empty");
                                        }
                                        return Promise.resolve();
                                    },
                                })]}
                            >
                                <Input.Password/>
                            </Form.Item>
                        </div>
                        <div className="col-6">
                            <Form.Item
                                label="Confirm Password"
                                name="confirm_password"
                                rules={[{type: "string", min: 6}, {whitespace: true}, ({getFieldValue}) => ({
                                    validator(rule, value) {
                                        if (value && (value.length > 1)) {
                                            if (!/^\s+|\s+$/g.test(value[0]) && !/^\s+|\s+$/g.test(value[value.length - 1])) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject("'password' cannot be empty");
                                        }
                                        return Promise.resolve();
                                    },
                                })]}
                            >
                                <Input.Password/>
                            </Form.Item>
                        </div>
                        <div className="col-12">
                            <Form.Item className="text-end mb-0">
                                <Button type="primary" disabled={itemFetching} htmlType="submit" className="me-3 mb-0">
                                    Submit {itemFetching && <Icon type="sync-outlined" spin/>}
                                </Button>
                                <Button htmlType="button"
                                        className="mb-0"
                                        onClick={() => setShowProfile(false)}>
                                    Cancel
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            </Modal>
            <div className="hn__header">
                <div className="row">
                    <div className="col-8 text-start ps-4" id="header-menu">
                        <div className="d-none d-lg-block">
                            <div className="row align-items-center">
                                {/*<div className="col-1 pe-0 width-70px">*/}
                                {/*    <i className="fas fa-bars color--black cursorPointer ms-3 fs--24px hn__header--toggle"*/}
                                {/*       onClick={props.toggleCollapsed}/>*/}
                                {/*</div>*/}
                                <div className="col-2 ps-0">
                                    {/*<img*/}
                                    {/*    src="/layout/logo.svg"*/}
                                    {/*    className="" width="130px" height="60px"/>*/}
                                    <img
                                        src="/layout/logo.png"
                                        className="" width="150px" height="70px"/>

                                </div>
                                <div className="col-10">
                                    <HeaderMenu {...props} />
                                </div>
                            </div>
                        </div>
                        <div className="d-lg-none">
                            <div className="row align-items-center pt-2">
                                <div className="col-2 px-0">
                                    <i className="fas fa-bars color--black cursorPointer ms-3 fa-2x hn__header--toggle"
                                       onClick={props.toggleCollapsed}/>
                                </div>
                                <div className="col-10 text-center">
                                    <img
                                        src="/layout/logo.png"
                                        className="" width="150px" height=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-4 mt-2 text-end pe-4">
                        <Space>
                            <a href={process.env.REACT_APP_MENU_LINK_A}
                               className="color--primary text-decoration-none" target="_blank">
                                <Button type="primary" htmlType="button"
                                        className="bg--primary border-0 border__radius--6px"
                                >
                                    {process.env.REACT_APP_MENU_LINK_TITLE_A}
                                </Button>
                            </a>
                            <a href={process.env.REACT_APP_MENU_LINK_C}
                               className="color--primary text-decoration-none" target="_blank">
                                <Button type="primary" htmlType="button"
                                        className="bg--primary border-0 border__radius--6px"
                                >
                                    {process.env.REACT_APP_MENU_LINK_TITLE_C}
                                </Button>
                            </a>
                            <a href={process.env.REACT_APP_MENU_LINK_B}
                               className="color--primary text-decoration-none"
                               target="_blank">
                                <Button type="primary" htmlType="button"
                                        className="bg--primary border-0 border__radius--6px"
                                >{process.env.REACT_APP_MENU_LINK_TITLE_B}
                                </Button>
                            </a>
                            <Dropdown overlay={menu}>
                                <Avatar size={50} icon={<img style={{cursor: "pointer"}} width={50} height={50}
                                                             src={(itemProfile?.first_name && itemProfile?.last_name) ? `https://ui-avatars.com/api/?bold=true&background=ffffff&name=${itemProfile?.first_name} ${itemProfile?.last_name}` : "https://ui-avatars.com/api/?bold=true&background=ffffff&name=NF"}/>}/>
                            </Dropdown>
                        </Space>
                    </div>
                </div>
            </div>
        </>
    )
}

export default withRouter(Header);
