import {call, put, takeLatest, fork, all, delay} from 'redux-saga/effects';
import * as Types from '../types/ComparablesRequest';
import * as Func from "../../utils/functions";
import {parseListRequestParams} from '../../utils/commonUtils';
import * as FunctionService from '../services/comparablesRequestService';
import * as FunctionAction from '../actions/comparablesRequestAction';
import {filtersParams} from "../mapping/Request/ComparablesRequestRequest";

function* actionGetList() {
    yield takeLatest(Types.COMPARABLES_REQUEST_LIST_ACTION, Func.sagaWrapper(function* (action) {
        const params = parseListRequestParams(filtersParams(action.filters) || {});
        const result = yield call(FunctionService.actionList, params);
        yield put(FunctionAction.comparablesRequestListSuccess(result));
    }, errorHandle(Types.COMPARABLES_REQUEST_FAIL)))
}

function* actionGetItem() {
    yield takeLatest(Types.COMPARABLES_REQUEST_DETAIL_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.actionDetail, action.params.id);
        yield put(FunctionAction.comparablesRequestDetailSuccess(result));
    }, errorHandle(Types.COMPARABLES_REQUEST_FAIL)))
}

// function* actionGetMasterContact() {
//     yield takeLatest(Types.CONTACT_MASTER_ACTION, Func.sagaWrapper(function* (action) {
//         const result = yield call(FunctionService.masterContact, action.params);
//         yield put(FunctionAction.contactMasterSuccess(result));
//     }, errorHandle(Types.CONTACT_FAIL)))
// }
//
// function* createAction() {
//     yield takeLatest(Types.CONTACT_CREATE_ACTION, Func.sagaWrapper(function* (action) {
//         const data = new ContactRequest(action.params).exportCreate();
//         const result = yield call(FunctionService.create, data);
//         message.success(TAG_DEFINE.VALIDATION.statusCode.code["201"]);
//         action.params.props.history.goBack();
//         yield put({type: Types.CONTACT_LIST_ACTION});
//     }, errorHandle(Types.CONTACT_FAIL)))
// }
//
// function* updateAction() {
//     yield takeLatest(Types.CONTACT_UPDATE_ACTION, Func.sagaWrapper(function* (action) {
//         const data = new ContactRequest(action.params).exportUpdate();
//         yield call(FunctionService.update, data);
//         message.success(TAG_DEFINE.VALIDATION.statusCode.code["204"]);
//         yield put(FunctionAction.contactDetailAction({id: action.params.id}));
//     }, errorHandle(Types.CONTACT_FAIL)))
// }
//
// function* deleteAction() {
//     yield takeLatest(Types.CONTACT_DELETE_ACTION, Func.sagaWrapper(function* (action) {
//         yield call(FunctionService.deleteFunction, action.params?.id);
//         message.success(TAG_DEFINE.VALIDATION.statusCode.code["203"]);
//         yield put(FunctionAction.contactListAction(action.params?.filters));
//     }, errorHandle(Types.CONTACT_FAIL)))
// }

function errorHandle(errorActionType) {
    return Func.sagaErrorHandler(function* (e) {
        console.log("===> ", e)
        yield put({type: errorActionType, errors: e?.errors})
    });
}

export function* comparablesRequestSaga() {
    yield all([
        fork(actionGetList),
        fork(actionGetItem),
    ])
}
