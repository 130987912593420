import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {
    CaretDownOutlined,
    CaretUpOutlined,
    InfoCircleOutlined,
    LoadingOutlined,
    SearchOutlined
} from "@ant-design/icons";
import {Form, Button, Input, Typography, Row, Col, AutoComplete, Select, Card, message} from "antd";

import {address_lookup, filter_comparables, address_lookup_reset} from "../../../data/actions/fastCMA";
import {useScript} from "../../../utils/commonUtils"
import {predictions as samplePredictions, texas_predictions as texasPredictions} from "./demo_google_predictions";
import queryString from "query-string";

const {Title, Text} = Typography;
const {Option} = Select;

const googleAddressParser = (place) => {
    const detail = {}
    if (place.address_components) {
        detail.address = place.formatted_address
        for (var component of place.address_components) {

            if (component.types.indexOf('street_number') >= 0) {
                detail.street_box_num = component.short_name;
            }
            if (component.types.indexOf('route') >= 0) {
                detail.street_name = component.long_name;
                detail.address = detail.address.replace(component.short_name, component.long_name)
            }
            if (component.types.indexOf('administrative_area_level_1') >= 0) {
                detail.state = component.short_name;
            }
            if (component.types.indexOf('locality') >= 0) {
                detail.city = component.long_name;
            }
            if (component.types.indexOf('country') >= 0) {
                detail.country = component.short_name;
            }
            if (component.types.indexOf('postal_code') >= 0) {
                detail.zipcode = component.long_name;
            }
        }
        detail.Lat = place?.geometry.location.lat();
        detail.Lon = place?.geometry.location.lng();
    }
    return detail;
}

const AddressLookup = (props) => {
    const {onLookup, lookup, showFilter, resetLookUpField} = props
    const [canLookup, enableLookup] = useState(false)
    const [addresses, setAddress] = useState([]);
    const [addressError, setAddressError] = useState(false)
    const [lookupAddress, setLookupAddress] = useState('');
    const [form] = Form.useForm();

    useEffect(() => {
        if (props?.location) {
            let paramsFilters = queryString.parse(props.location.search, {arrayFormat: 'comma'});
            form.setFieldsValue({"address-search": paramsFilters?.fullAddress})
            if (window.getPredictions) clearTimeout(window.getPredictions);
            if (paramsFilters?.fullAddress) {
                window.getPredictions = setTimeout(() => {
                    if (lookup.status !== 'idle') {
                        resetLookUpField();
                    }
                    const service = new window.google.maps.places.AutocompleteService();
                    service.getPlacePredictions({
                        input: paramsFilters?.fullAddress
                    }, displaySuggestion)
                    window.getPredictions = false;
                }, 500);
            } else {
                // setLookupAddress(false)
                setAddress([])
            }
        }
    }, []);


    const isTexasState = (terms = []) => {
        if (terms.length) {
            for (let value of terms.map(i => i.value)) {
                if (value.match(/tx|texas/i)) {
                    return true;
                }
            }
        }
        return false;
    }

    const onSubmitHandle = (values) => {
        let selected = false;
        if (addresses) {
            selected = addresses.filter(item => item.value === values['address-search']);
            if (selected.length > 0) {
                selected = selected[0]
            }
        }

        if (!selected || !isTexasState(selected.terms)) {
            setAddressError(true)
        } else {
            setAddressError(false)
            // start lookup
            if (window.google) {
                const request = {
                    placeId: selected.place_id,
                    fields: ['name', 'address_component', 'formatted_address', 'geometry']
                };
                const map = new window.google.maps.Map(document.createElement('div'))
                const service = new window.google.maps.places.PlacesService(map);
                service.getDetails(request, (place, status) => {
                    if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                        const detail = googleAddressParser(place);

                        form.setFieldsValue({
                            address: ''
                        })
                        onLookup(detail);
                    }
                });
            }
            // setLookupAddress(selected)
        }
    }

    const onSelectAddress = (value) => {
        resetLookUpField();
        enableLookup(true)
    }

    const displaySuggestion = (predictions, status) => {
        if (window.google && status != window.google.maps.places.PlacesServiceStatus.OK || !predictions) {
            setAddress([])
            return;
        }
        setAddress(predictions.map(addr => {
            return {
                value: addr.description,
                terms: addr.terms,
                place_id: addr.place_id
            }
        }))

    }

    const onAddressChange = (address) => {
        if (window.getPredictions) clearTimeout(window.getPredictions);
        if (address) {
            window.getPredictions = setTimeout(() => {
                if (lookup.status !== 'idle') {
                    resetLookUpField();
                }
                const service = new window.google.maps.places.AutocompleteService();
                service.getPlacePredictions({
                    input: address
                }, displaySuggestion)
                window.getPredictions = false;
            }, 500);
        } else {
            // setLookupAddress(false)
            setAddress([])
        }
    }

    const onSelectValidAddress = (value) => {
        enableLookup(false);
        // Start find comparable
        const selectItem = lookup.result.filter(item => value == item.mls_num || value == item.address);
        let lat, lon, address;
        if (selectItem.length > 0) {
            lat = selectItem[0].lat;
            lon = selectItem[0].lon;
            address = selectItem[0].address;
        }
        props.setGetAddress(address)
        showFilter(value, lat, lon, address);
    }

    return (
        <Form form={form} name="address-lookup" className="custom--form" layout="vertical" onFinish={onSubmitHandle}>
            <div className="row">
                {/*<div className="col-3">*/}
                {/*    <Form.Item*/}
                {/*        label="Profile"*/}
                {/*        rules={[*/}
                {/*            {*/}
                {/*                required: true,*/}
                {/*                message: "Please choose Profile",*/}
                {/*            },*/}
                {/*        ]}*/}
                {/*    >*/}
                {/*        {props?.selectProfile}*/}
                {/*    </Form.Item>*/}
                {/*</div>*/}
                {/*<div className="col-3">*/}
                {/*    <Form.Item*/}
                {/*        label="Market"*/}
                {/*        rules={[*/}
                {/*            {*/}
                {/*                required: true,*/}
                {/*                message: "Please choose Market",*/}
                {/*            },*/}
                {/*        ]}*/}
                {/*    >*/}
                {/*        {props?.selectMarket}*/}
                {/*    </Form.Item>*/}
                {/*</div>*/}
                <div className="col-4 position-relative">
                    <SearchOutlined className="fast-cma--icon-input-address icon-ant"/>
                    <Form.Item
                        name="address-search"
                        label="Address"
                        className=""
                        rules={[
                            {
                                required: true,
                                message: "Please input address location",
                            },
                        ]}
                    >
                        <AutoComplete
                            allowClear
                            className="custom--auto-complete fast-cma--input-address"
                            onSelect={onSelectAddress}
                            onSearch={onAddressChange}
                            placeholder="Enter Address"
                        >
                            {addresses.map(item => {
                                return <AutoComplete.Option key={item.place_id}
                                                            value={item.value}>{item.value}</AutoComplete.Option>
                            })}
                        </AutoComplete>
                    </Form.Item>
                </div>
                <div className="col-2">
                    <Form.Item label=" " shouldUpdate wrapperCol={24}>
                        <Button disabled={!canLookup} type="primary" shape="round" className="w-100 mt-1 custom--button"
                                htmlType="submit">
                            {lookup.status === 'searching' ? <LoadingOutlined/> : 'Search'}
                        </Button>
                        {/*{addressError &&*/}
                        {/*    <>*/}
                        {/*        <br/>*/}
                        {/*        <Text type="danger"><InfoCircleOutlined style={{fontSize: 12}}/> Please*/}
                        {/*            provide <Text underline type="dange">Texas state</Text> location </Text>*/}
                        {/*    </>*/}
                        {/*}*/}
                    </Form.Item>
                </div>
            </div>
            {/*{lookup.status === 'success' && lookup.result && lookup.result.length > 0 &&*/}
            {/*    <Row>*/}
            {/*        <Col span={16}>*/}
            {/*            /!*<Text className="mb-2">Please, validate the address provided</Text>*!/*/}
            {/*            <Form.Item*/}
            {/*                name="address"*/}
            {/*                label="Please, validate the address provided"*/}
            {/*                rules={[*/}
            {/*                    {*/}
            {/*                        required: true,*/}
            {/*                        message: "Please choose address",*/}
            {/*                    },*/}
            {/*                ]}*/}
            {/*            >*/}
            {/*                <Select suffixIcon={e => e?.open ? <CaretUpOutlined/> : <CaretDownOutlined/>}*/}
            {/*                        className="custom--selector" onSelect={onSelectValidAddress}>*/}
            {/*                    {lookup.result.map((property, idx) => <Option key={`select-valid-address-${idx}`}*/}
            {/*                                                                  value={property.mls_num || property.address}>{property.address}*/}
            {/*                        {property.list_date ?*/}
            {/*                            `(${(new Date(property.list_date)).toLocaleDateString('en-US', {*/}
            {/*                                year: 'numeric',*/}
            {/*                                month: 'short',*/}
            {/*                                day: 'numeric',*/}
            {/*                                hour: 'numeric',*/}
            {/*                                minute: 'numeric'*/}
            {/*                            })} ${property.status || ''})` : ''}</Option>)}*/}
            {/*                </Select>*/}
            {/*            </Form.Item>*/}
            {/*        </Col>*/}
            {/*    </Row>*/}
            {/*}*/}
        </Form>
    )
}

const mapStateToProps = (state) => {
    const fastCMA = state.FastCMAReducer;
    return {
        cmaStep: fastCMA.step,
        lookup: fastCMA.address_lookup,
        googleSessionToken: fastCMA.google ? fastCMA.google.session : false
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onLookup: (address) => {
            dispatch(address_lookup(address));
        },
        showFilter: (mls_num, lat, lon, address) => {
            dispatch(filter_comparables({
                mls_num: mls_num,
                address: address,
                latitude: lat,
                longitude: lon
            }));
        },
        resetLookUpField: () => {
            dispatch(address_lookup_reset());
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddressLookup);
