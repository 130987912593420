import React, {useEffect, useState, useMemo} from "react";
import {connect} from "react-redux";

import {Card, Row, Col, Tabs, Input} from "antd";
import GoogleMap from "../GoogleMap";
import {moneyFormatter} from "../../../../../utils/commonUtils";
import {PROPERTY} from "../../../../../constants/fastCMA";
import './ComparablesShortEstimate.scss';
import {initial} from "lodash";

const {TabPane} = Tabs;

const EstimateCard = (props) => {
    const {estimate, extend, subject, status, defaultEstimate, single} = props
    const currentStatus = status;
    const soldPrice = status === PROPERTY.STATUS.LEASED.key || status === PROPERTY.STATUS.NONACTIVE.key ? estimate[PROPERTY.STATUS.SOLD.key].sold_price : estimate[currentStatus].sold_price
    let initials = {};
    let estimateStatus = status === PROPERTY.STATUS.ACTIVE.key ? PROPERTY.STATUS.ACTIVE.key : PROPERTY.STATUS.SOLD.key;
    if (defaultEstimate) {
        initials = {
            [PROPERTY.STATUS.SOLD.key]: {
                gross: defaultEstimate[PROPERTY.STATUS.SOLD.key].price - defaultEstimate[PROPERTY.STATUS.SOLD.key].grossDiscount,
                percent: defaultEstimate[PROPERTY.STATUS.SOLD.key].percentDiscount.toFixed(2)
            },
            [PROPERTY.STATUS.ACTIVE.key]: {
                gross: defaultEstimate[PROPERTY.STATUS.ACTIVE.key].price - defaultEstimate[PROPERTY.STATUS.ACTIVE.key].grossDiscount,
                percent: defaultEstimate[PROPERTY.STATUS.ACTIVE.key].percentDiscount.toFixed(2)
            },
        }
    } else {
        initials = {
            [PROPERTY.STATUS.SOLD.key]: {
                gross: estimate[PROPERTY.STATUS.SOLD.key].sold_price * 0.7,
                percent: 30
            },
            [PROPERTY.STATUS.ACTIVE.key]: {
                gross: estimate[PROPERTY.STATUS.ACTIVE.key].sold_price.price * 0.7,
                percent: 30
            },
        }
    }

    const [config, setConfig] = useState(initials)

    const gridStyle = {
        width: extend ? '16.65%' : '25%'
    }

    const handleChange = (e) => {
        const {value} = e.target;
        let offer = parseFloat(value.replace(/([^\d\.\s]+)/g, ''))
        offer = isNaN(offer) ? 0 : offer;
        setConfig({
            ...config,
            [estimateStatus]: {
                gross: offer.toFixed(),
                percent: ((soldPrice - offer) / soldPrice * 100).toFixed()
            }
        });
    };

    return (
        <Card className="short-estimate-board" title={false} style={{
            backgroundColor: 'transparent'
        }}>
            <Card.Grid hoverable={false} style={gridStyle}>
                <span className="money">{moneyFormatter.format(Math.floor(soldPrice))}</span>
                <span className="label">Estimated price</span>
            </Card.Grid>
            <Card.Grid hoverable={false} style={gridStyle}>
                <span
                    className="money">{currentStatus === PROPERTY.STATUS.NONACTIVE.key ? 0 : moneyFormatter.format(Math.floor(estimate[PROPERTY.STATUS.LEASED.key].sold_price))}</span>
                <span className="label">Monthly lease</span>
            </Card.Grid>
            {extend &&
                <>
                    <Card.Grid hoverable={false} style={gridStyle}>
            <span className="money">
              <Input
                  onChange={handleChange}
                  // onBlur={handleBlur}
                  placeholder="Input a number"
                  maxLength={25}
                  value={Math.floor(config[estimateStatus].gross)}
              />
            </span>
                        <span className="label">Asking/offer price</span>
                    </Card.Grid>
                    <Card.Grid hoverable={false} style={gridStyle}>
                        <span
                            className="money">{currentStatus === PROPERTY.STATUS.NONACTIVE.key ? 0 : moneyFormatter.format(Math.floor(soldPrice - config[estimateStatus].gross))}</span>
                        <span className="label">IQ Discount™</span>
                    </Card.Grid>
                    <Card.Grid hoverable={false} style={gridStyle}>
                        <span
                            className="money">{currentStatus === PROPERTY.STATUS.NONACTIVE.key ? 0 : config[estimateStatus].percent}%</span>
                        <span className="label">IQ Discount %™</span>
                    </Card.Grid>
                    <Card.Grid hoverable={false} style={gridStyle}>
                        <span
                            className="money">{((estimate[PROPERTY.STATUS.LEASED.key].sold_price / parseFloat(config[estimateStatus].gross)) * 100).toFixed(2)}</span>
                        <span className="label">Rental Index™</span>
                    </Card.Grid>
                </>
            }
        </Card>
    )
}

const ComparableShortEstimate = (props) => {
    const {subject, properties, selectedProperties, activeStatus, estimate, defaultEstimate} = props
    // const selectedIndex = selectedProperties[activeStatus] || [];
    // const data = properties[activeStatus] && properties[activeStatus].length ? properties[activeStatus].filter((item, idx) => selectedIndex.indexOf(idx) >= 0) : [];
    //
    // let coordinates = [[subject.lon, subject.lat]];
    // if (data.length > 0) {
    //     for (let property of data) {
    //         coordinates.push([
    //             property.lon,
    //             property.lat
    //         ]);
    //     }
    // }

    return (
        <Row className="comparable__short-estimate">
            <Col span="24">
                <Tabs defaultActiveKey="estimated">
                    <TabPane tab="Estimated value" key="estimated">
                        {/*<EstimateCard estimate={estimate} status={activeStatus} subject={subject}/>*/}
                    </TabPane>
                    <TabPane tab="Investment summary" key="summary">
                        {/*<EstimateCard defaultEstimate={defaultEstimate} estimate={estimate} status={activeStatus}*/}
                        {/*              extend={true} subject={subject}/>*/}
                    </TabPane>
                </Tabs>
            </Col>
        </Row>
    )
}

const mapStateToProps = state => {
    const fastCMA = state.FastCMAReducer

    return {
        // subject: fastCMA.cma.result.subject,
        // properties: fastCMA.cma.result.payload,
        // defaultEstimate: {
        //     distance: fastCMA.cma.result.distance,
        //     recency: fastCMA.cma.result.recency,
        //     active: {
        //         grossDiscount: fastCMA.cma.result.activeEstGrossDiscount,
        //         percentDiscount: fastCMA.cma.result.activeEstPercentDiscount,
        //         price: fastCMA.cma.result.activeEstimatedPrice
        //     },
        //     leased: {
        //         monthly: fastCMA.cma.result.estimatedMonthlyLease,
        //         rentalIndex: fastCMA.cma.result.rentalIndex
        //     },
        //     sold: {
        //         grossDiscount: fastCMA.cma.result.soldEstGrossDiscount,
        //         percentDiscount: fastCMA.cma.result.soldEstPercentDiscount,
        //         price: fastCMA.cma.result.soldEstimatedPrice
        //     }
        // },
        // selectedProperties: fastCMA.cma.selected_properties,
        activeStatus: fastCMA.cma.active_status,
        // estimate: fastCMA.cma.estimate
    }
}

export default connect(mapStateToProps, null)(ComparableShortEstimate);

export {
    EstimateCard
}
