import React, {useState, useEffect, useRef} from "react";
import {connect} from "react-redux";

import './Comparables.scss';
import {Typography, Checkbox, Row, Col, Image as AntdImage, Tabs, Card, Radio, Grid, Table, Button} from "antd";
import {StarFilled, UnorderedListOutlined, FileImageOutlined} from "@ant-design/icons";
import {TAB_COMPARABLES, FIELD_COMPARA, properties as compareFields, COLUMN_PRIMARY} from "./compareFields";
import ComparableStatusSwitch from "./ComparableStatusSwitch"
import ComparablesEstimate from "./ComparablesEstimate";
import ComparablesShortEstimate from "./ComparablesShortEstimate";
import {moneyFormatter} from "../../../../../utils/commonUtils";

import {cma_set_active_status, set_selected_property} from "../../../../../data/actions/fastCMA";
import {MARKET, PROPERTY} from "../../../../../constants/fastCMA";
import Image from "../../Image"
import {cloneDeep} from "lodash";

const {Title} = Typography
const {TabPane} = Tabs

const TableScroll = connect(state => {
    const fastCMA = state.FastCMAReducer;

    return {
        selectedProperty: fastCMA.cma?.selected_properties
    }
}, null)(props => {
    const {selectedProperty} = props
    useEffect(() => {
        let table = window.document.querySelector('.comparable__table-wrapper')
        let scrollLeft = table.getAttribute('data-scroll-left')
        if (table.scrollLeft !== scrollLeft) {
            table.scrollLeft = scrollLeft
        }
    })

    return <></>
})
const PropertySelectBox = connect(state => {
    const fastCMA = state.FastCMAReducer;

    return {
        // subject: fastCMA.cma?.result.subject,
        // properties: fastCMA.cma?.result.payload,
        activeStatus: fastCMA.cma?.active_status,
        // selectedProperty: fastCMA.cma?.selected_properties
    }
}, dispatch => {
    return {
        setSelectedProperty: (selectedIndex) => {
            dispatch(set_selected_property({
                selectedIndex: selectedIndex
            }))
        }
    }
})((props) => {
    const {index, selectedProperty, setSelectedProperty, activeStatus, properties, className, title} = props
    const compareData = properties && properties[activeStatus] ? properties[activeStatus] : [];
    const checkedList = selectedProperty[activeStatus];
    const isChecked = index === 'all' ? checkedList.length === compareData.length : (checkedList && checkedList.indexOf(index) >= 0);

    const changeHandle = (e) => {
        const index = e.target.value
        let listItem = [...checkedList];

        let keys = Object.keys(compareData).map(i => parseInt(i));
        if (e.target.checked) {
            if (index === 'all') {
                listItem = keys;
            } else {
                if (listItem.indexOf(index) < 0) {
                    listItem.push(index)
                }
            }
        } else {
            if (index === 'all') {
                listItem = [];
            } else {
                for (let i = 0; i < listItem.length; i++) {
                    if (listItem[i] === index) {
                        listItem.splice(i, 1);
                    }
                }
            }
        }
        setSelectedProperty(listItem);
    }

    if (checkedList.indexOf(index) >= 0) {
        className.push('comparable__cell--selected')
    }

    return <td className={className.join(' ')}><Checkbox
        onChange={changeHandle}
        checked={isChecked}
        value={index}>{title || (index + 1)}</Checkbox></td>
})

const ComparableCol = connect(state => {
    const fastCMA = state.FastCMAReducer;
    return {
        activeStatus: fastCMA.cma?.active_status,
        // selectedProperty: fastCMA.cma?.selected_properties,
        market: fastCMA.cma?.market,
    }

}, null)(props => {
    const {isSubject, index, type, data, dataKey, selectedProperty, activeStatus, market} = props
    const today = (new Date())
    const selectedList = selectedProperty[activeStatus]
    const isSelected = selectedList && selectedList.indexOf(index) >= 0

    const displayCell = (className) => {
        switch (type) {
            case 'checkbox':
                return isSubject ? <StarFilled/> : <PropertySelectBox
                    subject={props?.subject}
                    properties={props?.properties}
                    selectedProperty={props?.selectedProperty}
                    className={className} index={index}/>;
            case 'image':
                return <Image id={data[dataKey]}/>
            case 'money':
                return moneyFormatter.format(data[dataKey]);
            case 'list_price_orig':
                return Math.round(data['list_price'] / data['list_price_orig'] * 100) + '%';
            case 'date':
                const d = new Date(data[dataKey]);

                let month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear(),
                    hour = d.getHours(),
                    second = "0" + d.getSeconds();
                return `${month}-${day}-${year} ${("0" + hour).slice(-2)}:${second.slice(-2)} ${hour >= 12 ? 'PM' : 'AM'}`;
            case 'price_per_square_foot':
                return <span><span
                    className="currency">$</span>{Math.round((data['sold_price'] || data['list_price']) / data['square_feet'] * 100) / 100}</span>
            case 'recency_day':
                return Math.round(((today - (new Date(data['sold_date'] || data['modified_date']))) / (24 * 60 * 60 * 1000)));
            case 'distance':
                return Math.round((data['dist'] || 0) * 0.00062137 * 100) / 100;
            case 'street_name':
                return [data["street_box_num"], data["street_name"], data["street_type"]].join(' ');
            default:
                let value = Array.isArray(dataKey) ? dataKey.map(k => data[k] || 'N/A').join(' / ') : data[dataKey];
                return <span>{value || 'N/A'}</span>;
        }
    }
    const className = ['comparable__cell']
    !!isSubject && className.push('comparable__cell--subject');
    !!isSelected && !isSubject && className.push('comparable__cell--selected');
    if (type === 'checkbox' && !isSubject) {
        return displayCell(className)
    }
    return (
        <td className={className.join(' ')}>
            {displayCell()}
        </td>
    )
})

function useTraceUpdate(props) {
    const prev = useRef(props);
    useEffect(() => {
        const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
            if (prev.current[k] !== v) {
                ps[k] = [prev.current[k], v];
            }
            return ps;
        }, {});
        if (Object.keys(changedProps).length > 0) {
            console.log('Changed props:', changedProps);
        }
        prev.current = props;
    });
}

const PropertyImagesRow = connect(state => {
    const data = state.FastCMAReducer
    return {
        market: data?.cma.market
    }
}, null)((props) => {
    let {uid, market} = props
    let row = [];
    let style = {
        paddingTop: '8px',
        paddingBottom: '8px',
    }
    for (let i = 0; i < 24; i++) {
        row.push(
            <Col className="gutter-row" span={4} style={style}>
                <Image
                    id={uid}
                    index={i}
                    width="100%"
                    height={70}
                />
            </Col>
        )
    }
    return (
        <Row gutter={16}>
            {row}
        </Row>
    );
})

const Comparables = (props) => {
    const {subject, properties, activeStatus, setActiveStatus, setSelectedProperty} = props;
    const [selectedRowKeys, setSelectedRowKeys] = useState([0]),
        [resultTable, setResultTable] = useState([]),
        [selectedRowByTab, setSelectedRowByTab] = useState({
            sold: [0],
            active: [0],
            leased: [0],
            nonActive: [0],
        }),
        [checkTab, setCheckTab] = useState(props?.tabActive);

    const compareData = properties && properties[activeStatus] ? properties[activeStatus] : [];

    const toggleChildRows = (event) => {
        const target = event.target;
        if (target.classList.contains('comparable__head')) {
            const row = target.parentElement;
            if (!row.classList.contains('comparable__row--has-children')) return;
            if (row.classList.contains('show-child')) {
                row.classList.remove('show-child');
            } else {
                row.classList.add('show-child');
            }
        }
    }

    useEffect(() => {
        document.addEventListener('click', toggleChildRows);

        return () => {
            document.removeEventListener('click', toggleChildRows)
        }
    }, [])

    useEffect(() => {
        setSelectedRowKeys(props?.selectedProperty[activeStatus])
        setSelectedRowByTab(props?.selectedProperty)
    }, [props?.selectedProperty])

    useEffect(() => {
        if (Object.keys(properties || {}).length > 0) {
            if ((properties[activeStatus] || []).length > 0) {
            }
            // onSelectChange([properties[activeStatus][0]["_id"]])
        }
    }, [properties])

    useEffect(() => {
        props?.getTabActive(checkTab)
    }, [checkTab])

    useEffect(() => {
        let newResult = [{...subject, keyOrder: -1}, ...compareData.map((i, k) => {
            return {...i, keyOrder: k}
        })];
        setResultTable(newResult || [])
        props?.sendResultTable(newResult)
    }, [subject, compareData]);

    useEffect(() => {
        setResultTable(props?.resultTable)
    }, [props?.resultTable]);

    if (!properties) {
        return null;
    }

    const scrollHandle = (evt) => {
        evt.target.setAttribute('data-scroll-left', evt.target.scrollLeft || 0)
    }

    const onSelectChange = (newSelectedRowKeys) => {
        // console.log('selectedRowKeys-changed: ', newSelectedRowKeys);
        console.log('selectedRowKeys-changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
        setSelectedProperty(newSelectedRowKeys)
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        getCheckboxProps: (record) => ({
            disabled: record.keyOrder === -1,
        }),
    };

    const changeTabTable = e => {
        setActiveStatus(e)
        setCheckTab(e)
        setSelectedProperty(selectedRowByTab[e])
    }

    // console.log(2222, selectedRowByTab)

    return (
        <>
            <div className="hn__detail-cma--comparables box--shadow border__radius--10px p-3 mt-4">
                <div className="row align-items-center">
                    <div className="col-6">
                        <p className="fs--16px fw--600">Comparables</p>
                    </div>
                    <div className="col-6 text-end pe-4 pt-3">
                        <Button type="primary" onClick={e => props?.onShowReportnShare(true)}>Report and Share</Button>
                    </div>
                </div>
                <div className="mb-2">
                    <Tabs defaultActiveKey={checkTab} onChange={(e) => changeTabTable(e)}>
                        {PROPERTY.STATUS && Object.values(PROPERTY.STATUS).map((item, k) =>
                            <TabPane
                                key={item.key}
                                tab={`${item.label} (${properties[item.key].length})`}
                                disabled={properties[item.key].length < 1}
                            >
                                <ComparablesEstimate keyItem={item.key} checkTab={checkTab}/>
                            </TabPane>
                        )}
                    </Tabs>
                </div>
                <div className="hn__detail-cma--table mt-5">
                    <Tabs defaultActiveKey="primary" onChange={(e) => console.log(e)}>
                        {
                            TAB_COMPARABLES.map((i, k) => {
                                return (
                                    <TabPane
                                        key={i?.key}
                                        tab={i?.title}
                                        disabled={i?.key === "mls"}
                                    >
                                        <Table
                                            rowKey="keyOrder"
                                            rowSelection={rowSelection}
                                            dataSource={resultTable}
                                            columns={i?.column}
                                            pagination={false}
                                            scroll={(i?.column || []).length > 8 ? {
                                                x: 2000,
                                            } : false}
                                        />
                                    </TabPane>
                                )
                            })
                        }
                    </Tabs>

                </div>
            </div>

            {/*<ComparablesShortEstimate/>*/}
            {/*<ComparableStatusSwitch/>*/}
            {/*<ComparablesEstimate/>*/}

            {/*<div className="comparable">*/}
            {/*    <Title level={4}>Comparables</Title>*/}
            {/*    <Tabs defaultActiveKey="list">*/}
            {/*        <TabPane tab={<><UnorderedListOutlined/> List</>} key="list">*/}
            {/*            /!* <Checkbox.Group value={checkedList} className="comparable__select-item" onChange={onSelectProperties}> *!/*/}
            {/*            <div className="comparable__table-wrapper" onScroll={scrollHandle}>*/}
            {/*                <TableScroll/>*/}
            {/*                <table className="comparable__table" style={{*/}
            {/*                    width: `${(compareData.length + 2) * 200}px`*/}
            {/*                }}*/}
            {/*                >*/}
            {/*                    {compareFields && compareFields.map((head, index) => (*/}
            {/*                        <>*/}
            {/*                            <tr className={`comparable__row ${head.childs ? 'comparable__row--has-children' : ''}`}*/}
            {/*                                key={`row-${index}`}>*/}
            {/*                                {index === 0*/}
            {/*                                    ? <PropertySelectBox*/}
            {/*                                        subject={props?.subject}*/}
            {/*                                        properties={props?.properties}*/}
            {/*                                        selectedProperty={props?.selectedProperty}*/}
            {/*                                        className={["comparable__head"]} index="all"*/}
            {/*                                        title={head.title}/>*/}
            {/*                                    : <th className="comparable__head">{head.title}</th>}*/}
            {/*                                <ComparableCol*/}
            {/*                                    subject={props?.subject}*/}
            {/*                                    properties={props?.properties}*/}
            {/*                                    selectedProperty={props?.selectedProperty}*/}
            {/*                                    key="subject-col"*/}
            {/*                                    type={head.type}*/}
            {/*                                    isSubject={true}*/}
            {/*                                    data={subject}*/}
            {/*                                    dataKey={head.key}/>*/}
            {/*                                {compareData.map((property, colIdx) => <ComparableCol*/}
            {/*                                    subject={props?.subject}*/}
            {/*                                    properties={props?.properties}*/}
            {/*                                    selectedProperty={props?.selectedProperty}*/}
            {/*                                    key={`property-col-${colIdx}`}*/}
            {/*                                    type={head.type}*/}
            {/*                                    index={colIdx}*/}
            {/*                                    data={property}*/}
            {/*                                    dataKey={head.key}/>)}*/}
            {/*                            </tr>*/}
            {/*                            {head.childs &&*/}
            {/*                                <tbody>*/}
            {/*                                {head.childs.map((child, childIdx) => (*/}
            {/*                                    <tr className="comparable__row comparable__row--child"*/}
            {/*                                        key={`row-${index}-child-${childIdx}`}>*/}
            {/*                                        <th className="comparable__head">{child.title}</th>*/}
            {/*                                        <ComparableCol*/}
            {/*                                            subject={props?.subject}*/}
            {/*                                            properties={props?.properties}*/}
            {/*                                            selectedProperty={props?.selectedProperty}*/}
            {/*                                            key="subject-col" type={child.type} isSubject={true}*/}
            {/*                                            data={subject} dataKey={child.key}/>*/}
            {/*                                        {compareData.map((property, colIdx) => <ComparableCol*/}
            {/*                                            subject={props?.subject}*/}
            {/*                                            properties={props?.properties}*/}
            {/*                                            selectedProperty={props?.selectedProperty}*/}
            {/*                                            key={`property-col-${colIdx}`} type={child.type} index={colIdx}*/}
            {/*                                            data={property} dataKey={child.key}/>)}*/}
            {/*                                    </tr>*/}
            {/*                                ))}*/}
            {/*                                </tbody>*/}
            {/*                            }*/}
            {/*                        </>*/}
            {/*                    ))}*/}
            {/*                </table>*/}
            {/*            </div>*/}
            {/*            /!* </Checkbox.Group> *!/*/}
            {/*        </TabPane>*/}
            {/*        <TabPane tab={<><FileImageOutlined/> Photo</>} key="photo">*/}
            {/*            <table className="comparable__table" style={{width: '100%'}}>*/}
            {/*                <tbody>*/}
            {/*                {subject &&*/}
            {/*                    <tr class="comparable__row">*/}
            {/*                        <th style={{*/}
            {/*                            width: '100px',*/}
            {/*                            textAlign: 'center'*/}
            {/*                        }} className="comparable__head"><StarFilled/></th>*/}
            {/*                        <td>*/}
            {/*                            <PropertyImagesRow uid={subject.uid}/>*/}
            {/*                        </td>*/}
            {/*                    </tr>*/}
            {/*                }*/}
            {/*                {compareData.map((property, colIdx) => (*/}
            {/*                    <tr key={`property-image-${colIdx}`} class="comparable__row"*/}
            {/*                        style={{*/}
            {/*                            borderBottom: '1px solid #ccc'*/}
            {/*                        }}*/}
            {/*                    >*/}
            {/*                        <th style={{*/}
            {/*                            width: '100px',*/}
            {/*                            textAlign: 'center'*/}
            {/*                        }} className="comparable__head">{colIdx + 1}</th>*/}
            {/*                        <td>*/}
            {/*                            <PropertyImagesRow uid={property.uid}/>*/}
            {/*                        </td>*/}
            {/*                    </tr>*/}
            {/*                ))}*/}
            {/*                </tbody>*/}
            {/*            </table>*/}
            {/*        </TabPane>*/}
            {/*    </Tabs>*/}

            {/*</div>*/}
        </>
    )
}


const mapStateToProps = state => {
    const fastCMA = state.FastCMAReducer;

    return {
        subject: fastCMA.cma?.result.subject,
        properties: fastCMA.cma?.result.payload,
        activeStatus: fastCMA.cma?.active_status,
        selectedProperty: fastCMA.cma?.selected_properties
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setActiveStatus: (status) => {
            dispatch(cma_set_active_status({
                status: status
            }))
        },
        setSelectedProperty: (selectedIndex) => {
            dispatch(set_selected_property({
                selectedIndex: selectedIndex
            }))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Comparables);
