import React from "react";
import { connect } from "react-redux";

import { Radio } from "antd";
import { PROPERTY } from "../../../../../constants/fastCMA";

import { cma_set_active_status } from "../../../../../data/actions/fastCMA";

const ComparableStatusSwitch = (props) => {
  const { properties, activeStatus, setActiveStatus } = props

  const handleTypeChange = (event) => {
    // setActiveType(event.target.value)
    console.log(999, event.target.value)
    setActiveStatus(event.target.value)
  }

  return (
    <Radio.Group
      style={{
        marginTop: 12,
      }}
      value={activeStatus}
      onChange={handleTypeChange}
      className="type-select-bar"
    >
      {PROPERTY.STATUS && Object.values(PROPERTY.STATUS).map(item =>
        <Radio.Button
          key={`property-status-${item.key}`}
          value={item.key}
        >{item.label} ({properties[item.key].length})</Radio.Button>
      )}
    </Radio.Group>
  )
}

const mapStateToProps = state => {
  const fastCMA = state.FastCMAReducer

  return {
    properties: fastCMA.cma?.result.payload,
    activeStatus: fastCMA.cma?.active_status
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setActiveStatus: (status) => {
      dispatch(cma_set_active_status({
        status: status
      }))
    }
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ComparableStatusSwitch);
