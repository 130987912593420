import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {getProfile} from "../../../data/reselects/authSelector";
import {Menu} from "antd";
import {Link} from "react-router-dom";
import {capitalizeFirstLetter} from "../../../utils/functions";
import {MailOutlined} from "@ant-design/icons";

const {SubMenu} = Menu;

const HeaderMenu = props => {
    const [itemMenu, setItemMenu] = useState([]),
        itemAuthProfile = useSelector(getProfile());

    useEffect(() => {
        let menu = itemAuthProfile?.menu || [];
        let updatedMenu = [
            ...menu.slice(0, 1),
            {
                name: "Eva",
                group: "cma",
                route: "fast-cma"
            },
            {
                name: "Eva Profiles",
                group: "cma",
                route: "fast-cma/profiles"
            },
            {
                name: "Comparables Request",
                group: "comparables",
                route: "comparables-request/list"
            },
            ...menu.slice(1, menu.length)
        ]
        setItemMenu(updatedMenu)
    }, [itemAuthProfile.menu]);

    return (
        <>
            {
                itemMenu.length > 0 && (
                    <Menu
                        // getPopupContainer={() => document.getElementById("custom-selector-antd")}
                        defaultSelectedKeys={['/']}
                        selectedKeys={[props?.location?.pathname]}
                        mode="horizontal"
                        expandIcon={false}
                        // inlineCollapsed={props?.collapsed}
                        className="hn__header--menu"
                    >
                        {
                            itemMenu.map((i, k) => {
                                if ((i?.items || []).length > 0) {
                                    return (
                                        <SubMenu
                                            popupClassName={`${props?.collapsed ? "left--60px" : ""} hn__navigation--hide-all-popup-submenu`}
                                            key={i?.group} title={
                                            <>
                                                {/*<i className={`width-18px height--20px hn__navigation--icon icon--${i?.icon ? i?.icon : "off-market"} ${props?.collapsed ? "me-2" : "me-2"}`}/>*/}
                                                {
                                                    props.collapsed ?
                                                        <i className={`icon--arrow-right ${props?.collapsed ? "me-2" : ""}`}/> : ""
                                                }
                                                {!props?.collapsed && i?.name}
                                            </>
                                        } className="fs--14px hn__navigation--hide-all-submenu"
                                            onTitleClick={() => props.history.push(`/${i?.route}`)}>
                                            {renderMenuItem(i?.items || [])}
                                        </SubMenu>
                                    )
                                } else {
                                    return (
                                        <Menu.Item key={`/${i.route}`} className="">
                                            <Link to={`/${i.route}`}
                                                  className="fs--14px text-decoration-none">
                                                {/*<i className={`width-18px height--20px hn__navigation--icon icon--${i?.icon ? i?.icon : "off-market"} ${props?.collapsed ? "me-3" : "me-2"}`}/>*/}
                                                {!props?.collapsed && capitalizeFirstLetter(i.name)}
                                            </Link>
                                        </Menu.Item>
                                    )
                                }
                            })
                        }
                    </Menu>
                )
            }
        </>
    )
}

const renderMenuItem = (item) => {
    let arrayMenu = [];
    if (item.length > 0) {
        item.map((i, k) => arrayMenu.push(
            <Menu.Item key={`/${i.route}`} className="">
                <Link to={`/${i.route}`}
                      className="fs--14px text-decoration-none">{capitalizeFirstLetter(i.name)}</Link>
            </Menu.Item>
        ))
    }
    return arrayMenu
}
export default HeaderMenu
