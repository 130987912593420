export const mls_search_result = {
  "status": 1,
  "recency": "180",
  "distance": 402.335,
  "rentalIndex": 0,
  "estimatedMonthlyLease": 0,
  "soldEstimatedPrice": 224758.91280739274,
  "activeEstimatedPrice": 0,
  "activeEstGrossDiscount": 0,
  "activeEstPercentDiscount": 0,
  "soldEstGrossDiscount": 74858.91280739274,
  "soldEstPercentDiscount": 33.30631558604446,
  "subjectProperty": {
    "_id": "5daba7477eb0a2d075583b1d",
    "school_name1": "JONES ELEMENTARY SCHOOL (ALDINE)",
    "mls_num": 10001021,
    "roof": "Composition",
    "modified_date": "2019-12-16T21:24:54.420",
    "uid": 179102302,
    "school_name2": "JONES MIDDLE SCHOOL (ALDINE)",
    "school_district": "1 - Aldine",
    "foundation": "Slab",
    "house_type": "Traditional",
    "hoa_amount": 350,
    "seller_type": "Mud,Sellers Disclosure",
    "hoa_frequency": "Annually",
    "school_name3": "NIMITZ HIGH SCHOOL (ALDINE)",
    "showing_instruction": "Appointment Required",
    "bathroom_total": 2.1,
    "square_feet": 1694,
    "bedroom": 3,
    "state": "Texas",
    "status": "Sold",
    "storie": 2,
    "street_name": "Elm Drake",
    "street_box_num": 8917,
    "street_type": "Lane",
    "subdivision": "Deerbrook Estates",
    "list_price_orig": 149900,
    "agent_listing_appt_phone": "832-527-2450",
    "listing_agent_name": "Tammy Theriot",
    "garage": 2,
    "list_price": 149900,
    "list_date": "2019-10-19",
    "pool": 0,
    "zipcode": 77338,
    "dom": 29,
    "year_built": 2006,
    "acre": 2632,
    "prop_subtype": "Townhouse",
    "prop_type": "Townhouse/Condo",
    "public_remark": "Lennar townhome with dramatic two story foyer with window seat. Large living room. Dining area off of kitchen. Kitchen with lots of cabinets and counter space. Breakfast bar leading to living room. Huge walk in pantry and secondary pantry.Upstairs there is an area for a desk/gaming/reading,etc. Master suite is very large and master bath has double sinks, a soak in tub and separate shower. All bedrooms have walk-in closets. Refrigerator, washer and dryer are included. New fence, new carpet throughout, new blinds, fresh paint and solar screens.",
    "city": "Humble",
    "lat": 30.00932,
    "lon": -95.2883,
    "address": "8917 Elm Drake Lane Humble Texas 77338",
    "last_update": "Tue Dec 31 2019 00:58:55 GMT-0500 (EST)",
    "acres": null,
    "lot_description": "",
    "construction_type": "",
    "carport": null,
    "sold_price": 148350,
    "sold_date": "2019-12-16",
    "hoa": "",
    "seller_contribution": 3000,
    "appointment_type": "",
    "fireplace": null
  },
  "payload": {
    "active": [],
    "nonActive": [
      {
        "_id": "61df7d0d5ea047686bb505c2",
        "geolocation": {
          "coordinates": [
            -95.286974,
            30.009
          ],
          "type": "Point"
        },
        "school_name1": "JONES ELEMENTARY SCHOOL (ALDINE)",
        "mls_num": 80289454,
        "roof": "Composition",
        "modified_date": "2022-01-14T12:50:42.890",
        "uid": 203549733,
        "school_name2": "JONES MIDDLE SCHOOL (ALDINE)",
        "fireplace": "",
        "appointment_type": "Alternate Agent",
        "school_district": "1 - Aldine",
        "foundation": "Slab",
        "house_type": "Traditional",
        "seller_contribution": "",
        "hoa_amount": 350,
        "seller_type": "Mud,Sellers Disclosure",
        "hoa_frequency": "Annually",
        "hoa": "1",
        "school_name3": "NIMITZ HIGH SCHOOL (ALDINE)",
        "showing_instruction": "Leave Business Card,Lockbox Front",
        "bathroom_total": 2.1,
        "bedroom": 3,
        "state": "Texas",
        "status": "Terminated",
        "storie": 2,
        "street_name": "Elm Drake",
        "street_box_num": 9020,
        "street_type": "Lane",
        "sold_date": "",
        "subdivision": "Deerbrook Estates Sec 01 Amd",
        "sold_price": "",
        "list_price_orig": 197000,
        "agent_listing_appt_phone": "713-205-1637",
        "listing_agent_name": "Melissa Chavez",
        "carport": "",
        "garage": 2,
        "list_price": 197000,
        "list_date": "2022-01-12",
        "pool": 0,
        "construction_type": "Back Yard,Back Yard Fenced",
        "zipcode": 77338,
        "dom": 2,
        "lot_description": "Cul-De-Sac,Subdivision Lot",
        "year_built": 2006,
        "acre": 2800,
        "prop_subtype": "",
        "prop_type": "Single-Family",
        "public_remark": "This charming and meticulously cared for townhome in Humble is available for you to come out and take a look and make it your new home. The open concept continues into the Living Room, Kitchen and Dining area. Upstairs you'll find a updated full secondary restroom.  The spacious Primary Bedroom with ensuite bath features a large walk-in closet, garden tub and separate shower. There are 2 additional bedrooms down the hall as well as a convenient utility room. This home is in a great location to most everything and only minutes to IAH, yet located on a quiet cul-de-sac. New roof in 2021! This one is going to go fast so call for a showing appointment today!",
        "city": "Humble",
        "acres": 0.0643,
        "lat": 30.009,
        "lon": -95.286974,
        "square_feet": 1694,
        "last_update": "Fri Jan 14 2022 14:18:31 GMT-0500 (EST)",
        "address": "9020 Elm Drake Lane Humble Texas 77338",
        "dist": 132.6920417076113
      },
      {
        "_id": "6182e00161a0a0509dd50fd2",
        "geolocation": {
          "coordinates": [
            -95.286578,
            30.008219
          ],
          "type": "Point"
        },
        "school_name1": "JONES ELEMENTARY SCHOOL (ALDINE)",
        "mls_num": 54478976,
        "roof": "Aluminum,Other",
        "modified_date": "2021-12-16T10:31:23.980",
        "uid": 201880661,
        "school_name2": "JONES MIDDLE SCHOOL (ALDINE)",
        "fireplace": "",
        "appointment_type": "",
        "school_district": "3 - Alvin",
        "foundation": "Pier & Beam",
        "house_type": "Traditional",
        "seller_contribution": "",
        "hoa_amount": "",
        "seller_type": "Sellers Disclosure",
        "hoa_frequency": "",
        "hoa": "0",
        "school_name3": "NIMITZ HIGH SCHOOL (ALDINE)",
        "showing_instruction": "Supra Keybox,Vacant/Unoccupied",
        "bathroom_total": 2,
        "bedroom": 3,
        "state": "Texas",
        "status": "Terminated",
        "storie": 1,
        "street_name": "Stonewall Road County Rd 669a",
        "street_box_num": 19002,
        "street_type": "",
        "sold_date": "",
        "subdivision": "Village Trace Sec 1",
        "sold_price": "",
        "list_price_orig": 168500,
        "agent_listing_appt_phone": "512-827-2252",
        "listing_agent_name": "Aaron Jistel",
        "carport": "",
        "garage": 1,
        "list_price": 168500,
        "list_date": "2021-11-03",
        "pool": 1,
        "construction_type": "",
        "zipcode": 77511,
        "dom": 14,
        "lot_description": "Corner",
        "year_built": 2006,
        "acre": 37026,
        "prop_subtype": "",
        "prop_type": "Single-Family",
        "public_remark": "Show anytime. Over 1/3 Acre, (.3697) Bring your mobile homes/manufactured homes, rv/camper or fifth wheel, or build your dream home. Outside city limits, in the county, so no restrictions (per seller). But will need permit to build (anything larger than a storage shed). There is an rv hookup on the left side of the house. Sewer water and electric are all accessible. Single-wide mobile home located on a very accessible corner lot. Approximately .3697 acre. Enough land to possibly build small home. Above ground pool with stained deck installed 2 months ago. Custom rock Waterfall on back patio. Metal roof done in 2017. Comes with front and back patios. Front has concrete slab which could be used to add on square footage.Home shows installed once on tx state records.",
        "city": "Pearland",
        "acres": 0.3697,
        "lat": 30.008219,
        "lon": -95.286578,
        "square_feet": 1368,
        "last_update": "Thu Dec 16 2021 12:18:31 GMT-0500 (EST)",
        "address": "19002 Stonewall Road County Rd 669a Pearland Texas 77511",
        "dist": 206.3387480189033
      }
    ],
    "sold": [
      {
        "_id": "61ec65b2a09511f0dee9b10d",
        "geolocation": {
          "coordinates": [
            -95.289371,
            30.008353
          ],
          "type": "Point"
        },
        "school_name1": "JONES ELEMENTARY SCHOOL (ALDINE)",
        "mls_num": 55114133,
        "roof": "Composition",
        "modified_date": "2022-02-19T02:14:42.400",
        "uid": 203656599,
        "school_name2": "JONES MIDDLE SCHOOL (ALDINE)",
        "fireplace": 1,
        "appointment_type": "",
        "school_district": "1 - Aldine",
        "foundation": "Slab",
        "house_type": "Traditional",
        "seller_contribution": 0,
        "hoa_amount": 350,
        "seller_type": "Mud,Sellers Disclosure",
        "hoa_frequency": "Annually",
        "hoa": "1",
        "school_name3": "NIMITZ HIGH SCHOOL (ALDINE)",
        "showing_instruction": "Appointment Required,Leave Business Card,Supra Keybox",
        "bathroom_total": 2,
        "bedroom": 3,
        "state": "Texas",
        "status": "Sold",
        "storie": 1,
        "street_name": "Orchard Ridge",
        "street_box_num": 8830,
        "street_type": "Lane",
        "sold_date": "2022-02-18",
        "subdivision": "Deerbrook Estates Sec 1 Amd",
        "sold_price": 260000,
        "list_price_orig": 245000,
        "agent_listing_appt_phone": "832-265-7791",
        "listing_agent_name": "Michael Joseph",
        "carport": "",
        "garage": 2,
        "list_price": 245000,
        "list_date": "2022-01-22",
        "pool": 0,
        "construction_type": "Back Yard Fenced",
        "zipcode": 77338,
        "dom": 5,
        "lot_description": "Subdivision Lot",
        "year_built": 2005,
        "acre": 13236,
        "prop_subtype": "",
        "prop_type": "Single-Family",
        "public_remark": "",
        "city": "Humble",
        "acres": 0.3039,
        "lat": 30.008353,
        "lon": -95.289371,
        "square_feet": 1950,
        "last_update": "Sat Feb 19 2022 05:18:31 GMT-0500 (EST)",
        "address": "8830 Orchard Ridge Lane Humble Texas 77338",
        "dist": 149.1513234680521,
        "select": true
      },
      {
        "_id": "61a66a595a2113f1701982c8",
        "geolocation": {
          "coordinates": [
            -95.285332,
            30.010562
          ],
          "type": "Point"
        },
        "school_name1": "JONES ELEMENTARY SCHOOL (ALDINE)",
        "mls_num": 43664046,
        "roof": "Composition",
        "modified_date": "2021-12-29T13:03:47.590",
        "uid": 202581838,
        "school_name2": "JONES MIDDLE SCHOOL (ALDINE)",
        "fireplace": "",
        "appointment_type": "",
        "school_district": "1 - Aldine",
        "foundation": "Slab",
        "house_type": "Traditional",
        "seller_contribution": 1200,
        "hoa_amount": 350,
        "seller_type": "Mud,Sellers Disclosure",
        "hoa_frequency": "Annually",
        "hoa": "1",
        "school_name3": "NIMITZ HIGH SCHOOL (ALDINE)",
        "showing_instruction": "Accompany,Appointment Required,Supra Keybox",
        "bathroom_total": 2.1,
        "bedroom": 4,
        "state": "Texas",
        "status": "Sold",
        "storie": 2,
        "street_name": "Plume Tree",
        "street_box_num": 9118,
        "street_type": "Drive",
        "sold_date": "2021-12-29",
        "subdivision": "Deerbrook Estates Sec 04",
        "sold_price": 240000,
        "list_price_orig": 230000,
        "agent_listing_appt_phone": "281-798-3353",
        "listing_agent_name": "Jill Henderson",
        "carport": "",
        "garage": 2,
        "list_price": 230000,
        "list_date": "2021-11-30",
        "pool": 0,
        "construction_type": "Back Yard,Back Yard Fenced,Covered Patio/Deck,Sprinkler System",
        "zipcode": 77338,
        "dom": 3,
        "lot_description": "Subdivision Lot",
        "year_built": 2007,
        "acre": 5500,
        "prop_subtype": "",
        "prop_type": "Single-Family",
        "public_remark": "Beautiful one owner home shows pride of ownership.  Great open floorplan with fresh and current paint colors.  Walk through the rich wood and leaded glass front door into the foyer with views of your spacious den which is open to breakfast area and kitchen.  You are cooking with gas in this large kitchen with breakfast bar, plenty of cabinet/counter space and walk-in pantry!  Interior laundry/mud room just off kitchen with access to garage.  Lots of extra storage in downstairs closet that goes back and under the staircase.  Warm and durable laminate flooring in entry, den, stairs, and all of the second floor.  Tile in breakfast, kitchen and wet areas.  Primary bedroom with ensuite bath and walk-in closet. 2\" blinds through-out. Private backyard with lush grass and covered patio.  FULL HOUSE AUTOMATIC GENERAC GAS GENERATOR!  2 car attached garage with workbench.  Washer/Dryer/Fridge can stay too!  Lovingly cared for and ready for it's new owner!",
        "city": "Humble",
        "acres": 0.1263,
        "lat": 30.010562,
        "lon": -95.285332,
        "square_feet": 1853,
        "last_update": "Wed Dec 29 2021 14:18:31 GMT-0500 (EST)",
        "address": "9118 Plume Tree Drive Humble Texas 77338",
        "dist": 317.75648406889144,
        "select": true
      },
      {
        "_id": "616793036bf50bc4bbdf8c32",
        "geolocation": {
          "coordinates": [
            -95.284801,
            30.009869
          ],
          "type": "Point"
        },
        "school_name1": "JONES ELEMENTARY SCHOOL (ALDINE)",
        "mls_num": 84030397,
        "roof": "Composition",
        "modified_date": "2021-12-15T08:26:46.220",
        "uid": 201339969,
        "school_name2": "JONES MIDDLE SCHOOL (ALDINE)",
        "fireplace": "",
        "appointment_type": "",
        "school_district": "1 - Aldine",
        "foundation": "Slab",
        "house_type": "Traditional",
        "seller_contribution": 0,
        "hoa_amount": 350,
        "seller_type": "Mud",
        "hoa_frequency": "Annually",
        "hoa": "1",
        "school_name3": "NIMITZ HIGH SCHOOL (ALDINE)",
        "showing_instruction": "Appointment Required,Leave Business Card,Lockbox Front,Vacant/Unoccupied",
        "bathroom_total": 2,
        "bedroom": 3,
        "state": "Texas",
        "status": "Sold",
        "storie": 1,
        "street_name": "Shumaring",
        "street_box_num": 19926,
        "street_type": "Drive",
        "sold_date": "2021-11-23",
        "subdivision": "Deerbrook Estates Sec 04",
        "sold_price": 219000,
        "list_price_orig": 235000,
        "agent_listing_appt_phone": "713-729-1106",
        "listing_agent_name": "Evelyn Guinn",
        "carport": "",
        "garage": 2,
        "list_price": 225000,
        "list_date": "2021-10-13",
        "pool": 0,
        "construction_type": "Back Yard Fenced,Covered Patio/Deck,Fully Fenced,Porch",
        "zipcode": 77338,
        "dom": 17,
        "lot_description": "Subdivision Lot",
        "year_built": 2008,
        "acre": 5750,
        "prop_subtype": "",
        "prop_type": "Single-Family",
        "public_remark": "This is a great home built by Lennar. Large kitchen with nice sized breakfast room, big living room and the secondary bedrooms are good sized. The primary bedroom will accommodate a king size bed quite easily . Washer/dryer and refrigerator remain in the home. Private back yard with no back neighbors.",
        "city": "Humble",
        "acres": 0.132,
        "lat": 30.009869,
        "lon": -95.284801,
        "square_feet": 1620,
        "last_update": "Wed Dec 15 2021 10:18:30 GMT-0500 (EST)",
        "address": "19926 Shumaring Drive Humble Texas 77338",
        "dist": 342.78028020473783,
        "select": true
      }
    ],
    "leased": []
  }
}