import React from "react"
import {connect} from "react-redux"
import {run_cma} from "../../../data/actions/fastCMA";

import {Button, Typography, Row, Col, Table} from "antd";
import {LoadingOutlined} from "@ant-design/icons";

const {Title} = Typography

const CMAResults = (props) => {
    const {runCMA, data} = props
    const columns = [
        {
            title: 'Sold',
            dataIndex: 'sold',
            key: 'sold',
        },
        {
            title: 'Active',
            dataIndex: 'active',
            key: 'active',
        },
        {
            title: 'Non-Active',
            dataIndex: 'non-active',
            key: 'non-active',
        },
        {
            title: 'Leased',
            dataIndex: 'leased',
            key: 'leased',
        },]

    let count = [];
    let total = 0;
    console.log(222, data.payload)
    if (data.payload) {
        const row = {
            sold: data.payload.sold.length,
            active: data.payload.active.length,
            ['non-active']: data.payload.nonActive.length,
            ['leased']: data.payload.leased.length,
        };
        count.push(row)
    } else {
        count.push({
            sold: 0,
            active: 0,
            'non-active': 0,
            leased: 0
        })
    }

    return (
        <div className="row">
            <div className="col-3">
                <div
                    className={`fast-cma-findings__item ${(data.payload?.sold || []).length > 0 ? "fast-cma-findings__item--active" : ""}`}>
                    <div className="row align-items-center">
                        <div className="col-3 fs--50px text-end fw-bold fast-cma-findings__item--title">
                            {(data.payload?.sold || []).length}
                        </div>
                        <div className="col-9">
                            <p className="mb-1 text-start">Sold</p>
                            <p className="mb-0 text-start">Properties</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-3">
                <div
                    className={`fast-cma-findings__item ${(data.payload?.active || []).length > 0 ? "fast-cma-findings__item--active" : ""}`}>
                    <div className="row align-items-center">
                        <div className="col-3 fs--50px text-end fw-bold fast-cma-findings__item--title">
                            {(data.payload?.active || []).length}
                        </div>
                        <div className="col-9">
                            <p className="mb-1 text-start">Active</p>
                            <p className="mb-0 text-start">Properties</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-3">
                <div
                    className={`fast-cma-findings__item ${(data.payload?.leased || []).length > 0 ? "fast-cma-findings__item--active" : ""}`}>
                    <div className="row align-items-center">
                        <div className="col-3 fs--50px text-end fw-bold fast-cma-findings__item--title">
                            {(data.payload?.leased || []).length}
                        </div>
                        <div className="col-9">
                            <p className="mb-1 text-start">Leased</p>
                            <p className="mb-0 text-start">Properties</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-3">
                <div
                    className={`fast-cma-findings__item ${(data.payload?.nonActive || []).length > 0 ? "fast-cma-findings__item--active" : ""}`}>
                    <div className="row align-items-center">
                        <div className="col-3 fs--50px text-end fw-bold fast-cma-findings__item--title">
                            {(data.payload?.nonActive || []).length}
                        </div>
                        <div className="col-9">
                            <p className="mb-1 text-start">Non Active</p>
                            <p className="mb-0 text-start">Properties</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 mt-3 text-end">
                <Button onClick={runCMA} type="primary" className="border__radius--5px">Run CMA</Button>
            </div>
        </div>
    )
}

const mapStatesToProps = state => {
    const data = state.FastCMAReducer
    return {
        data: data.cma.result
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        runCMA: () => {
            dispatch(run_cma())
        }
    }
}

export default connect(mapStatesToProps, mapDispatchToProps)(CMAResults);
