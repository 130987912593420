import React, {useState, useEffect} from "react";
import {connect} from "react-redux";

import {Card, Row, Col, Switch, Table, Space} from "antd";

import GoogleMap from "../GoogleMap";
import {moneyFormatter, toTitleCase} from "../../../../../utils/commonUtils";
import {PROPERTY} from "../../../../../constants/fastCMA";
import './ComparablesEstimate.scss';

const formatNumber = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

const Statistics = connect(state => {
    const fastCMA = state.FastCMAReducer
    return {
        subject: fastCMA.cma.result.subject,
        properties: fastCMA.cma.result.payload,
        activeStatus: fastCMA.cma.active_status,
        selectedProperties: fastCMA.cma.selected_properties,
    }
}, null)(props => {
    const {className, activeStatus, properties, subject, isSelected, selectedProperties} = props
    const current = activeStatus === PROPERTY.STATUS.NONACTIVE.key ? PROPERTY.STATUS.SOLD.key : activeStatus;

    const propertiesData = isSelected ? properties[current].filter((i, idx) => selectedProperties[current].indexOf(idx) >= 0) : properties[current];
    let minPrice, maxPrice,
        minSquareFeet, maxSquareFeet,
        averagePrice = 0, averageSquareFeet = 0,
        minYearBuilt, maxYearBuilt, averageYearBuilt = 0,
        minBathRoom, maxBathRoom, averageBathRoom = 0,
        minRecency, maxRecency, averageRecency = 0,
        minDist, maxDist, averageDist = 0,
        minLotSize, maxLotSize, averageLotSize = 0;

    const today = new Date()
    propertiesData.forEach(item => {
        let recency = Math.round((today - new Date(item.sold_date || item.list_date)) / (24 * 60 * 60 * 1000))
        let distance = item.dist * 0.000621
        minRecency = !minRecency ? recency : (recency < minRecency ? recency : minRecency);
        maxRecency = !maxRecency ? recency : (recency < maxRecency ? maxRecency : recency);
        averageRecency += recency;

        minDist = !minDist ? distance : (distance < minDist ? distance : minDist);
        maxDist = !maxDist ? distance : (distance < maxDist ? maxDist : distance);
        averageDist += distance;

        minLotSize = !minLotSize ? item.acres : (item.acres < minLotSize ? item.acres : minLotSize);
        maxLotSize = !maxLotSize ? item.acres : (item.acres < maxLotSize ? maxLotSize : item.acres);
        averageLotSize += item.acres;

        let price = item['sold_price'] || item['list_price']
        minPrice = !minPrice ? price : (price < minPrice ? price : minPrice);
        maxPrice = !maxPrice ? price : (price < maxPrice ? maxPrice : price);
        averagePrice += price;

        minSquareFeet = !minSquareFeet ? item['square_feet'] : (item['square_feet'] < minSquareFeet ? item['square_feet'] : minSquareFeet);
        maxSquareFeet = !maxSquareFeet ? item['square_feet'] : (item['square_feet'] < maxSquareFeet ? maxSquareFeet : item['square_feet']);
        averageSquareFeet += item.square_feet;

        minYearBuilt = !minYearBuilt ? item['year_built'] : (item['year_built'] < minYearBuilt ? item['year_built'] : minYearBuilt);
        maxYearBuilt = !maxYearBuilt ? item['year_built'] : (item['year_built'] < maxYearBuilt ? maxYearBuilt : item['year_built']);
        averageYearBuilt += parseInt(item['year_built'] || 0);

        minBathRoom = !minBathRoom ? item['bathroom_total'] : (item['bathroom_total'] < minBathRoom ? item['bathroom_total'] : minBathRoom);
        maxBathRoom = !maxBathRoom ? item['bathroom_total'] : (item['bathroom_total'] < maxBathRoom ? maxBathRoom : item['bathroom_total']);
        averageBathRoom += parseInt(item['bathroom_total'] || 0);
    })

    const columns = [
        {
            title: `Available (${propertiesData.length})`,
            dataIndex: 'key'
        },
        {
            title: `${activeStatus === PROPERTY.STATUS.NONACTIVE.key || activeStatus === PROPERTY.STATUS.SOLD.key ? 'Sold' : (activeStatus === PROPERTY.STATUS.ACTIVE.key ? 'List' : 'Leased')} Price`,
            dataIndex: 'price'
        },
        {
            title: "Square feet",
            dataIndex: 'squareFeet'
        },
        {
            title: "Recency (days)",
            dataIndex: 'recency',
        },
        {
            title: "Year built",
            dataIndex: 'yearBuilt'
        },
        {
            title: "Bathroom",
            dataIndex: 'bathRoom'
        },
        {
            title: "Distance (miles)",
            dataIndex: 'distance'
        },
        {
            title: 'Lot size (acres)',
            dataIndex: 'acres'
        }
    ]

    let data = [
        {
            key: 'Minimum',
            price: moneyFormatter.format(minPrice),
            squareFeet: formatNumber(minSquareFeet),
            yearBuilt: minYearBuilt,
            bathRoom: minBathRoom,
            recency: minRecency,
            distance: Math.round(minDist * 100) / 100,
            acres: Math.round(minLotSize * 100) / 100,
        },
        {
            key: 'Maximum',
            price: moneyFormatter.format(maxPrice),
            squareFeet: formatNumber(maxSquareFeet),
            yearBuilt: maxYearBuilt,
            bathRoom: maxBathRoom,
            recency: maxRecency,
            distance: Math.round(maxDist * 100) / 100,
            acres: Math.round(maxLotSize * 100) / 100,
        },
        {
            key: 'Average',
            price: moneyFormatter.format((averagePrice / propertiesData.length).toFixed()),
            squareFeet: formatNumber(Math.round(averageSquareFeet / propertiesData.length)),
            yearBuilt: Math.round(averageYearBuilt / propertiesData.length),
            bathRoom: maxBathRoom,
            recency: Math.round(averageRecency / propertiesData.length),
            distance: Math.round(averageDist / propertiesData.length * 100) / 100,
            acres: Math.round(averageLotSize / propertiesData.length * 100) / 100,
        }
    ]
    return (
        <Table className={['statistic', className].join(' ')} columns={columns} dataSource={data}
               pagination={{position: ['none', 'none']}} style={{marginBottom: 12}}/>
    )
})

const EstimateBlock = (props) => {
    const {estimate, title, extra, switcher, statusKey, activeStatus} = props
    if (!estimate) {
        return null;
    }
    const round = statusKey === PROPERTY.STATUS.LEASED.key ? 2 : 0;
    return (
        <Card
            title={title}
            bordered={false}
            extra={extra}
            className="estimate__block"
        >
            <Row>
                <Col span={12}>
                    <span className="money">{moneyFormatter.format(estimate.sold_price.toFixed(round))}</span> <br/>
                    <span
                        className="lable">{statusKey === PROPERTY.STATUS.LEASED.key ? 'Monthly Lease' : `${toTitleCase(statusKey)} Price`}</span>
                </Col>
                <Col>
                    {(statusKey === PROPERTY.STATUS.SOLD.key
                            || statusKey === PROPERTY.STATUS.ACTIVE.key
                            || statusKey === PROPERTY.STATUS.NONACTIVE.key
                        ) &&
                        <>
                            <span
                                className="money">{moneyFormatter.format(estimate.price_per_square_foot.toFixed(2))}</span>
                            <br/>
                            <span
                                className="lable">{(statusKey === PROPERTY.STATUS.SOLD.key && activeStatus === PROPERTY.STATUS.NONACTIVE.key) ? 'Rental Index™' : 'Price per square foot'}</span>
                        </>
                    }
                    {statusKey === PROPERTY.STATUS.LEASED.key &&
                        <>
                            <span className="money">{(estimate.rental_index * 100).toFixed(2) || 0}</span> <br/>
                            <span className="lable">Rental Index</span>
                        </>
                    }
                </Col>
            </Row>
            {(typeof switcher === 'function' && activeStatus !== PROPERTY.STATUS.NONACTIVE.key) &&
                <Row style={{marginTop: 'auto'}}>
                    <Col span={24}>
                        <Switch size="small" style={{width: 30}} onChange={switcher}/> Statistics
                    </Col>
                </Row>
            }
        </Card>
    )
}

const ComparableEstimate = (props) => {
    const {subject, properties, selectedProperties, activeStatus, estimate} = props
    const [showStatistics, setShowStatistics] = useState(false)

    const selectedIndex = selectedProperties[activeStatus] || [];
    const data = properties[activeStatus] && properties[activeStatus].length ? properties[activeStatus].filter((item, idx) => selectedIndex.indexOf(idx) >= 0) : [];

    let coordinates = [[parseFloat(subject.lon), parseFloat(subject.lat)]];
    if (data.length > 0) {
        for (let property of data) {
            coordinates.push([
                property.lon,
                property.lat
            ]);
        }
    }

    const current = activeStatus === PROPERTY.STATUS.NONACTIVE.key ? PROPERTY.STATUS.SOLD.key : activeStatus;

    useEffect(() => {
        if (activeStatus === PROPERTY.STATUS.NONACTIVE.key) {
            setShowStatistics(false)
        }
    }, [activeStatus])
    const round = current === PROPERTY.STATUS.LEASED.key ? 2 : 0;
    return (
        <>
            <div className="row">
                <div className="col-9 symbol--vertical">
                    {
                        (props?.checkTab === props?.keyItem) ? <GoogleMap coordinates={coordinates}/> : null
                    }
                </div>
                <div className="col-3 ps-5">
                    <div>
                        <p className="fs--12px fw--600 mb-2">{`${toTitleCase(current)} CMA`}</p>
                        <div
                            className="hn__detail-cma--comparables-estimate hn__detail-cma--comparables-estimate-active mb-3">
                            <div className="mb-2">
                                <p className="fs--14px fw--500 mb-0">{current === PROPERTY.STATUS.LEASED.key ? 'Monthly Lease' : `${toTitleCase(current)} Price`}</p>
                                <p className="fs--24px fw--500 mb-0">{moneyFormatter.format(estimate[current].sold_price.toFixed(round))}</p>
                            </div>
                            <div>
                                <p className="fs--14px fw--500 mb-0">{(current === PROPERTY.STATUS.SOLD.key && activeStatus === PROPERTY.STATUS.NONACTIVE.key) ? 'Rental Index™' : 'Price per square foot'}</p>
                                <Space>
                                    <p className="fs--22px fw--500 mb-0 width--200px">{moneyFormatter.format(estimate[current].price_per_square_foot.toFixed(2))}</p>
                                    <span
                                        className="fs--12px fw--600 hn__detail-cma--comparables-estimate-tag hn__detail-cma--comparables-estimate-tag-active">{`${selectedProperties[current].length}/${properties[current].length}`}</span>
                                </Space>
                            </div>
                        </div>
                        {Object.values(PROPERTY.STATUS).map((item, k) => {
                            if (item.key === current || item.key === PROPERTY.STATUS.NONACTIVE.key || item.key === PROPERTY.STATUS.LEASED.key) return null;

                            return (
                                <>
                                    <p className="fs--12px fw--600 mb-2">{`${item.label} CMA`}</p>
                                    <div className="hn__detail-cma--comparables-estimate mb-3">
                                        <div className="mb-2">
                                            <p className="fs--14px fw--500 mb-0">{item.key === PROPERTY.STATUS.LEASED.key ? 'Monthly Lease' : `${toTitleCase(item.key)} Price`}</p>
                                            <p className="fs--24px fw--500 mb-0">{moneyFormatter.format(estimate[item.key].sold_price.toFixed(round))}</p>
                                        </div>
                                        {item.key === PROPERTY.STATUS.LEASED.key ? (
                                            <div>
                                                <p className="fs--14px fw--500 mb-0">Rental Index</p>
                                                <Space>
                                                    <p className="fs--24px fw--500 mb-0 width--200px">{(estimate.rental_index * 100).toFixed(2) || 0}</p>
                                                    <span
                                                        className="fs--12px fw--600 hn__detail-cma--comparables-estimate-tag">{`(${selectedProperties[item.key].length}/${properties[item.key].length})`}</span>
                                                </Space>
                                            </div>
                                        ) : (
                                            <div>
                                                <p className="fs--14px fw--500 mb-0">{(item.key === PROPERTY.STATUS.SOLD.key && activeStatus === PROPERTY.STATUS.NONACTIVE.key) ? 'Rental Index™' : 'Price per square foot'}</p>
                                                <Space>
                                                    <p className="fs--22px fw--500 mb-0 width--200px">{moneyFormatter.format(estimate[item.key].price_per_square_foot.toFixed(2))}</p>
                                                    <span
                                                        className="fs--12px fw--600 hn__detail-cma--comparables-estimate-tag">{`(${selectedProperties[item.key].length}/${properties[item.key].length})`}</span>
                                                </Space>
                                            </div>
                                        )}
                                    </div>
                                </>
                            )
                        })}
                    </div>
                </div>
            </div>
            {/*<Row className="comparable__overview">*/}
            {/*    <Col span={8}>*/}

            {/*    </Col>*/}
            {/*    <Col span={8} className={`comparable__type comparable__type--active`}>*/}
            {/*        <EstimateBlock*/}
            {/*            title={`${toTitleCase(current)} CMA`}*/}
            {/*            extra={`(${selectedProperties[current].length}/${properties[current].length})`}*/}
            {/*            estimate={estimate[current]}*/}
            {/*            switcher={(checked) => {*/}
            {/*                setShowStatistics(checked)*/}
            {/*            }}*/}
            {/*            statusKey={current}*/}
            {/*            activeStatus={activeStatus}*/}
            {/*        />*/}
            {/*    </Col>*/}
            {/*    <Col span={8} className={`comparable__type`}>*/}
            {/*        {Object.values(PROPERTY.STATUS).map(item => {*/}
            {/*            if (item.key === current || item.key === PROPERTY.STATUS.NONACTIVE.key) return null;*/}

            {/*            let itemData = properties[item.key] && properties[item.key].length ? properties[item.key].filter((item, idx) => selectedIndex.indexOf(idx) >= 0) : [];*/}

            {/*            return <EstimateBlock*/}
            {/*                title={`${item.label} CMA`}*/}
            {/*                estimate={estimate[item.key]}*/}
            {/*                extra={`(${selectedProperties[item.key].length}/${properties[item.key].length})`}*/}
            {/*                statusKey={item.key}*/}
            {/*                activeStatus={activeStatus}*/}
            {/*            />*/}
            {/*        })}*/}
            {/*    </Col>*/}
            {/*</Row>*/}
            {showStatistics &&
                <Card title="CMA Statistics" bordered={false}>
                    <Statistics/>
                    <Statistics className={"statistic--selected"} isSelected={true}/>
                </Card>
            }
        </>
    )
}

const mapStateToProps = state => {
    const fastCMA = state.FastCMAReducer

    return {
        subject: fastCMA.cma.result.subject,
        properties: fastCMA.cma.result.payload,
        activeStatus: fastCMA.cma.active_status,
        selectedProperties: fastCMA.cma.selected_properties,
        estimate: fastCMA.cma.estimate
    }
}

export default connect(mapStateToProps, null)(ComparableEstimate);
