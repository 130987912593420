import React, {useState, useEffect} from "react";
import {Link, withRouter} from "react-router-dom";
import routers from "../../../routers";
import {find} from "lodash";
import {Menu, Button, Drawer} from 'antd';
import {
    AppstoreOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    PieChartOutlined,
    DesktopOutlined,
    ContainerOutlined,
    MailOutlined,
} from '@ant-design/icons';
import {useSelector} from "react-redux";
import {capitalizeFirstLetter} from "../../../utils/functions";
import {getProfile} from "../../../data/reselects/authSelector";

const {SubMenu} = Menu;

const Navigation = (props) => {
    const [itemMenu, setItemMenu] = useState([]),
        itemAuthProfile = useSelector(getProfile())

    useEffect(() => {
        let menu = itemAuthProfile?.menu || [];
        let updatedMenu = [
            ...menu.slice(0, 1),
            {
                name: "Eva",
                group: "cma",
                route: "fast-cma"
            },
            {
                name: "Eva Profiles",
                group: "cma",
                route: "fast-cma/profiles"
            },
            ...menu.slice(1, menu.length)
        ]
        setItemMenu(updatedMenu)
    }, [itemAuthProfile.menu]);
    // const [collapsed, setCollapsed] = useState(false);
    //
    // const toggleCollapsed = () => {
    //     setCollapsed(!collapsed)
    // };

    const renderMenuItem = (item) => {
        let arrayMenu = [];
        if (item.length > 0) {
            item.map((i, k) => arrayMenu.push(
                <Menu.Item key={`/${i.route}`} className="">
                    <Link to={`/${i.route}`}
                          className="fs--18px text-decoration-none">{capitalizeFirstLetter(i.name)}</Link>
                </Menu.Item>
            ))
        }
        return arrayMenu
    }

    return (
        <>
            <div className={`hn__navigation d-none ${props?.collapsed ? "" : "d-lg-block"}`} style={{width: 230}}>
                {/*<Button type="primary" onClick={toggleCollapsed} style={{marginBottom: 16}}>*/}
                {/*    {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined)}*/}
                {/*</Button>*/}
                {
                    itemMenu.length > 0 && (
                        <Menu
                            defaultSelectedKeys={['/']}
                            selectedKeys={[props?.location?.pathname]}
                            mode="vertical"
                            theme="dark"
                            inlineCollapsed={props?.collapsed}
                            className="pt-3"
                        >
                            {
                                itemMenu.map((i, k) => {
                                    if ((i?.items || []).length > 0) {
                                        return (
                                            <SubMenu key={i?.group} title={i?.name} className="fs--18px"
                                                     onTitleClick={() => props.history.push(`/${i?.route}`)}>
                                                {renderMenuItem(i?.items || [])}
                                            </SubMenu>
                                        )
                                    } else {
                                        return (
                                            <Menu.Item key={`/${i.route}`} className="">
                                                <Link to={`/${i.route}`}
                                                      className="fs--18px text-decoration-none">{capitalizeFirstLetter(i.name)}</Link>
                                            </Menu.Item>
                                        )
                                    }
                                })
                            }
                        </Menu>
                    )
                }
                <div className="hn__navigation--footer-powered d-none d-lg-block">
                    <a href="https://megalithinc.com" target="_blank" className="text-decoration-none">
                        Powered by Megalith Solutions
                    </a>
                </div>
            </div>
            <Drawer title={<img src="./layout/logo.svg" width="200px" height="50px"/>}
                    className="hn__navigation--mobile"
                    placement="left" onClose={props.toggleCollapsed} visible={props?.collapsed}>
                {
                    itemMenu.length > 0 && (
                        <Menu
                            defaultSelectedKeys={['/']}
                            selectedKeys={[props?.location?.pathname]}
                            mode="inline"
                            theme="dark"
                        >
                            {
                                itemMenu.map((i, k) => {
                                    if ((i?.items || []).length > 0) {
                                        return (
                                            <SubMenu key={i?.group} title={i?.name} className="fs--18px">
                                                {renderMenuItem(i?.items || [])}
                                            </SubMenu>
                                        )
                                    } else {
                                        return (
                                            <Menu.Item key={`/${i.route}`} className="">
                                                <Link to={`/${i.route}`}
                                                      className="fs--18px text-decoration-none">{capitalizeFirstLetter(i.name)}</Link>
                                            </Menu.Item>
                                        )
                                    }
                                })
                            }
                        </Menu>
                    )
                }
            </Drawer>
        </>
    );
};

export default withRouter(Navigation);
