import React from "react";
import {connect} from "react-redux";
import {useParams} from "react-router-dom";

import {
    Select
} from "antd"

import {cma_load_profiles, cma_select_profile} from "../../../../data/actions/fastCMA";
import {useEffect} from "react";
import {CaretDownOutlined, CaretUpOutlined} from "@ant-design/icons";

const ProfileSelect = props => {
    const {profiles, editProfile, selectedProfile, disabled, className, loadProfiles} = props
    const {add} = useParams();
    useEffect(() => {
        let storeSearch = localStorage.getItem("search_eva");
        if (storeSearch) {
            storeSearch = JSON.parse(storeSearch)
            editProfile(storeSearch?.profile)
        }
    }, []);

    const onProfileChange = (value) => {
        let storeSearch = localStorage.getItem("search_eva");
        if (storeSearch) {
            storeSearch = JSON.parse(storeSearch)
            storeSearch = {
                ...storeSearch,
                profile: value
            }
            localStorage.setItem("search_eva", JSON.stringify(storeSearch))
        } else {
            localStorage.setItem("search_eva", JSON.stringify({profile: value}))
        }
        editProfile(value)
    }
    useEffect(() => {
        if (add !== 'add') {
            loadProfiles();
        }
    }, [])

    return (
        <Select
            suffixIcon={e => e?.open ? <CaretUpOutlined/> : <CaretDownOutlined/>}
            className={` custom--selector ${className ? className : ""}`}
            disabled={disabled || !profiles}
            loading={!profiles}
            // value={selectedProfile}
            value={`${localStorage.getItem("search_eva") ? JSON.parse(localStorage.getItem("search_eva") || {})?.profile ? JSON.parse(localStorage.getItem("search_eva") || {})?.profile : (selectedProfile || "") : (selectedProfile || "")}`}
            style={{width: 120}}
            onChange={onProfileChange}>
            {!profiles && <Select.Option key={`profile-default`} value="default">Default</Select.Option>}
            {profiles && profiles.map(item =>
                <Select.Option key={`profile-${item.id}`} value={item.id}>{item.name}</Select.Option>
            )}
        </Select>
    )
};

export default connect(state => {
    const data = state.FastCMAReducer

    return {
        profiles: data.cma.profiles || null,
        selectedProfile: data.cma.edit_profile
    }
}, dispatch => {

    return {
        loadProfiles: () => {
            dispatch(cma_load_profiles())
        },
        editProfile: id => {
            dispatch(cma_select_profile(id))
        }
    }
})(ProfileSelect);
