import React, { useEffect, useState } from "react"
import { connect } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

import MainLayout from "../../../common/layout/MainLayout";
import { cma_load_profiles, cma_select_profile, cma_delete_profile, cma_update_profile } from "../../../../data/actions/fastCMA";

import { 
  Form,
  Input,
  Button,
  Select,
  Anchor,
  InputNumber,
  Modal,
 } from "antd"

import ProfileSelect from './ProfileSelect'
import "./Profile.scss"
import { PlusOutlined } from "@ant-design/icons";

const Profile = (props) => {
  const { loadProfiles, profiles, updateProfile, deleteProfile } = props;
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const navigate = useHistory();

  const configs = {
    RECENCY : { 
        label: 'RECENCY',
        default: 360,
        tooltip: "in days"
    },
    DISTANCE : { 
        label: 'DISTANCE',
        default: 804.672,
        tooltip: "0.5 miles FMV (ARV SUBDIVISION)"
    },
    ARV_DISTANCE : { 
        label: 'ARV_DISTANCE',
        default: 804.672,
        tooltip: "Revert back to 0.5 miles(25 sept, 2019)  old-changes=>  0.5 mile to 1 miles (1609.344) 28 March, 2019"
    },
    YEAR_BUILT_RANGE : { 
        label: 'YEAR_BUILT_RANGE',
        default: 10,
        tooltip: " number +/-"
    }, //
    SQUARE_FEET_RANGE : { 
        label: 'SQUARE_FEET_RANGE',
        default: 25
          ,tooltip: "percentage +/- (25%) FMV (SUBDIVISION)",
    },
    ARV_SQUARE_FEET_RANGE : { 
        label: 'ARV_SQUARE_FEET_RANGE',
        default: 20
          ,tooltip: "percentage +/- (20%) ARV (DISTANCE)",
    },
    FMV_ACRE_RANGE : { 
        label: 'FMV_ACRE_RANGE',
        default: 25
          ,tooltip: "percentage +/- (25%) FMV (SUBDIVISION)",
    },
    ARV_ACRE_RANGE : { 
        label: 'ARV_ACRE_RANGE',
        default: 20
          ,tooltip: "percentage +/- (20%) ARV (DISTANCE)",
    },
    SUBDIVISION : { 
        label: 'SUBDIVISION',
        default: 20000
    },
    DISTANCE1 : { 
        label: 'DISTANCE1',
        default: 1000
          ,tooltip: "0.25 Miles",
    },
    DISTANCE2 : { 
        label: 'DISTANCE2',
        default: 800
          ,tooltip: "0.5 Miles",
    },
    DISTANCE3 : { 
        label: 'DISTANCE3',
        default: 400
          ,tooltip: "0.75 Miles",
    },
    DISTANCE4 : { 
        label: 'DISTANCE4',
        default: 200
          ,tooltip: "1 Miles",
    },
    SQUARE_FOOTAGE1 : { 
        label: 'SQUARE_FOOTAGE1',
        default: 700
          ,tooltip: "0 %",
    },
    SQUARE_FOOTAGE2 : { 
        label: 'SQUARE_FOOTAGE2',
        default: 650
          ,tooltip: "2 %",
    },
    SQUARE_FOOTAGE3 : { 
        label: 'SQUARE_FOOTAGE3',
        default: 600
          ,tooltip: "4 %",
    },
    SQUARE_FOOTAGE4 : { 
        label: 'SQUARE_FOOTAGE4',
        default: 550
          ,tooltip: "6 %",
    },
    SQUARE_FOOTAGE5 : { 
        label: 'SQUARE_FOOTAGE5',
        default: 500
          ,tooltip: "8 %",
    },
    SQUARE_FOOTAGE6 : { 
        label: 'SQUARE_FOOTAGE6',
        default: 450
          ,tooltip: "10 %",
    },
    SQUARE_FOOTAGE7 : { 
        label: 'SQUARE_FOOTAGE7',
        default: 400
          ,tooltip: "12 %",
    },
    SQUARE_FOOTAGE8 : { 
        label: 'SQUARE_FOOTAGE8',
        default: 350
          ,tooltip: "14 %",
    },
    SQUARE_FOOTAGE9 : { 
        label: 'SQUARE_FOOTAGE9',
        default: 300
          ,tooltip: "16 %",
    },
    SQUARE_FOOTAGE10 : { 
        label: 'SQUARE_FOOTAGE10',
        default: 250
          ,tooltip: "18 %",
    },
    SQUARE_FOOTAGE11 : { 
        label: 'SQUARE_FOOTAGE11',
        default: 200
          ,tooltip: "20 %",
    },
    YEAR_BUILT1 : { 
        label: 'YEAR_BUILT1',
        default: 1000
          ,tooltip: "0 +/-",
    },
    YEAR_BUILT2 : { 
        label: 'YEAR_BUILT2',
        default: 900
          ,tooltip: "1 +/-",
    },
    YEAR_BUILT3 : { 
        label: 'YEAR_BUILT3',
        default: 800
          ,tooltip: "2 +/-",
    },
    YEAR_BUILT4 : { 
        label: 'YEAR_BUILT4',
        default: 700
          ,tooltip: "3 +/-",
    },
    YEAR_BUILT5 : { 
        label: 'YEAR_BUILT5',
        default: 600
          ,tooltip: "4 +/-",
    },
    YEAR_BUILT6 : { 
        label: 'YEAR_BUILT6',
        default: 500
          ,tooltip: "5 +/-",
    },
    YEAR_BUILT7 : { 
        label: 'YEAR_BUILT7',
        default: 400
          ,tooltip: "6 +/-",
    },
    YEAR_BUILT8 : { 
        label: 'YEAR_BUILT8',
        default: 300
          ,tooltip: "7 +/-",
    },
    YEAR_BUILT9 : { 
        label: 'YEAR_BUILT9',
        default: 200
          ,tooltip: "8 +/-",
    },
    YEAR_BUILT10 : { 
        label: 'YEAR_BUILT10',
        default: 100
          ,tooltip: "9 +/-",
    },
    ACRE1 : { 
        label: 'ACRE1',
        default: 90
          ,tooltip: "0% to less than 10%",
    },
    ACRE2 : { 
        label: 'ACRE2',
        default: 80
          ,tooltip: "10% to less than 20%",
    },
    ACRE3 : { 
        label: 'ACRE3',
        default: 70
          ,tooltip: "20% to less than 30%",
    },
    ACRE4 : { 
        label: 'ACRE4',
        default: 60
          ,tooltip: "30% to less than 40%",
    },
    ACRE5 : { 
        label: 'ACRE5',
        default: 50
          ,tooltip: "40% to less than 50%",
    },
    ACRE6 : { 
        label: 'ACRE6',
        default: 40
          ,tooltip: "50% to less than 75%",
    },
    ACRE7 : { 
        label: 'ACRE7',
        default: 30
          ,tooltip: "75% to less than 100%",
    },
    STORIE : { 
        label: 'STORIE',
        default: 100
    },
    POOL : { 
        label: 'POOL',
        default: 5000
          ,tooltip: "comparable property types shoud be Single-Family.",
    },
    GARAGE : { 
        label: 'GARAGE',
        default: 2500
          ,tooltip: "comparable property types shoud be Single-Family.",
    },
    HOUSE_TYPE : { 
        label: 'HOUSE_TYPE',
        default: 9
          ,tooltip: "comparable property types shoud be Single-Family.",
    },
    FOUNDATION_TYPE : { 
        label: 'FOUNDATION_TYPE',
        default: 9
    },
    HOUSE_STYLE : { 
        label: 'HOUSE_STYLE',
        default: 9
    },
    CONSTRUCTION_TYPE : { 
        label: 'CONSTRUCTION_TYPE',
        default: 9
    },
    ROOF_TYPE : { 
        label: 'ROOF_TYPE',
        default: 9
    },
    CARPORT : { 
        label: 'CARPORT',
        default: 9
          ,tooltip: "comparable property types shoud be Single-Family.",
    },
    FIREPLACE : { 
        label: 'FIREPLACE',
        default: 4
          ,tooltip: "comparable property types shoud be Single-Family.",
    },
    HOA : { 
        label: 'HOA',
        default: 4
    },
    SCHOOL_DISTRICT : { 
        label: 'SCHOOL_DISTRICT',
        default: 4
    },
    SCHOOL_ELEMENTARY : { 
        label: 'SCHOOL_ELEMENTARY',
        default: 2
    },
    SCHOOL_MIDDLE : { 
        label: 'SCHOOL_MIDDLE',
        default: 2
    },
    SCHOOL_HIGH: {
      label: "SCHOOL_HIGH",
      default:  2
    }
  }

  let { add } = useParams();
  let selectedProfile = add === 'add' ? 'new' : props.selectedProfile;
  const defaultProfile = {
    id: 'new',
    name: 'default'
  }
  const loadCurrentProfile = () => {
    if ( add === 'add' ) return defaultProfile;

    let profile = profiles && profiles.length ? profiles.filter(i => i.id === selectedProfile) : {};
  
    if ( profile.length ) {
      if ( typeof profile[0].configs !== 'undefined' ) {
        return {
          id: profile[0].id,
          name: profile[0].name,
          ...profile[0].configs
        }
      } else {
        return profile[0];
      }
    } else {
      profile = (profiles && profiles.length ? profiles[0] : {});
    }
    return profile;
  }

  let currentProfile = loadCurrentProfile()

  const [form] = Form.useForm();
  let initialValues = {
    name: "Untitle"
  };
  for( let key in configs) {
    let value = (configs[key].default || '');
    initialValues[key] = value;
  }

  useEffect(() => {
    loadProfiles();
  }, []);

  useEffect(() => {
    setShowConfirmDeleteModal(false);

    let formValues;
    let profile = loadCurrentProfile();

    if ( add === 'add' ) {
      formValues = {
        name: 'Untitle'
      }
    } else {
      formValues = {
        name: profile.name || 'Untitle'
      }
    }

    for( let key in configs) {
      let value = (profile[key] || configs[key].default || '');
      formValues[key] = value;
    }

    form.setFieldsValue(formValues);

  }, [selectedProfile, profiles])

  
  const onSubmitHandle = (values) => {
    if ( selectedProfile === 'default' ) {
      return;
    }
    
    updateProfile(values, selectedProfile === 'new' ? false : selectedProfile, navigate);
  }

  const onDeleteProfile = () => {
    setShowConfirmDeleteModal(true);
  }

  const confirmDelete = () => {
    deleteProfile(currentProfile.id, navigate);
  }

  const cancelDeleteProfile = () => {
    setShowConfirmDeleteModal(false);
  }
  
  return (
		<MainLayout
      className="fast-cma-profile-manager"
			pathname={props.location.pathname}
			history={props.history}
			disabledTab={true}
			title={
        <div className="fast-cma-title-wrapper">
          <Anchor className="fast-cma-title" affix={false}>
            <Anchor.Link href="#reset"  title="Profile Management" />
          </Anchor>
          {add !== 'add' && (
            <div className="fast-cma-profile-actions">
              <ProfileSelect /> 
              <Button className="fast-cma-add-new-profile ms-3" size="small" type="primary" onClick={() => {
                navigate.push('/fast-cma/profiles/add')
              }}>Add new</Button>
            </div>
          )}
        </div>
      }
    >
      <Form
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        name="profile-manage-form"
        form={form}
        className="mt-5 profile-manage-form"
        onFinish={onSubmitHandle}
        initialValues={initialValues}
        size="small"
        labelAlign="left"
      >

        <Form.Item name={'name'} key={`profile-item-name`} label="Name">
          <Input disabled={'default'===selectedProfile} />
        </Form.Item>

        {Object.keys(configs).map(key => {
          return (
            <Form.Item 
              name={key} 
              key={`profile-item-${key}`} 
              label={configs[key].label}
              tooltip={configs[key].tooltip || ''}
            >
              <InputNumber disabled={'default'===selectedProfile} />
            </Form.Item>
          )
        })}
        {selectedProfile !== 'default' &&
          <Form.Item wrapperCol={{ span: 14, offset: 6 }} >
            <Button type="primary" size="large" htmlType="submit">
              Submit
            </Button>
            {add === 'add' && <Button className="ms-2" type="ghost" size="large" onClick={() => {
              navigate.push('/fast-cma')
            }}>Cancel</Button>}
            {add !== 'add' && 
              <>
                <Button className="ms-2" type="primary" danger size="large" onClick={onDeleteProfile}>
                  Delete
                </Button>
                <Modal className="fast-cma-profile-remove-modal" title="Delete Profile" visible={showConfirmDeleteModal} onOk={confirmDelete} onCancel={cancelDeleteProfile}>
                  <p>Remove <strong>{currentProfile.name}</strong> profile?</p>
                  <Input hidden value={selectedProfile} disabled />
                </Modal>
              </>
            }
          </Form.Item>
        }
      </Form>
    </MainLayout>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    loadProfiles: () => {
      dispatch(cma_load_profiles())
    },
    updateProfile: (values, id, navigate) => {
      dispatch(cma_update_profile({
        data: {
          id: id,
          ...values
        }, 
        navigate: navigate
      }))
    },
    deleteProfile: (id, navigate) => {
      dispatch(cma_delete_profile({
        id: id,
        navigate: navigate
      }))
    }
  }
}

const mapStatesToProps = state => {
  const data = state.FastCMAReducer;
  return {
    profiles: data.cma.profiles,
    selectedProfile: data.cma.edit_profile || 'default'
  }
}

export default connect(mapStatesToProps, mapDispatchToProps)(Profile);