import {useCallback} from 'react'
import {useDispatch} from 'react-redux'

import * as HookAction from '../actions/comparablesRequestAction'
import {comparablesRequestInitStore} from "../actions/comparablesRequestAction";

export const ActionInit = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.comparablesRequestInitStore(params)),
        [dispatch]
    )
}

export const ActionGetList = () => {
    const dispatch = useDispatch()
    return useCallback(
        (filters) => dispatch(HookAction.comparablesRequestListAction(filters)),
        [dispatch]
    )
}

export const ActionGetItem = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.comparablesRequestDetailAction(params)),
        [dispatch]
    )
}
