import ApiService from '../../utils/ApiService';
import Urls from '../../utils/Urls';
import * as Func from '../../utils/functions';
import {removeObjectNull} from "../../utils/functions";
import ComparablesRequestResponse from "../mapping/Response/ComparablesRequestResponse";

// export function masterContact() {
//     return new ApiService({
//         baseURL: process.env.REACT_APP_API_URL,
//         endpoint: Urls.CONTACT_MASTER_DATA,
//         // params: removeObjectNull({ city_id }),
//         parser: data => new ContactMasterResponse(data.data.result).exportMaster(),
//     }).get();
// }

export function actionList(params) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.COMPARABLES_REQUEST_LIST,
        params: params,
        parser: dataParser,
    }).get();
}

// export function actionContactExport(params) {
//     return new ApiService({
//         baseURL: process.env.REACT_APP_API_URL,
//         endpoint: Urls.CONTACT_LIST,
//         params: params,
//         isDownload: true,
//         parser: data => data,
//     }).get();
// }

export function actionDetail(id) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.COMPARABLES_REQUEST_DETAIL,
        endpointParams: {id},
        parser: data => new ComparablesRequestResponse(data.data.result).exportDetail(),
    }).get();
}

// export function update(data) {
//     return new ApiService({
//         baseURL: process.env.REACT_APP_API_URL,
//         endpoint: Urls.CONTACT_UPDATE,
//         endpointParams: {id: data?.id},
//     }).put(data);
// }
//
// export function create(data) {
//     return new ApiService({
//         baseURL: process.env.REACT_APP_API_URL,
//         endpoint: Urls.CONTACT_CREATE,
//     }).post(data);
// }
//
// export function deleteFunction(id, ids = []) {
//     return new ApiService({
//         baseURL: process.env.REACT_APP_API_URL,
//         endpoint: Urls.CONTACT_DELETE,
//         endpointParams: {id},
//     }).delete();
// }

function dataParser(data = {}) {
    return {
        ...data.data,
        result: (data.data.result || []).map(item => new ComparablesRequestResponse(item).exportList()),
        total_page: Math.ceil(parseInt(data.data.total) / parseInt(data.data.page_size)),
        usage_count: data?.data?.usage_count || ""
    }
}
