import React, {useEffect, useState} from "react";

import {Card, Row, Col, Tabs, Typography, Table, Radio, Checkbox, Button, Modal, Form, Input} from "antd";
import {StarFilled} from "@ant-design/icons";
import {connect} from "react-redux";

import {PROPERTY} from "../../../../constants/fastCMA";
import {moneyFormatter} from "../../../../utils/commonUtils";
import {cma_export_pdf} from "../../../../data/actions/fastCMA";

import Image from "../Image";
import "./ReportAndShare.scss";

const {TabPane} = Tabs;
const {Title, Text} = Typography
const {TextArea} = Input


const EstimateCard = connect(state => {
    const data = state.FastCMAReducer;

    return {
        subject: data.cma?.result.subject,
        properties: data.cma?.result.payload,
        selectedProperty: data.cma?.selected_properties,
        estimate: data.cma?.estimate,
        defaultEstimate: {
            distance: data?.cma.result.distance,
            recency: data?.cma.result.recency,
            active: {
                grossDiscount: data?.cma.result.activeEstGrossDiscount,
                percentDiscount: data?.cma.result.activeEstPercentDiscount,
                price: data?.cma.result.activeEstimatedPrice
            },
            leased: {
                monthly: data?.cma.result.estimatedMonthlyLease,
                rentalIndex: data?.cma.result.rentalIndex
            },
            sold: {
                grossDiscount: data?.cma.result.soldEstGrossDiscount,
                percentDiscount: data?.cma.result.soldEstPercentDiscount,
                price: data?.cma.result.soldEstimatedPrice
            }
        },
    }
}, null)((props) => {
    const {estimate, subject, status, defaultEstimate, extend, selectedProperty, properties, reportType} = props

    const currentStatus = Object.values(PROPERTY.STATUS).filter(i => i.key === status)[0];
    const soldPrice = estimate[status].sold_price;
    const activePrice = estimate[PROPERTY.STATUS.ACTIVE.key].sold_price;

    let estimateStatus = status === PROPERTY.STATUS.ACTIVE.key ? PROPERTY.STATUS.ACTIVE.key : PROPERTY.STATUS.SOLD.key;
    let selectedProp = properties[status] ? properties[status].filter((i, idx) => selectedProperty[status].indexOf(idx) >= 0) : [];
    let avgDom = 0;

    if (selectedProp.length > 0) {
        for (let i in selectedProp) {
            if (selectedProp[i].dom) avgDom += selectedProp[i].dom
        }
        avgDom = avgDom / selectedProp.length;
    }


    const gross = defaultEstimate[PROPERTY.STATUS.SOLD.key].price - defaultEstimate[PROPERTY.STATUS.SOLD.key].grossDiscount;
    const percent = defaultEstimate[PROPERTY.STATUS.SOLD.key].percentDiscount.toFixed(2)
    // const avgDom
    const gridStyle = {
        width: extend ? '16.65%' : '25%'
    }

    return (
        <Card className="short-estimate-board" title={false} style={{
            backgroundColor: 'transparent'
        }}>
            {(extend || (!extend && status !== PROPERTY.STATUS.LEASED.key)) &&
                <Card.Grid hoverable={false} style={gridStyle}>
                    <span className="money">{moneyFormatter.format(Math.floor(soldPrice))}</span>
                    <span className="label">Estimated {currentStatus.label} price</span>
                </Card.Grid>
            }

            {extend && reportType === 'retail-report' &&
                <>
                    <Card.Grid hoverable={false} style={gridStyle}>
                        <span
                            className="money">{moneyFormatter.format(Math.round(soldPrice / subject?.square_feet))}</span>
                        <span className="label">Sold price per square foot</span>
                    </Card.Grid>
                    <Card.Grid hoverable={false} style={gridStyle}>
                        <span className="money">{moneyFormatter.format(Math.round(activePrice))}</span>
                        <span className="label">Estimate active price</span>
                    </Card.Grid>
                    <Card.Grid hoverable={false} style={gridStyle}>
                        <span
                            className="money">{moneyFormatter.format(Math.round(activePrice / subject?.square_feet))}</span>
                        <span className="label">Active price per square foot</span>
                    </Card.Grid>
                </>
            }

            {(extend
                    || (!extend && status === PROPERTY.STATUS.LEASED.key)) &&
                <Card.Grid hoverable={false} style={gridStyle}>
                    <span
                        className="money">{moneyFormatter.format(Math.floor(estimate[PROPERTY.STATUS.LEASED.key].sold_price))}</span>
                    <span className="label">Monthly lease</span>
                </Card.Grid>
            }

            {!extend && status !== PROPERTY.STATUS.LEASED.key &&
                <>
                    <Card.Grid hoverable={false} style={gridStyle}>
                        <span
                            className="money">{moneyFormatter.format(Math.round(soldPrice / subject?.square_feet))}</span>
                        <span className="label">Price per square foot</span>
                    </Card.Grid>
                    <Card.Grid hoverable={false} style={gridStyle}>
                        <span className="money">{Math.round(avgDom)}</span>
                        <span className="label">Average days on market</span>
                    </Card.Grid>
                </>
            }

            {(extend && reportType !== 'retail-report') &&
                <>
                    <Card.Grid hoverable={false} style={gridStyle}>
                        <span className="money">{moneyFormatter.format(Math.floor(gross))}</span>
                        <span className="label">Asking/offer price</span>
                    </Card.Grid>
                    <Card.Grid hoverable={false} style={gridStyle}>
                        <span className="money">{moneyFormatter.format(Math.floor(soldPrice - gross))}</span>
                        <span className="label">{currentStatus.label} IQ Discount™</span>
                    </Card.Grid>
                    <Card.Grid hoverable={false} style={gridStyle}>
                        <span className="money">%{percent}</span>
                        <span className="label">{currentStatus.label} IQ Discount %™</span>
                    </Card.Grid>
                </>
            }
            {((extend && reportType !== 'retail-report') || (!extend && status === PROPERTY.STATUS.LEASED.key)) &&
                <Card.Grid hoverable={false} style={gridStyle}>
                    <span
                        className="money">{((estimate[PROPERTY.STATUS.LEASED.key].sold_price / parseFloat(gross)) * 100).toFixed(2)}</span>
                    <span className="label">Rental Index™</span>
                </Card.Grid>
            }
        </Card>
    )
})

const DetailListing = (props) => {
    const {data} = props
    return (
        <ul style={{listStyle: 'none', padding: 0}}>
            {data.map((item, idx) =>
                <li key={`detail-listing-${idx}`} className={item.tab ? "sub-prop" : ''}>
                    {item.label && <strong>{item.label}: </strong>}
                    {item.value}
                </li>
            )}
        </ul>
    )
}

const StatusTableRow = props => {
    const {data, status, reportType} = props
    return (
        <tr>
            <td>{data.address}</td>
            <td>{data.status}</td>

            <td>{data.dist ? Math.round(data.dist * 0.000621 * 100) / 100 : <StarFilled/>}</td>
            <td>{data.subdivision}</td>
            <td>{data.year_built}</td>
            <td>{data.square_feet}</td>
            <td>{(data.bedroom || 'N/A')}/ {(data.bathroom_total || 'N/A')}</td>
            <td>{(data.garage || 'N/A')}/ {(data.carport || 'N/A')}/ {(data.pool || 'N/A')}</td>


            {reportType !== 'retail-report' &&
                <>
                    <td>{status.key === PROPERTY.STATUS.SOLD.key ? data.sold_date : data.list_date}</td>
                    <td>{data.dom}</td>
                    <td>{moneyFormatter.format(data.list_price_orig)}</td>
                    <td>{moneyFormatter.format(status.key === PROPERTY.STATUS.SOLD.key ? data.sold_price : data.list_price)}</td>
                    <td>{moneyFormatter.format(Math.round((status.key === PROPERTY.STATUS.SOLD.key ? data.sold_price : data.list_price) / data.square_feet))}</td>
                </>
            }
        </tr>
    )
}

const StatusTable = connect(state => {
    const data = state.FastCMAReducer

    return {
        subject: data.cma?.result.subject,
        properties: data.cma?.result.payload,
        selectedProperty: data.cma?.selected_properties
    }
}, null)(props => {
    const {status, subject, properties, selectedProperty, reportType} = props
    const checkedList = selectedProperty[status.key];
    const rows = properties[status.key].filter((i, idx) => checkedList.indexOf(idx) >= 0);

    return (
        <>
            <Title level={3}>{status.label}</Title>
            {reportType !== 'retail-report' && <EstimateCard status={status.key} single={true}/>}
            <table className="table table--report">
                <thead>
                <tr>
                    <th colSpan={2}>Property</th>
                    <th colSpan={6}>Details</th>
                    {reportType !== 'retail-report' && <th colSpan={5}>Sales Information</th>}
                </tr>
                <tr>
                    <th>Address</th>
                    <th>Status</th>

                    <th>Distance</th>
                    <th>Subdivision</th>

                    <th>Year built</th>
                    <th>Square feet</th>
                    <th>Bed/ bath</th>
                    <th>Garage/ carport/ pool</th>

                    {reportType !== 'retail-report' &&
                        <>
                            <th>{status.key === 'sold' ? 'Sold' : 'List'} date</th>
                            <th>Days on market</th>
                            <th>Original Price</th>
                            <th>{status.key === 'sold' ? 'Sold' : 'List'} price</th>
                            <th>{status.key === 'sold' ? 'Sold' : 'List'} price/ square foot</th>
                        </>
                    }
                </tr>
                </thead>
                <tbody>
                <StatusTableRow reportType={reportType} status={status} key={`data-${status.key}-${subject?.mls_num}`}
                                data={subject}/>
                {rows && rows.map(i => <StatusTableRow reportType={reportType} status={status} key={`data-${i.mls_num}`}
                                                       data={i}/>)}
                </tbody>
            </table>
        </>
    )
})


const ReportAndShare = (props) => {
    const {subject, exportReportPDF, estimate, properties} = props
    let reportOptions = [];
    for (let key in PROPERTY.STATUS) {
        reportOptions.push({
            label: PROPERTY.STATUS[key].label,
            value: PROPERTY.STATUS[key].key,
            checked: true,
        })
    }
    reportOptions.push({
        label: 'MLS Information',
        checked: true,
        value: 'mls-infor'
    })
    reportOptions.push({
        label: 'Photos',
        value: 'photo'
    })

    const [reportOptionsSelect, setReportOptionsSelect] = useState(reportOptions.map(i => i.value))

    const [reportType, setReportType] = useState('investment-report')
    const [shareModalVisible, setModalVisible] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [downloading, setDownloading] = useState(false)
    const [shareEmails, setShareEmails] = useState()

    const changeReportTypeHandle = (event) => {
        const updateType = event.target.value;
        setReportType(updateType)
    }

    const changeReportOptionsHandle = (checkedValues) => {
        setReportOptionsSelect(checkedValues)
    }

    const onExportReportPDF = () => {
        setDownloading(true)
        exportReportPDF({
            options: reportOptionsSelect,
            type: reportType
        })
    }

    const onExportReportEmail = () => {
        setConfirmLoading(true);
        exportReportPDF({
            options: reportOptionsSelect,
            type: reportType,
            isEmail: true,
            emails: shareEmails
        });

        setTimeout(() => {
            setModalVisible(false);
            setConfirmLoading(false);
        }, 2000);
    }

    const handleCancelExportReportEmail = () => {
        setModalVisible(false);
    }

    const onShare = () => {
        setModalVisible(true);
    }

    const handleTextAreaChange = (event) => {
        setShareEmails(event.target.value)
    }

    useEffect(() => {
        window.addEventListener('cma-file-download-already', (event) => {
            setDownloading(false)
        });
    }, []);
    return (
        <div className="px-4 mb-3">
            <div className="hn__detail-cma--comparables box--shadow border__radius--10px p-3 report-and-share">
                <Row className="comparable__short-estimate">
                    <Col span="24">
                        <Radio.Group buttonStyle="solid" className="report-type-switcher" onChange={changeReportTypeHandle}
                                     defaultValue="retail-report">
                            <Radio.Button value="retail-report">Retail Report</Radio.Button>
                            <Radio.Button value="investment-report">Investment Report</Radio.Button>
                        </Radio.Group>
                        <div className="report__change-options">
                            <p>Display the following information in the report:</p>
                            <Checkbox.Group options={reportOptions} value={reportOptionsSelect}
                                            onChange={changeReportOptionsHandle}/>

                            <div style={{
                                display: "flex",
                                justifyContent: "right"
                            }}>
                                <Button onClick={onExportReportPDF} type="primary" style={{marginRight: "1em"}}
                                        loading={downloading}>Download PDF</Button>
                                <Button onClick={onShare} type="primary">Share Report</Button>
                                <Modal
                                    title="Share report"
                                    visible={shareModalVisible}
                                    onOk={onExportReportEmail}
                                    confirmLoading={confirmLoading}
                                    onCancel={handleCancelExportReportEmail}
                                    style={{minWidth: "70vw"}}
                                    okText="Send email"
                                >
                                    <h4 style={{marginBottom: "24px"}}><StarFilled/> {subject?.address}</h4>
                                    <h6>Add Email Address (optional)</h6>

                                    <Text type="note">Email addresses entered here will not be saved to your contact
                                        list</Text>
                                    <Form
                                        labelCol={{span: 4}}
                                        wrapperCol={{span: 14}}
                                        layout="vertical"
                                    >
                                        <Form.Item
                                            children
                                            input
                                            name="share-report-emails"
                                            help="If you don't see the email in your Inbox, please look at your spam or filters. You may need to whitelist 'realestateiq.me' to get emails from us.">
                                            <TextArea
                                                rows={4}
                                                placeholder="Enter email addresses, separated by comma."
                                                value={shareEmails}
                                                onChange={handleTextAreaChange}
                                            />
                                        </Form.Item>
                                    </Form>
                                </Modal>
                            </div>
                        </div>
                    </Col>
                    <Col span={24} className="report__item">
                        <Title level={3}>Summary</Title>
                        <Title level={4}><StarFilled/> {subject?.address}</Title>
                        {
                            Object?.keys(estimate || {}).length > 0 ?
                                <EstimateCard status={PROPERTY.STATUS.SOLD.key} extend={true}
                                              reportType={reportType}/> : null
                        }
                    </Col>

                    <Col span="24" className="report__item">
                        <Title level={3}>Details</Title>
                        <Row>
                            <Col span={12}>
                                <DetailListing data={[
                                    {
                                        label: 'Property type & subtype',
                                        value: [subject?.prop_type, subject?.prop_subtype].join(' & ')
                                    },
                                    {
                                        label: 'House type	Traditional',
                                        value: subject?.house_type
                                    },
                                    {
                                        label: 'Architecture style',
                                        value: subject?.architecture_style || 'N/A'
                                    },
                                    {
                                        label: 'Construction type',
                                        value: subject?.construction_type || 'N/A'
                                    },
                                    {
                                        label: 'Foundation',
                                        value: subject?.foundation || 'N/A',
                                        tab: 1
                                    },
                                    {
                                        label: 'Roof',
                                        value: subject?.roof || 'N/A',
                                        tab: 1
                                    },
                                    {
                                        label: 'Subdivision',
                                        value: subject?.subdivision || 'N/A',
                                    },
                                    {
                                        label: 'School district',
                                        value: subject?.school_district || 'N/A',
                                    },
                                    {
                                        label: 'Elementary School',
                                        value: subject?.school_name1 || 'N/A',
                                    },
                                    {
                                        label: 'Middle School',
                                        value: subject?.school_name2 || 'N/A',
                                    },
                                    {
                                        label: 'High School',
                                        value: subject?.school_name3 || 'N/A',
                                    },
                                ]}/>
                            </Col>
                            <Col span={12}>
                                <DetailListing data={[
                                    {
                                        label: 'Year build',
                                        value: subject?.year_built
                                    },
                                    {
                                        label: 'Square feet',
                                        value: subject?.square_feet
                                    },
                                    {
                                        label: 'Bed / bath',
                                        value: (subject?.bedroom || 'N/A') + ' / ' + (subject?.bathroom_total || 'N/A')
                                    },
                                    {
                                        label: 'Stories',
                                        value: subject?.storie || 'N/A'
                                    },
                                    {
                                        label: 'Pool',
                                        value: subject?.pool || 'N/A',
                                    },
                                    {
                                        label: 'Garage / carport',
                                        value: (subject?.garage || 'N/A') + ' / ' + (subject?.carport || 'N/A')
                                    },
                                    {
                                        label: 'Fireplace',
                                        value: subject?.fireplace || 'N/A',
                                    },
                                    {
                                        label: 'Lotsize (acres)',
                                        value: subject?.acres || 'N/A',
                                    },
                                ]}/>
                            </Col>
                        </Row>
                    </Col>

                    {reportOptionsSelect.indexOf('mls-infor') >= 0 &&
                        <Col span="24" className="report__item">
                            <Title level={3}>MLS Information</Title>
                            <Row>
                                <Col span={12}>
                                    <DetailListing data={[
                                        {
                                            label: 'MLS #',
                                            value: subject?.mls_num
                                        },
                                        {
                                            label: 'MLS status',
                                            value: subject?.status
                                        },
                                        {
                                            label: 'List date',
                                            value: subject?.list_date || 'N/A'
                                        },
                                        {
                                            label: 'Agent name',
                                            value: subject?.listing_agent_name || 'N/A'
                                        },
                                    ]}/>
                                </Col>
                                <Col span={12}>
                                    <DetailListing data={[
                                        {
                                            label: 'Listing Price',
                                            value: subject?.list_price
                                        },
                                        {
                                            label: 'List price per square feet',
                                            value: Math.round(subject?.list_price / subject?.square_feet)
                                        },
                                        {
                                            label: 'Days on market',
                                            value: subject?.dom || 'N/A'
                                        },
                                        {
                                            label: 'Agent Phone number',
                                            value: subject?.agent_listing_appt_phone || 'N/A'
                                        },
                                    ]}/>
                                </Col>

                                <Col span={24}>
                                    <DetailListing data={[
                                        {
                                            label: 'Listing agent remarks',
                                            value: subject?.public_remark
                                        },
                                    ]}/>
                                </Col>
                            </Row>
                        </Col>
                    }

                    {Object.keys(properties || []).length > 0 ? Object.values(PROPERTY.STATUS).map(i => {
                        if (reportOptionsSelect.indexOf(i.key) < 0) {
                            return null;
                        }
                        return (
                            <Col span={24} className="report__item">
                                <StatusTable reportType={reportType} status={i}/>
                            </Col>
                        )
                    }) : null}

                    {/*{reportOptionsSelect.indexOf('photo') >= 0 &&*/}
                    {/*    <Col span="24" className="report__item">*/}
                    {/*        <Title level={3}>Photos</Title>*/}
                    {/*        <Row>*/}
                    {/*            {[...Array(24)].map((i, idx) =>*/}
                    {/*                <Col span={12}>*/}
                    {/*                    <Image*/}
                    {/*                        id={subject?.uid}*/}
                    {/*                        index={idx}*/}
                    {/*                        width="100%"*/}
                    {/*                        height={"auto"}*/}
                    {/*                    />*/}
                    {/*                </Col>*/}
                    {/*            )}*/}
                    {/*        </Row>*/}
                    {/*    </Col>*/}
                    {/*}*/}

                    <Col span={24}>
                        <Text type="secondary">Our automated CMA is a calculation of estimated market price, computed using
                            proprietary algorithms with 100% MLS data. It is not an appraisal. It is a starting point in
                            determining a home's price. It is a broker price opinion or comparative market analysis and
                            should not be considered an appraisal or opinion of value. In making any decision that relies
                            upon Agents or computer work, you should know that we have not followed the guidelines for
                            development of an appraisal or analysis contained in the Uniform Standards of Professional
                            Appraisal Practice of the Appraisal Foundation.</Text>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default connect(state => {
    const data = state.FastCMAReducer

    return {
        subject: data.cma?.result.subject,
        properties: data.cma?.result.payload,
        estimate: data.cma?.estimate
    }
}, dispatch => {
    return {
        exportReportPDF: (params) => {
            dispatch(cma_export_pdf(params))
        }
    }
})(ReportAndShare);
