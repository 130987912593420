import {pick} from "lodash";
import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define";
import {removeObjectNullFull} from "../../../utils/functions";

export default class FastCMAAddresLookupRequest {
    constructor(data = {}) {
        this.setData(data);
    }

    setData(data = {}) {
        this.address = data?.address;
        this.street_name = data?.street_name;
        this.street_box_num = data?.street_box_num;
        this.city = data?.city || '';
        this.state = data?.state || '';
        this.zipcode = data?.zipcode || '';
        this.Lat = data?.Lat || '';
        this.Lon = data?.Lon || '';
        this.marketplace = data?.marketplace.toUpperCase() || 'HOUSTON';
    }

    exportAddressDetail() {
        return removeObjectNullFull({
            address: this.address,
            street_name: this.street_name,
            street_box_num: this.street_box_num,
            city: this.city,
            state: this.state,
            zipcode: this.zipcode,
            Lat: this.Lat,
            Lon: this.Lon,
            marketplace: this.marketplace
        });
    }
}


export class FastCMAFindComparableWrapperRequest {
    constructor(data = {}) {
        this.setData(data);
    }

    setData(data = {}) {
        this.type = data['type'] || null
        this.latitude = data['latitude'] || null
        this.longitude = data['longitude'] || null
        this.year_built = data['year-build'] || null
        this.square_feet = data['square-feet'] || null
        this.property_type = data['property-type'] || null
        this.property_subtype = data['property-sub-type'] || null
        this.mls_no = data['subject'] || null
        this.lot_size_are = data['lot_size_are'] || null

        this.distance = data['distance'] || null
        this.year_range = data['year-build-range'] || null
        this.acre_range = data['acre_range'] || null
        this.lot_size_are = data['lot_size_are'] || null
        this.squarefeet_range = data['square-feet-range'] || null
        this.address = data['address'] || null
        this.street_box_num = data['street_box_num'] || null
        this.street_name = data['street_name'] || null
        this.city = data['city'] || null
        this.state = data['state'] || null
        this.zipcode = data['zipcode'] || null
        this.recency = data['recency'] || null
        this.market_name = data['market_name'] || 'houston'
        this.profile = data['profile'] || 'default'
        this.property_address = data['property_address'] || ''
        this.idres_property = data['idres_property'] || ''
    }

    exportArvDistanceQuery() {
        return removeObjectNullFull({
            market_name: this.market_name,
            profile: this.profile,
            type: this.type,
            latitude: this.latitude,
            longitude: this.longitude,
            year_built: this.year_built,
            square_feet: this.square_feet,
            property_type: this.property_type,
            property_subtype: this.property_subtype,
            mls_no: this.mls_no,
            lot_size_are: this.lot_size_are,
            property_address: this.property_address,
            idres_property: this.idres_property,
        });
    }

    exportCustomFilter() {
        return removeObjectNullFull({
            market_name: this.market_name,
            profile: this.profile,
            type: this.type,
            latitude: this.latitude,
            longitude: this.longitude,
            distance: this.distance,
            year_built: this.year_built,
            year_range: this.year_range,
            acre_range: this.acre_range,
            lot_size_are: this.lot_size_are,
            square_feet: this.square_feet,
            squarefeet_range: this.squarefeet_range,
            address: this.address,
            street_box_num: this.street_box_num,
            street_name: this.street_name,
            city: this.city,
            state: this.state,
            zipcode: this.zipcode,
            recency: this.recency,
            property_type: this.property_type,
            property_subtype: this.property_subtype,
            mls_no: this.mls_no,
            property_address: this.property_address,
            idres_property: this.idres_property,
        })
    }

    exportFindPopup(data){
        return {
            property_type: data["property-type"] || "",
            property_subtype: data["property-sub-type"] || "",
            year_built: data["year-build"] || "",
            latitude: data["latitude"] || "",
            longitude: data["longitude"] || "",
            property_address: data["property_address"] || "",
            square_feet: data["square-feet"] || "",
            idres_property: data["idres_property"] || "",
        }
    }
}
