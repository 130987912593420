import {combineReducers} from "redux";
import authReducer from './authReducer';
import FastCMAReducer from "./fastCMAReducer";
import comparablesRequestReducer from "./comparablesRequestReducer";

export default combineReducers({
    authReducer,
    FastCMAReducer,
    comparablesRequestReducer
});
