import * as Types from "../types/FastCMA"

export const ggmap_lookup = (params) => ({type: Types.GG_MAP_LOOKUP, params})
export const mls_lookup = (params) => ({type: Types.MLS_LOOKUP, params})

export const address_lookup = (params) => ({type: Types.ADDRESS_LOOKUP, params})
export const address_lookup_reset = (params) => ({type: Types.ADDRESS_LOOKUP_RESET, params})
export const address_lookup_success = (params) => ({type: Types.ADDRESS_LOOKUP_SUCCESS, params})
export const address_lookup_fail = (params) => ({type: Types.ADDRESS_LOOKUP_FAIL, params})

export const filter_comparables = (params) => ({type: Types.FILTER_COMPARABLES, params})
export const find_comparables = (params) => ({type: Types.FIND_COMPARABLES, params})
export const find_comparables_success = (params) => ({type: Types.FIND_COMPARABLES_SUCCESS, params})
export const change_market_channel = (params) => ({type: Types.CHANGE_MARKET_CHANNEL, params})

export const switch_type = () => ({type: Types.SWITCH_TYPE})
export const store_google_api_session = (params) => ({type: Types.GOOGLE_SPACES_SESSION_TOKEN, params})
export const run_cma = (params) => ({type: Types.RUN_CMA, params})
export const set_selected_property = (params) => ({type: Types.CMA_SET_SELECTED_PROPERTY, params})
export const cma_set_active_status = (params) => ({ type: Types.CMA_SET_ACTIVE_STATUS, params})

export const show_cma_report = (params) => ({type: Types.SHOW_CMA_REPORTS, params})
export const cma_export_pdf = (params) => ({type: Types.EXPORT_CMA_REPORT_PDF, params})

export const cma_load_profiles = (params) => ({ type: Types.CMA_LOAD_PROFILES, params })
export const cma_load_profile_success = (params) => ({ type: Types.CMA_LOAD_PROFILES_SUCCESS, params })
export const cma_load_profiles_fail = (params) => ({ type: Types.CMA_LOAD_PROFILES_FAIL, params })
export const cma_select_profile = (params) => ({ type: Types.CMA_SELECT_PROFILE, params })
export const cma_update_profile = (params) => ({ type: Types.CMA_UPDATE_PROFILE, params })
export const cma_update_profile_success = (params) => ({ type: Types.CMA_UPDATE_PROFILE_SUCCESS, params })

export const cma_delete_profile = params => ({ type: Types.CMA_DELETE_PROFILE, params })
export const cma_delete_profile_success = params => ({ type: Types.CMA_DELETE_PROFILE_SUCCESS, params })
export const cmd_init = () => ({ type: Types.CMA_INIT})
