import {call, put, takeLatest, fork, all, delay} from 'redux-saga/effects';
import * as Types from '../../data/types/Auth';
import * as AuthService from './../services/authService';
import * as Func from "../../utils/functions";
import history from '../../utils/history';
import * as Auth from "../actions/authAction";
import $ from "jquery";
import {get} from "lodash";
import {CONFIG_LOCAL_STORAGE, PATHS, REFRESH_TOKEN_DELAY_TIMEOUT, ROUTE_LIST} from '../../constants/define';
import * as LocalStorage from '../../utils/localStorage';
import {message} from 'antd';
import moment from 'moment';
import UserRequest from "../mapping/Request/UserRequest";
import TAG_DEFINE from "../../constants/common";
import AuthRequest from "../mapping/Request/AuthRequest";
import {checkUserAction} from "../actions/authAction";

function* redirectTo(location) {
    yield history.push(location);
}

function* loginSaga() {
    yield takeLatest(Types.LOGIN_ACTION, Func.sagaWrapper(function* (action) {
        const body = action.params;
        const data = yield call(AuthService.login, body.email, body.password, body?.code, body?.token_captcha);
        if ((data.data.status).toUpperCase() === "SUCCESS") {
            yield put({type: Types.LOGIN_SUCCESS});
            LocalStorage.set(CONFIG_LOCAL_STORAGE.ACCESS_TOKEN, data.data.data.result.token);
            LocalStorage.set(CONFIG_LOCAL_STORAGE.REFRESH_TOKEN, data.data.data.result.refreshToken);
            // LocalStorage.set(CONFIG_LOCAL_STORAGE.EXPIRES, data.expires);
            yield put({type: Types.CHECK_USER_ACTION});
            window.location = `/${body?.fullAddress ? `?fullAddress=${body?.fullAddress}` : ""}`
            // yield call(redirectTo, ROUTE_LIST.PreForeClosure);
        } else {
            yield errorHandleAuth(Types.LOGIN_FAILED, data.data)
        }
    }, errorHandle(Types.LOGIN_FAILED)))
}

function* logoutSaga() {
    yield takeLatest(Types.LOGOUT_ACTION, Func.sagaWrapper(function* (action) {
        yield call(AuthService.logout);
        LocalStorage.remove(CONFIG_LOCAL_STORAGE.ACCESS_TOKEN);
        LocalStorage.remove(CONFIG_LOCAL_STORAGE.REFRESH_TOKEN);
        LocalStorage.remove(CONFIG_LOCAL_STORAGE.EXPIRES);
        LocalStorage.remove(CONFIG_LOCAL_STORAGE.PROFILE);
        history.go(PATHS.LOGIN.path);
    }, errorHandle(Types.LOGIN_FAILED)))
}

function* checkUserSaga() {
    yield takeLatest(Types.CHECK_USER_ACTION, Func.sagaWrapper(function* (action) {
        const data = yield call(AuthService.getUserProfile);
        // LocalStorage.set(CONFIG_LOCAL_STORAGE.PROFILE, data);
        yield put(Auth.checkUserSuccess(data));
    }, errorHandleProfile(Types.LOGIN_FAILED)))
}

function* refreshTokenFunc(action, shouldGetProfile = true) {
    const refreshToken = action.refreshToken || LocalStorage.get(CONFIG_LOCAL_STORAGE.REFRESH_TOKEN);
    const expires = LocalStorage.get(CONFIG_LOCAL_STORAGE.EXPIRES);
    const shouldRefresh = parseInt(new Date().getTime()) >= (parseInt(new Date(expires).getTime()) - REFRESH_TOKEN_DELAY_TIMEOUT)
    let intervalLoaded = false;

    function* refreshTokenInterval() {
        if (shouldGetProfile) {
            yield put({type: Types.CHECK_USER_ACTION});
        }
        if (!intervalLoaded) {
            const expireDate = LocalStorage.get(CONFIG_LOCAL_STORAGE.EXPIRES);
            const delayTimeout = ((parseInt(new Date(expireDate).getTime()) - parseInt(new Date().getTime())) - REFRESH_TOKEN_DELAY_TIMEOUT);
            yield delay(delayTimeout);
            yield call(refreshTokenFunc, {refreshToken: LocalStorage.get(CONFIG_LOCAL_STORAGE.REFRESH_TOKEN)}, false);
            intervalLoaded = true;
        }
    }

    if (shouldRefresh && refreshToken) {
        const data = yield call(AuthService.refreshToken, refreshToken);
        if (data.accessToken) {
            yield put({type: Types.REFRESH_TOKEN_SUCCESS});
            LocalStorage.set(CONFIG_LOCAL_STORAGE.ACCESS_TOKEN, data.accessToken);
            LocalStorage.set(CONFIG_LOCAL_STORAGE.REFRESH_TOKEN, data.refreshToken);
            LocalStorage.set(CONFIG_LOCAL_STORAGE.EXPIRES, data.expires);
            yield refreshTokenInterval();
        }
    } else {
        yield refreshTokenInterval();
    }
}

function* refreshTokenSaga() {
    yield takeLatest(Types.REFRESH_TOKEN_ACTION, Func.sagaWrapper(refreshTokenFunc, errorHandleRefresh(Types.LOGIN_FAILED)))
}

function* create() {
    yield takeLatest(Types.REGISTER_ACTION, Func.sagaWrapper(function* (action) {
        const data = new UserRequest(action.params).actionRegister();
        const result = yield call(AuthService.register, data);
        // message.success(TAG_DEFINE.VALIDATION.statusCode.code["201"]);
        yield put(Auth.registerSuccess(result?.data?.data?.result));
        // setTimeout(() => window.location.reload(), 1500)
    }, errorHandle(Types.LOGIN_FAILED)))
}

function* errorHandleAuth(errorActionType, data) {
    if ((data.status).toUpperCase() === "FAIL") {
        message.error(data.errors.message);
        yield put({type: errorActionType});
        return false;
    } else if ((data.status).toUpperCase() === "VALIDATION") {
        message.error("Lỗi validation");
        yield put({type: errorActionType});
        return false;
    }
    message.error("Lỗi hệ thống");
    console.warn(data);
    yield put({type: errorActionType});
}

function errorHandle(errorActionType) {
    return Func.sagaErrorHandler(function* (e) {
        yield put({type: errorActionType})
    });
}

function errorHandleProfile(errorActionType) {
    return function* (e) {
        if (e.status === 401) {
            yield put({type: Types.LOGOUT_ACTION});
            LocalStorage.remove(CONFIG_LOCAL_STORAGE.ACCESS_TOKEN);
            LocalStorage.remove(CONFIG_LOCAL_STORAGE.REFRESH_TOKEN);
            LocalStorage.remove(CONFIG_LOCAL_STORAGE.EXPIRES);
            LocalStorage.remove(CONFIG_LOCAL_STORAGE.PROFILE);
            history.go(PATHS.LOGIN.path);
        } else {
            // message.error("Kết nối thất bại", true);
            console.warn(e);
            yield put({type: errorActionType});
        }
    };
}

function* updateProfile() {
    yield takeLatest(Types.UPDATE_PROFILE_ACTION, Func.sagaWrapper(function* (action) {
        const data = new AuthRequest(action.params).exportUpdate();
        yield call(AuthService.update, data);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["204"]);
        // yield put({ type: Types.HOTEL_GET_ITEM_ACTION });
        // yield put({ type: Types.VOUCHER_FAIL });
        yield put(Auth.updateProfileSuccess());
        yield put(checkUserAction());
        // yield put(VoucherActions.listVoucherAction(action.params.filters || {}));
    }, errorHandle(Types.LOGIN_FAILED)))
}

function* passwordRequest() {
    yield takeLatest(Types.PASSWORD_REQUEST_ACTION, Func.sagaWrapper(function* (action) {
        const params = new AuthRequest(action.params).exportPasswordRequest();
        const result = yield call(AuthService.passwordRequest, params);
        // message.success(TAG_DEFINE.VALIDATION.statusCode.code["204"]);
        yield put(Auth.passwordRequestSuccess(result));
    }, errorHandle(Types.LOGIN_FAILED)))
}

function* passwordReset() {
    yield takeLatest(Types.PASSWORD_RESET_ACTION, Func.sagaWrapper(function* (action) {
        const params = new AuthRequest(action.params).exportPasswordReset();
        const result = yield call(AuthService.passwordReset, params);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["207"]);
        yield put(Auth.passwordResetSuccess(result));
    }, errorHandle(Types.LOGIN_FAILED)))
}

function errorHandleRefresh(errorActionType) {
    return function* (e) {
        // if (e.errors) {
        //     LocalStorage.remove(CONFIG_LOCAL_STORAGE.ACCESS_TOKEN);
        //     LocalStorage.remove(CONFIG_LOCAL_STORAGE.REFRESH_TOKEN);
        //     LocalStorage.remove(CONFIG_LOCAL_STORAGE.EXPIRES);
        //     history.go(PATHS.LOGIN.path);
        // } else {
        //     Func.alertMessage(i18n.t('notifi.title'), i18n.t('validation.usernameOrPasswordNotMatch'), true);
        //     yield put({ type: errorActionType });
        // }
        // console.warn(e)
    };
}

export function* authSaga() {
    yield all([
        fork(loginSaga),
        fork(logoutSaga),
        fork(checkUserSaga),
        fork(refreshTokenSaga),
        fork(create),
        fork(updateProfile),
        fork(passwordRequest),
        fork(passwordReset),
    ])
}
