export const required = value => (value || value === undefined) ? undefined : true;
export const requiredArray = value => (value || value.length > 0) ? undefined : true;
export const maxLength = (value,max) => value && value.length > max ? true : undefined;
export const minLength = (value,min) => value && value.length < min  ? true : undefined;
export const isNumber = value => value && isNaN(Number(value)) ? true : undefined;
export const minValueNumber = (value,min) => [null, undefined, ''].indexOf(value) === -1 && parseInt(value) < parseInt(min) ? true : undefined;
export const maxValueNumber = (value,max) => parseInt(value) > parseInt(max) ? true : undefined;
export const compareString = (value,value2) => value.toString() !== value2 ? true : undefined;
export const maxImage = (value,max) => parseInt(value ? value.length : 0) > parseInt(max) ? true : undefined;
export const minImage = (value,min) => parseInt(value ? value.length : 0) < parseInt(min) ? true : undefined;
export const isEmail = value => value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? true : undefined;
export const isUrl = value => value && !/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$|^#$/i.test(value) ? true : undefined;
export const isPhone = value => value && /^[+0-9]{1}[0-9]+$/i.test(value) ? true : undefined;
export const isSpecialString = value => value && /(<\/?[\S][^>]*>)|([^a-zA-Z0-9@])$/i.test(value) ? true : undefined;
export const hadScriptTag = value => value && /<\/?script>/g.test(value) ? true : undefined;
export const strongPassword = value => value && !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W]).{6,}$/g.test(value) ? true : undefined;
export const isWhieSpace = value => value && /[\s]/g.test(value) ? true : undefined;
