export default class ApiResponse {
    constructor(data){
        this.data = data.data || undefined;
        this.customMessageErrors = data.customMessageErrors || undefined;
        this.code = data.code || undefined;
        this.errors = data.errors || undefined;
        this.status = data.status || undefined;
        this.statusText = data.statusText;
        this.headers = data.headers || undefined;
        this.message = data.message || undefined;
        this.success = data.success || false;
        this.request = data.request || undefined;
    }
}
