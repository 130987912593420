import React, {useEffect, useState} from "react"
import {EditOutlined, ShareAltOutlined} from "@ant-design/icons"

import {Radio, Row, Col, Image, Card, Modal, Button, Typography, Checkbox} from "antd"
import {mls_search_result as searchResult} from "../../../../data/demos/demo_cma";

import Comparables from "./Comparables";
import SubjectDetailOverview from "./Subject";
import "./Report.scss"
import {PROPERTY} from "../../../../constants/fastCMA";

const Report = (props) => {
    const data = searchResult.payload
    const [selectedProperty, setSelectedProperty] = useState({}),
        [defaultEstimate, setDefaultEstimate] = useState({})

    useEffect(() => {
        if (Object.keys(props?.subject || {}).length > 0 && Object.keys(props?.properties || {}).length > 0) {
            let selected_properties = {},
                newDefaultEstimate = {
                    distance: props?.response?.distance,
                    recency: props?.response?.recency,
                    active: {
                        grossDiscount: props?.response?.activeEstGrossDiscount,
                        percentDiscount: props?.response?.activeEstPercentDiscount,
                        price: props?.response?.activeEstimatedPrice
                    },
                    leased: {
                        monthly: props?.response?.estimatedMonthlyLease,
                        rentalIndex: props?.response?.rentalIndex
                    },
                    sold: {
                        grossDiscount: props?.response?.soldEstGrossDiscount,
                        percentDiscount: props?.response?.soldEstPercentDiscount,
                        price: props?.response?.soldEstimatedPrice
                    }
                }
            setDefaultEstimate(newDefaultEstimate)
            for (let i in PROPERTY.STATUS) {
                let currentStatus = (PROPERTY.STATUS[i])
                selected_properties[currentStatus.key] = props?.properties && props?.properties[currentStatus.key] ?
                    props?.properties[currentStatus.key].map((i, idx) => ({
                        index: idx,
                        select: i.select
                    })).filter(i => i.select).map(i => i.index) : [];
            }
            setSelectedProperty(selected_properties)
        }
    }, []);

    const calculateEstimate = (subject, properties, activeStatus) => {
        let result = {}

        for (let key in PROPERTY.STATUS) {
            let status = PROPERTY.STATUS[key]
            let sold_price = 0;
            let data = properties[status.key]
            let price_per_square_foot = 0;

            if (properties) {
                data = data.filter((item, index) => selectedProperty[status.key].indexOf(index) >= 0)
                if (data.length > 0) {
                    for (let property of data) {
                        price_per_square_foot += ((property.sold_price || property.list_price) || 0) / property.square_feet;
                        sold_price += (property.sold_price || property.list_price) || 0;
                    }

                    price_per_square_foot = price_per_square_foot / data.length;
                    sold_price = sold_price / data.length;
                    // sold_price = price_per_square_foot * subject.square_feet;
                }
            }

            result[status.key] = {
                price_per_square_foot: price_per_square_foot,
                sold_price: sold_price
            }
            if (status.key === PROPERTY.STATUS.LEASED.key) {
                result[status.key].rental_index = sold_price / subject.list_price
            }
        }
        return result;
    }

    return (
        <div className="px-4 mt-5 mb-3">
            <SubjectDetailOverview params={props?.params}/>

            <Comparables
                resultTable={props?.resultTable || []}
                sendResultTable={e => props?.sendResultTable(e)}
                source={data}
                onShowReportnShare={e => props?.onShowReportnShare(e)}
                tabActive={props?.tabActive}
                getTabActive={e => props?.getTabActive(e)}
            />
        </div>
    )
}

export default Report;
