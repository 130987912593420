import * as Types from '../types/ComparablesRequest';

export const comparablesRequestListAction = (filters) => ({type: Types.COMPARABLES_REQUEST_LIST_ACTION, filters});
export const comparablesRequestListSuccess = (list) => ({
    type: Types.COMPARABLES_REQUEST_LIST_SUCCESS,
    list
});

export const comparablesRequestDetailAction = (params) => ({type: Types.COMPARABLES_REQUEST_DETAIL_ACTION, params});
export const comparablesRequestDetailSuccess = (detail) => ({
    type: Types.COMPARABLES_REQUEST_DETAIL_SUCCESS,
    detail
});

export const comparablesRequestInitStore = (params) => ({type: Types.COMPARABLES_REQUEST_INIT, params});
export const comparablesRequestClearStore = (params) => ({type: Types.COMPARABLES_REQUEST_FAIL, params});
