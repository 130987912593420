import React, {useState} from "react";
import {connect} from "react-redux";

import {
    Card,
    Row,
    Col,
    Image as AntdImage,
    Button,
    Modal,
    Collapse,
    Space,
    InputNumber,
    Input,
    Form,
    Badge
} from "antd";
import {EditOutlined, ShareAltOutlined} from "@ant-design/icons";
import SubjectDetails from "./SubjectDetails";
import FindComparablesForm from "./../../FindComparablesForm";
import Image from "../../Image";
import {show_cma_report} from "../../../../../data/actions/fastCMA";

const SubjectDetailOverview = (props) => {
    const {subject, market, showReport} = props
    const [showSubjectDetail, setIsSubjectModalVisible] = useState(false);
    const [showFindComparableModal, setshowFindComparableModal] = useState(false);

    const openFindComparableModal = (evt) => {
        setshowFindComparableModal(true);
    }

    const hideFindComparableModal = (evt) => {
        setshowFindComparableModal(false);
    }

    const openDetailModal = (event) => {
        setIsSubjectModalVisible(true)
    }

    const hideDetailModalHandle = () => {
        setIsSubjectModalVisible(false)
    }

    const showReportAndShare = () => {
        showReport();
    }

    if (!subject) {
        return null;
    }

    return (
        <>
            <Collapse defaultActiveKey={['1']} expandIconPosition="right" onChange={() => {
            }} className="mb-3 hn__detail-cma--section-overview box--shadow">
                <Collapse.Panel header="Property details & Value" key="1">
                    <div className="row position-relative">
                        <div className="col-6 symbol--vertical">
                            <Space align="start">
                                <div className="position-relative">
                                    <p className="hn__detail-cma--section-overview-img-blur">No Image</p>
                                    <Image id={subject.uid} width="266px" height="223px"
                                           className="hn__detail-cma--section-overview-img"/>
                                </div>
                                <div className="ms-3 position-relative height--223px">
                                    <p className="fs--12px fw--500">{subject.prop_type} <img src="/img/verified.svg"/>
                                    </p>
                                    <p className="h4 fw-bold fs--16px mb-3">{props.params?.property_address || "-"}</p>
                                    <Space align="center" className="hn__detail-cma--section-overview-size-info mb-3">
                                        <img src="/img/square-feet.svg" className="width--16px height--16px"/>
                                        <span className="fs--12px me-1 symbol--vertical">{subject?.square_feet || "0"} sq ft</span>
                                        <img className="ms-3" src="/img/acres.svg"/>
                                        <span className="fs--12px">{subject?.acres || ""} acres</span>
                                    </Space>
                                    <div/>
                                    <Space align="center" className="mb-3 position-relative">
                                        <div className="me-2 symbol--vertical">
                                            <p className="fs--12px fw--400 mb-0">House type</p>
                                            <p className="fs--12px fw--400 mb-0">{subject?.house_type || ""}</p>
                                        </div>
                                        <div className="mx-3 symbol--vertical">
                                            <p className="fs--12px fw--400 mb-0">Subdivision</p>
                                            <p className="fs--12px fw--400 mb-0">{subject?.subdivision || ""}</p>
                                        </div>
                                        <div className="ms-2 ">
                                            <p className="fs--12px fw--400 mb-0">Year built</p>
                                            <p className="fs--12px fw--400 mb-0">{subject?.year_built || ""}</p>
                                        </div>
                                    </Space>
                                    <div/>
                                    <p className="hn__detail-cma--section-overview-info" onClick={openDetailModal}>
                                        + info
                                    </p>
                                </div>
                            </Space>
                        </div>
                        <div className="col-6 ps-5">
                            <p className="fs--14px fw--600">Estimated Value</p>
                            <Space align="center" className="mb-3 position-relative">
                                <div className="me-2 symbol--vertical">
                                    <p className="fs--12px fw--400 mb-0">Estimated Price</p>
                                    <p className="fs--20px fw--500 mb-0">$0</p>
                                </div>
                                <div className="ms-3">
                                    <p className="fs--12px fw--400 mb-0">Montly Lease</p>
                                    <p className="fs--20px fw--500 mb-0">$0</p>
                                </div>
                            </Space>
                            <div/>
                            <p className="fs--14px fw--600 mt-4">Investment Summary</p>
                            <Space align="center" className="position-relative mb-0">
                                <div className="me-2 symbol--vertical">
                                    <p className="fs--12px fw--400 mb-0">Offer / Asking Price</p>
                                    <p className="fs--20px fw--500 mb-0">
                                        <Form.Item label="" className="custom--input mb-0">
                                            <InputNumber disabled prefix="$" style={{width: '100%'}}/>
                                        </Form.Item>
                                    </p>
                                </div>
                                <div className="mx-3 symbol--vertical">
                                    <p className="fs--12px fw--400 mb-0">IQ Discount</p>
                                    <p className="fs--20px fw--500 mb-0">
                                        $0
                                        <Badge
                                            className="ms-2 mt--s5px"
                                            count={"+0%"}
                                            style={{backgroundColor: 'rgba(2, 177, 90, 0.2)', color: "#02B15A"}}
                                        />
                                    </p>
                                </div>
                                <div className="ms-2">
                                    <p className="fs--12px fw--400 mb-0">Rental Index</p>
                                    <p className="fs--20px fw--500 mb-0">0</p>
                                </div>
                            </Space>
                        </div>
                    </div>
                </Collapse.Panel>
            </Collapse>

            {/*<Card className="subject-property__details" title={subject.address}>*/}
            {/*    <Row>*/}
            {/*        <Col span={3}>*/}
            {/*            <Image id={subject.uid}/>*/}
            {/*        </Col>*/}
            {/*        <Col span={6}>*/}
            {/*            <ul>*/}
            {/*                <li><strong>Year build:</strong> {subject.year_built}</li>*/}
            {/*                <li><strong>Square feet:</strong> {subject.square_feet}</li>*/}
            {/*                <li><strong>Lot size (acres):</strong> {subject.acres}</li>*/}
            {/*                <li><Button size="small" type="link" onClick={openDetailModal}>Details</Button></li>*/}
            {/*            </ul>*/}
            {/*        </Col>*/}
            {/*        <Col span={6}>*/}
            {/*            <ul>*/}
            {/*                <li><strong>Property type:</strong> {subject.prop_type}</li>*/}
            {/*                <li><strong>House type:</strong> {subject.house_type}</li>*/}
            {/*                <li><strong>Subdivision:</strong> {subject.subdivision}</li>*/}
            {/*            </ul>*/}
            {/*        </Col>*/}
            {/*        <Col className="flex-right" span={9}>*/}
            {/*            <Button icon={<EditOutlined/>} size="small" type="link" onClick={openFindComparableModal}>Edit*/}
            {/*                CMA criteria</Button>*/}
            {/*            <Button onClick={showReportAndShare} icon={<ShareAltOutlined/>} size="small" type="link">Report*/}
            {/*                and share</Button>*/}
            {/*        </Col>*/}
            {/*    </Row>*/}
            {/*</Card>*/}

            <Modal
                title={subject.address}
                visible={showSubjectDetail}
                onCancel={hideDetailModalHandle}
                footer={false}
                width={'80vw'}
            >
                <SubjectDetails subject={subject}/>
            </Modal>

            <Modal
                title={'Find comparable'}
                visible={showFindComparableModal}
                onCancel={hideFindComparableModal}
                footer={false}
                width={'80vw'}
            >
                <FindComparablesForm params={props?.params} defaultType="custom"/>
            </Modal>
        </>
    )
}

const mapStateToProps = (state) => {
    const fastCMA = state.FastCMAReducer;

    return {
        subject: fastCMA.cma?.result.subject,
        market: fastCMA.cma?.market
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showReport: (params) => {
            dispatch(show_cma_report());
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SubjectDetailOverview);
