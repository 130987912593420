export default class User {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.id = data?.id || undefined;
        this.avatar = data?.avatar || undefined;
        this.username = data?.username || undefined;
        this.email = data?.email || undefined;
        this.password = data?.password || undefined;
        this.first_name = data?.first_name || undefined;
        this.last_name = data?.last_name || undefined;
        this.status = data?.status || 1;
        this.menu = data?.menu || [];
        this.is_edit_data = data?.is_edit_data || false;
    }

    profile(data) {
        this.id = data.id || undefined;
        this.avatar = data.avatar || undefined;
        this.username = data.username || undefined;
        this.email = data.email || undefined;
        this.status = data.status || 1;
        this.menu = data.menu || [];
        this.is_edit_data = data.is_edit_data || false;
        return this
    }

    setPermission(data = {}) {
        this.permissions = data.permissions || [];
    }

    setProperties(data = {}) {
        this.propertyIds = data.propertyIds || [];
    }

    actionRegister() {
        return {
            email: this.email,
            password: this.password,
            first_name: this.first_name,
            last_name: this.last_name,
        }
    }

    export() {
        return {
            id: this.id,
            password: this.password,
            email: this.email,
            firstname: this.firstname,
            lastname: this.lastname,
            username: this.username,
            phoneNumber: this.phoneNumber,
            avatar: this.avatar,
        }
    }
}
