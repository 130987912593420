const TAG_DEFINE = {
    GENERAL: {
        ITEM: {
            COMPARABLES_REQUEST: {
                code: "Code",
                profile: "Profile",
                market: "Market",
                property_address: "Address",
                status_explain: "Status",
                created_at: "Create Date",
                action: "Action",
            }
        }
    },
    ACTION: {
        info: "Thông tin",
        export: "Xuất file",
        import: "Nhập file",
        create: "Tạo mới",
        update: "Cập nhật",
        filter: "Bộ lọc",
        show: "Xem",
        remove: "Xoá",
        action: "Thao tác",
        exit: "Thoát",
        close: 'Đóng',
        lock: "Khoá",
        unlock: "Mở khoá",
        refreshData: "Làm mới dữ liệu",
        back: "Quay về",
        backIndex: "Quay về trang chủ",
        statusHide: "Không hiển thị",
        statusShow: "Hiển thị",
        uploadImage: "Tải Hình ảnh",
        collapse: "Thu gọn",
        detail: "Chi tiết",
        noData: "Không có dữ liệu",
        cancel: "Hủy",
        confirm: "Xác nhận",
        edit: 'Chỉnh sửa',
        add: 'Thêm',
        addMulti: 'Thêm nhiều',
        save: 'Lưu',
        send: 'Gửi',
        use: 'Sử dụng',
        resendEmail: 'Gửi lại email',
        approve: 'Cho phép',
        unApprove: 'Không cho phép',
        translate: 'Phiên dịch',
        unlink: 'Xóa liên kết',
        unActive: "Hủy kích hoạt",
        search: "Tìm kiếm"
    },
    NOTIFICATION: {
        title: "Thông báo",
        building: "Tính năng đang được phát triển",
        notInternet: "Kết nối bị lỗi, máy chủ không phản hồi!",
        createSuccess: "Thêm dữ liệu thành công",
        updateSuccess: "Cập nhật dữ liệu thành công",
        removeSuccess: "Xoá dữ liệu thành công",
        checkSuccess: "Kiểm tra thành công",
        uploadSuccess: "Tải dữ liệu thành công",
        resendMailSuccess: "Gởi email thành công",
        changePasswordSuccess: "Đổi mật khẩu thành công",
        assigneeSuccess: "Yêu cầu đã được chuyển",
        cancelTicketSuccess: "Đã huỷ yêu cầu",
        sureRemove: "Bạn chắc chắn muốn xóa ?",
        sureDeActive: "Bạn muốn vô hiệu tài khoản ?",
        sureUse: "Bạn chắc chắn muốn sử dụng ?",
        sureLogout: "Bạn chắc chắn muốn thoát?",
        changePassword: "Bạn chắc chắn muốn đổi mật khẩu?",
        confirmExit: 'Bạn có chắc chắn muốn thoát?',
        confirmBack: 'Bạn có chắc chắn muốn quay về?',
        downloadSuccess: 'Tải về file thành công.',
        youCantAccessThisPage: 'Bạn không thể vào trang này!',
        maxFileSize: 'Kích thước file tối đa',
    },
    PAGINATE: {
        showLine: "Items per page",
        first: "First page",
        last: "Last page",
        previous: "Previous",
        next: "Next",
        of: 'of',
        items: 'items',
        jumpTo: 'Go to',
        itemsPerPage: 'Items / page',
        page: 'page',
    },
    VALIDATION: {
        dropImage: "Kéo và thả hình ảnh ở đây",
        email: "Vui lòng nhập đúng email",
        password:
            "Mật khẩu gồm ký tự thường, ký tự viết hoa, ký tự số, ký tự đặt biệt và trên 6 ký tự",
        image: "File phải là hình",
        required: "Dữ liệu không được rỗng",
        requiredImage: "Vui lòng chọn hình",
        string: "Dữ liệu phải là ký tự",
        number: "Dữ liệu phải là số",
        priceRequired: "Vui lòng nhập giá",
        invalidDate: "Ngày tháng không hợp lệ",
        salePriceLessThenPrice: "Giá giảm phải thấp hơn giá gốc",
        sureUse: "Bạn chắc chắn muốn sử dụng ?",
        image1pic: "Vui lòng chọn 1 tấm hình",
        maxImage: "Tối đa 5 tấm hình",
        passwordRequired: "Mật khẩu không được rỗng",
        currentPasswordRequired: "Mật khẩu cũ không được rỗng",
        newPasswordRequired: "Mật khẩu mới không được rỗng",
        confirmPasswordRequired: "Mật khẩu xác nhận không được rỗng",
        usernameRequired: "Tài khoản không được rỗng",
        usernameOrPasswordNotMatch: "Tài khoản hoặc mật khẩu không đúng",
        userLocked: "Sai thông tin đăng nhập hoặc tài khoản bị khoá",
        connectFail: "Kết nối bị lỗi, máy chủ không phản hồi!",
        voucherInvalid: "Mã số voucher không hợp lệ",
        userNameExists: "Tài khoản đã tồn tại!",
        uploadFail: "Tải tập tin không thành công",
        percent: "Không được quá 100%",
        minOrderValue: "Dữ liệu phải nhỏ hơn đơn hàng tối đa",
        maxPhone: "Không được quá 12 ký tự",
        minPhone: "Dữ liệu phải từ 8 ký tự trở lên",
        minpassword: "Dữ liệu phải từ 6 ký tự trở lên",
        quantity: "Số lượng ít nhất là 1",
        LessThenZero: "Dữ liệu phải lớn hơn 0",
        MinValue: "Dữ liệu phải lớn hơn",
        isPhone: "Vui lòng nhập đúng số điện thoại",
        isSpecialString: "Vui lòng không nhập ký tự đặc biệt",
        isUrl: "Vui lòng nhập đúng đường dẫn",
        recaptcha: "Vui lòng xác nhận trước khi đăng nhập",
        comparePassword: "Mật khẩu xác nhận không trùng khớp",
        nameRequired: "Tên không được rỗng",
        codeRequired: "Code không được rỗng",
        starRequired: "Hạng không được để trống",
        checkForm: "Vui lòng kiểm tra lại dữ liệu",
        unknowError: 'Lỗi không xác định',
        nullData: "No data",
        hotelsUnActiveConfirm: "Huỷ kích họat khách sạn sẽ ần thông tin của khách sạn này trên các sản phẩm đang có. Bạn có chắn muốn huỷ kích hoạt?",
        statusCode: {
            code: {
                200: "Upload successful",
                201: "Create successful",
                204: "Update data successful",
                203: "Delete data successful",
                205: "Successfully added to favorites",
                206: "Successfully remove to favorites",
                207: "Password reset successful",
                400: "Không cập không hiệu lực",
                401: "Please login to the page!",
                403: "You do not have permission to access this site!.",
                404: "Page Not Found",
                405: 'Không hỗ trợ',
                1: "%s is reuqired",
                2: "%s is number",
                3: "%s quá giới hạn",
                4: "Không được quá %s ký tự",
                5: "Không được quá %s file",
                6: "Chưa có dữ liệu",
                7: "Bạn chắn chắn muốn xoá %s",
                8: 'Giá trị không được lớn hơn "%s"',
                9: "Hình ảnh tối đa là %s tấm hình",
                10: "Vui lòng nhập %s",
                11: "Vui lòng chọn %s",
                12: "Vui lòng chọn ít nhất %s tấm hình",
                13: "Vui lòng chọn ít nhất %s",
                14: "Bạn chắn chắn muốn cập nhật %s",
                15: 'Giá trị không được nhỏ hơn "%s"',
                1208: "Tài khoản hoặc mật khẩu không hợp lệ",
                1403: "Tài khoản hoặc mật khẩu không hợp lệ",
                1406: "Số điện thoại không hợp lệ",
                1409: "Vui lòng chọn trạm",
                1404: "Tài khoản hoặc email đã tồn tại!",
                1414: "Thông tin đăng nhập không đúng !",
                1210: "Thông tin đăng nhập không đúng !",
                1417: "Bạn không thể cập nhật nhân viên này!",
                1418: "Mã nhân viên không được trùng",
                1555: "Vui lòng nhập lý do hủy phiếu!",
                1563: "Nhân viên được giao không có quyền thực hiện phiếu yêu cầu",
                8001: "Tài khoàn hoặc mật khẩu của bạn không đúng. Vui lòng thử lại",
                8002: "IP đã bị chặn truy cập vào đường dẫn trên. Vui lòng quay lại sau",
                8003: "Tài khoản đã bị tạm khóa trong vòng 15'. Vui lòng quay lại sau",
                8004: "Bạn không có quyền để hủy ticket này",

                // Promotion: 10000
                10001: "Không thể tìm được chương trình",
                GROUP_NOT_FOUND: 'Không thể tìm được chương trình',
                10002: "Cơ sở không tồn tại",
                GROUP_PROPERTY_IDS_NOT_EXIST: 'Cơ sở không tồn tại',
                10003: "Sản phẩm không tồn tại",
                GROUP_PRODUCT_IDS_NOT_EXIST: 'Sản phẩm không tồn tại',
                10004: "Chương trình đang được bán",
                GROUP_IS_RUNNING: 'Chương trình đang được bán',
                10005: "Chương trình đã kết thúc",
                GROUP_WAS_ENDED: 'Chương trình đã kết thúc',
                10006: "Ngày giờ mở bán không được nhỏ hơn ngày giờ hiện tại",
                START_DATE_LESS_THAN_NOW: 'Ngày bắt đầu không được nhỏ hơn ngày giờ hiện tại',
                10007: "Ngày bắt đầu/Ngày mở bán không được lớn hơn ngày kết thúc/Ngày ngừng bán",
                START_DATE_LARGER_THAN_END_DATE: 'Ngày bắt đầu không được lớn hơn ngày kết thúc',
                10008: "Ngày ngừng bán không được lớn hơn ngày kết thúc",
                SALE_START_DATE_LARGER_THAN_SALE_END_DATE: 'Ngày ngừng bán không được lớn hơn ngày kết thúc',
                10101: "Ngày bắt đầu không được nhỏ hơn ngày hiện tại",
                SALE_END_DATE_LARGER_THAN_END_DATE: 'Ngày bắt đầu không được nhỏ hơn ngày hiện tại',
                DATE_LESS_THAN_NOW: 'Ngày giờ không được nhỏ hơn ngay giờ hiện tại',
                MIN_PRICE_LARGER_THAN_MAX_PRICE: 'Giá thấp nhất không được lớn hơn giá cao nhất',
                // Voucher: 11000
                11001: "Mã voucher không hợp lệ",
                SERIAL_NUMBER_INVALID: 'Mã voucher không hợp lệ',
                11002: "Không tìm thấy voucher",
                VOUCHER_NOT_FOUND: 'Không tìm thấy voucher',
                11003: "Voucher đã được sử dụng",
                VOUCHER_USED: 'Voucher đã được sử dụng',
                11004: "Voucher đã bị khoá",
                VOUCHER_LOCKED: 'Voucher đã bị khoá',
                11005: "Voucher đã hết số lần sử dụng",
                VOUCHER_USED_UP: 'Voucher đã hết số lần sử dụng',
                11006: "Chương trình tạm dừng",
                GROUP_UNAVAILABLE: 'Chương trình tạm dừng',
                11007: "Voucher đã hết hạn",
                VOUCHER_WAS_EXPIRED: 'Voucher đã hết hạn',
                11008: "Chương trình chưa bắt đầu",
                GROUP_NOT_STARTED_YET: 'Chương trình chưa bắt đầu',
                11009: "Voucher chưa đến ngày sử dụng",
                VOUCHER_NOT_STARTED_YET: 'Voucher chưa đến ngày sử dụng',
                11010: "Voucher không thể sử dụng tại cơ sở này",
                VOUCHER_PROPERTY_ERROR: 'Voucher không thể sử dụng tại cơ sở này',
                // User: 12000
                12001: "Không tìm thấy tài khoản hoặc tài khoản đã bị khoá",
                12002: "Tài khoản đã tồn tại",
                ID_USER_EXISTED: 'Tài khoản đã tồn tại',
                12003: "Cơ sở không tồn tại hoặc đã bị xoá",
                12004: "Quyền hạn chưa được cập nhật hoặc không tồn tại",
                12005: "Tài khoản không thể xoá",
                12006: "Tài khoản sẽ bị khoá nếu đăng nhập sai nhiều lần",
                ID_USER_LOCK_WARNING: "Tài khoản sẽ bị khoá nếu đăng nhập sai nhiều lần",
                12007: "Email chưa được xác nhận",
                12008: "Tài khoản đã bị khoá",
                ID_USER_LOCKED: 'Tài khoản đã bị khoá',
                12010: "Mã xác nhận sai",
                ID_WRONG_OLD_PASSWORD: "Mật khẩu cũ không chính xác",
                12011: "Mật khẩu cũ không chính xác",
                12012: "Email đã tồn tại",
                // Cart: 13000
                13001: "Chương trình không tồn tại",
                13002: "Voucher không đủ",
                13003: "Không tìm thấy giỏ hàng",
                13004: "Cart item not found",
                //ImportData: 14000
                14001: "Nhập dữ liệu thất bại",
                FAILED_TO_IMPORT_DATA: 'Nhập dữ liệu thất bại',
                FILE_NOT_FOUND: 'Không tìm thấy file',
                NOT_EXCEL_FORMAT: 'Không đúng định dạng excel',
                // UploadFile: 15000 (Storage)
                15001: "Tải dữ liệu thất bại",
                15002: "Không tìm thấy dữ liệu",
                15003: "Dữ liệu không được chấp nhận, Vui lòng chọn lại",
                15004: "Dung lượng dữ liệu bị giới hạn",
                // Banner: 16000 (Storage)
                16001: "Không tìm thấy hình ảnh",
                // Order: 17000 (Booking)
                17001: "Không tìm thấy đơn hàng",
                17002: "Đặt hàng thất bại",
                17003: "Đơn hàng đã được thanh toán",
                17004: "Không đủ voucher",
                // Property: 18000
                18001: "Không tìm thấy cơ sở",
                PROPERTY_NOT_FOUND: 'Không tìm thấy cơ sở',
                PROPERTY_USED: 'Cơ sở đang được sử dụng',
                // Product: 19000
                19001: "Không tìm thấy sản phẩm",
                PRODUCT_NOT_FOUND: 'Không tìm thấy sản phẩm',
                PRODUCT_USED: 'Sản phẩm đang được sử dụng',
                // Customer: 20000
                20001: "Không tìm thấy khách hàng",

                21001: "Chỉ được xem trong vòng 90 ngày",
                21002: "Ngày bắt đầu không được lớn hơn ngày kết thúc hoặc ngày hiện tại",
                //Role
                22001: "Vai trò không tồn tại",
                22002: "Vai trò đã tồn tại",
                22003: "Vai trò đã được sử dụng",
                UNDEFINED_ERROR: 'Lỗi không xác định',
                INVALID_CAPTCHA: 'Sai mã xác thực',
                PAGE_NOT_FOUND: "Không tìm thấy trang",
                CATEGORY_NOT_FOUND: "Không tìm thấy danh mục",
                CATEGORY_EXISTED: "Danh mục đã tồn tại",
                CATEGORY_USED: "Danh mục đã sử dụng",
                CATEGORY_IDS_NOT_EXIST: "Một số danh mục đã tồn tại",
                TAG_NOT_FOUND: "Không tìm thấy thẻ",
                TAG_EXISTED: "Thẻ tồn tại",
                TAG_USED: "Thẻ đã dùng",
                BANNER_NOT_FOUND: "Không tìm thấy banner",
                FILE_UPLOAD_FAILED: "Tải lên bị lỗi",
                FILE_TYPE_NOT_ACCEPTED: "Loại file không hỗ trợ",
                FILE_LENGTH_OVER_LIMIT: "File quá giới hạn kích thước",
                ZERO_FILE_LENGTH: "File rỗng",
                DIRECTORY_NOT_ALLOW: 'Thư mục không được phép thao tác',
            }
        }
    },
    ACTION_OPTIONS: {
        CREATE: 'CREATE',
        CLONE: 'CLONE',
        UPDATE: 'UPDATE',
        TRANSLATE: 'TRANSLATE',
    }
};
export default TAG_DEFINE;
