import Report from "../../FastCMA/Report/Report";
import HeaderAction from "../../../common/layout/HeaderAction";
import {ActionGetItem, ActionInit as ActionInitComparables} from "../../../../data/hooks/comparablesRequest";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {filter} from "lodash";
import {selectItem} from "../../../../data/reselects/comparablesRequestSelector";
import {ActionAppendResponse, ActionInit} from "../../../../data/hooks/fastCMA";
import {selectFetching} from "../../../../data/reselects/comparablesRequestSelector";
import {Card, Form, Input, PageHeader, Spin, Radio, Button, Space, InputNumber, Select} from "antd";
import {useScript} from "../../../../utils/commonUtils";
import {ReportAndShare} from "../../FastCMA/Report";

const Index = props => {
    const [form] = Form.useForm(),
        actionGetItem = ActionGetItem(),
        actionAppendResponse = ActionAppendResponse(),
        actionInit = ActionInit(),
        actionInitComparables = ActionInitComparables(),
        itemDetail = useSelector(selectItem()),
        itemFetching = useSelector(selectFetching())
    const [onShowReportnShare, setOnShowReportnShare] = useState(false),
        [resultTable, setResultTable] = useState([]),
        [valueSquareFeet, setValueSquareFeet] = useState(),
        [originTableData, setOriginTableData] = useState([]),
        [getTabActive, setGetTabActive] = useState("sold");

    const squareFeetOptions = [
        {
            value: Math.floor(Number(originTableData[0]?.square_feet || 0) - (Number(originTableData[0]?.square_feet || 0) * 0.2)),
            label: Math.floor(Number(originTableData[0]?.square_feet || 0) - (Number(originTableData[0]?.square_feet || 0) * 0.2)).toLocaleString(),
        },
        {
            value: Math.floor(Number(originTableData[0]?.square_feet || 0) - (Number(originTableData[0]?.square_feet || 0) * 0.1)),
            label: Math.floor(Number(originTableData[0]?.square_feet || 0) - (Number(originTableData[0]?.square_feet || 0) * 0.1)).toLocaleString(),
        },
        {
            value: Math.floor(Number(originTableData[0]?.square_feet || 0)),
            label: Math.floor(Number(originTableData[0]?.square_feet || 0)).toLocaleString()
        },
        {
            value: Math.floor(Number(originTableData[0]?.square_feet || 0) + (Number(originTableData[0]?.square_feet || 0) * 0.1)),
            label: Math.floor(Number(originTableData[0]?.square_feet || 0) + (Number(originTableData[0]?.square_feet || 0) * 0.1)).toLocaleString(),
        },
        {
            value: Math.floor(Number(originTableData[0]?.square_feet || 0) + (Number(originTableData[0]?.square_feet || 0) * 0.2)),
            label: Math.floor(Number(originTableData[0]?.square_feet || 0) + (Number(originTableData[0]?.square_feet || 0) * 0.2)).toLocaleString(),
        },
    ];

    useEffect(() => {
        actionInit()
        actionInitComparables()
        return () => {
            actionInit();
            actionInitComparables();
        };
    }, []);

    useEffect(() => {
        const {match} = props;
        if (Object.keys(match.params).length > 0) {
            if (Object.keys(itemDetail || {}).length < 1) {
                actionGetItem({id: match.params?.id, props: props});
            }
        }
    }, [props.match]);

    useEffect(() => {
        if (Object.keys(itemDetail || {}).length > 0) {
            actionAppendResponse(itemDetail?.response)
        }
    }, [itemDetail]);

    const onGGLoad = () => {

        window.dispatchEvent(new Event('google-map-api-loaded'))
    }

    // Load script of google map library
    useScript('https://maps.googleapis.com/maps/api/js?key=' + process.env.REACT_APP_GOOGLE_API_KEY + '&libraries=places', onGGLoad);

    const onFilterFunction = params => {
        const newResult = structuredClone(originTableData);

        return filter(newResult, property => {
            const passesBedFilter = params?.bed ? Number(property?.bedroom || 0) === Number(params?.bed || 0) : true;
            const passesBathFilter = params?.bath ? Number(property?.bathroom_total) === Number(params?.bath || 0) : true;
            const passesSquareFootMin = params?.square_feet?.min ? Number(property?.square_feet) >= Number(params?.square_feet?.min || 0) : true;
            const passesSquareFootMax = params?.square_feet?.max ? Number(property?.square_feet) <= Number(params?.square_feet?.max || 0) : true;
            const passesYearBuiltMin = params?.year_built?.min ? Number(property?.year_built) >= Number(params?.year_built?.min || 0) : true;
            const passesYearBuiltMax = params?.year_built?.max ? Number(property?.year_built) <= Number(params?.year_built?.max || 0) : true;
            const passesDistanceMin = params?.distance?.min ? Number(Math.round((property['dist'] || 0) * 0.00062137 * 100) / 100) >= Number(params?.distance?.min || 0) : true;
            const passesDistanceMax = params?.distance?.max ? Number(Math.round((property['dist'] || 0) * 0.00062137 * 100) / 100) <= Number(params?.distance?.max || 0) : true;
            return passesBedFilter && passesBathFilter && passesSquareFootMin && passesSquareFootMax && passesYearBuiltMin && passesYearBuiltMax && passesDistanceMin && passesDistanceMax;
        })
    }

    const onSubmit = val => {
        let resultFilters;
        resultFilters = onFilterFunction(val)
        setResultTable(resultFilters)
    }

    return <>
        <HeaderAction
            title="Comparables Request Detail"
        />
        <Spin spinning={itemFetching}>
            {
                onShowReportnShare ? (
                    <div className="cursorPointer w-25" onClick={() => setOnShowReportnShare(false)}>
                        <PageHeader
                            className="site-page-header"
                            onBack={() => setOnShowReportnShare(false)}
                            title="Back to detail"
                            subTitle=""
                        />
                    </div>
                ) : null
            }
            <div className="px-4 mt-5">
                <div className="box--shadow border__radius--10px p-3 report-and-share">
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={onSubmit}
                        onValuesChange={val => {
                            setValueSquareFeet(val?.square_feet)
                        }}
                        // requiredMark={requiredMark}
                    >
                        <div className="row align-items-end">
                            <div className="col-3">
                                <Form.Item label="Bed" name="bed">
                                    <Radio.Group
                                        optionType="button"
                                        buttonStyle="solid"
                                    >
                                        <Radio.Button value="">Any</Radio.Button>
                                        <Radio.Button value={1}>1</Radio.Button>
                                        <Radio.Button value={2}>2</Radio.Button>
                                        <Radio.Button value={3}>3</Radio.Button>
                                        <Radio.Button value={4}>4</Radio.Button>
                                        <Radio.Button value={5}>5</Radio.Button>
                                    </Radio.Group>
                                </Form.Item>
                            </div>
                            <div className="col-3">
                                <Form.Item label="Bath" name="bath">
                                    <Radio.Group
                                        optionType="button"
                                        buttonStyle="solid"
                                    >
                                        <Radio.Button value="">Any</Radio.Button>
                                        <Radio.Button value={1}>1</Radio.Button>
                                        <Radio.Button value={2}>2</Radio.Button>
                                        <Radio.Button value={3}>3</Radio.Button>
                                        <Radio.Button value={4}>4</Radio.Button>
                                        <Radio.Button value={5}>5</Radio.Button>
                                    </Radio.Group>
                                </Form.Item>
                            </div>
                            <div className="col-3">
                                <Form.Item label="Square Feet" name="square_feet">
                                    <Input.Group compact>
                                        <Form.Item
                                            noStyle
                                            name={["square_feet", "min"]}
                                        >
                                            <Select
                                                defaultValue=""
                                                style={{
                                                    width: 140,
                                                }}
                                                // placeholder="Minimum"
                                                options={[{
                                                    value: "",
                                                    label: "No Min",
                                                }, ...squareFeetOptions]}
                                            />
                                        </Form.Item>
                                        <Input
                                            className="site-input-split"
                                            style={{
                                                width: 30,
                                                borderLeft: 0,
                                                borderRight: 0,
                                                pointerEvents: 'none',
                                            }}
                                            placeholder="~"
                                            disabled
                                        />
                                        <Form.Item
                                            noStyle
                                            name={["square_feet", "max"]}
                                        >
                                            <Select
                                                defaultValue=""
                                                style={{
                                                    width: 140,
                                                }}
                                                options={[{
                                                    value: "",
                                                    label: "No Max",
                                                }, ...squareFeetOptions.slice(squareFeetOptions.findIndex(value => value?.value === valueSquareFeet?.min) + 1).filter(value => value?.value !== undefined)]}
                                            />
                                        </Form.Item>
                                    </Input.Group>
                                </Form.Item>
                            </div>
                            <div className="col-3">
                                <Form.Item label="Year Built" name="year_built">
                                    <Input.Group compact>
                                        <Form.Item
                                            noStyle
                                            name={["year_built", "min"]}
                                        >
                                            <InputNumber
                                                style={{
                                                    width: 140,
                                                    textAlign: 'center',
                                                }}
                                                placeholder="Minimum"
                                            />
                                        </Form.Item>
                                        <Input
                                            className="site-input-split"
                                            style={{
                                                width: 30,
                                                borderLeft: 0,
                                                borderRight: 0,
                                                pointerEvents: 'none',
                                            }}
                                            placeholder="~"
                                            disabled
                                        />
                                        <Form.Item
                                            noStyle
                                            name={["year_built", "max"]}
                                        >
                                            <InputNumber
                                                className="site-input-right"
                                                style={{
                                                    width: 140,
                                                    textAlign: 'center',
                                                }}
                                                placeholder="Maximum"
                                            />
                                        </Form.Item>
                                    </Input.Group>
                                </Form.Item>
                            </div>
                            <div className="col-3">
                                <Form.Item label="Distance" name="distance">
                                    <Input.Group compact>
                                        <Form.Item noStyle name={["distance", "min"]}>
                                            <InputNumber
                                                style={{
                                                    width: 140,
                                                    textAlign: 'center',
                                                }}
                                                placeholder="Minimum"
                                            />
                                        </Form.Item>
                                        <Input
                                            className="site-input-split"
                                            style={{
                                                width: 30,
                                                borderLeft: 0,
                                                borderRight: 0,
                                                pointerEvents: 'none',
                                            }}
                                            placeholder="~"
                                            disabled
                                        />
                                        <Form.Item noStyle name={["distance", "max"]}>
                                            <InputNumber
                                                className="site-input-right"
                                                style={{
                                                    width: 140,
                                                    textAlign: 'center',
                                                }}
                                                placeholder="Maximum"
                                            />
                                        </Form.Item>
                                    </Input.Group>
                                </Form.Item>
                            </div>
                            <div className="col-3">
                                <Space>
                                    <Form.Item className="" label="">
                                        <Button type="primary" htmlType="submit">
                                            Apply
                                        </Button>
                                    </Form.Item>
                                    <Form.Item className="" label="">
                                        <Button danger htmlType="button" className="color--white"
                                                onClick={e => {
                                                    setResultTable(originTableData)
                                                    form.resetFields();
                                                }}>
                                            Clear
                                        </Button>
                                    </Form.Item>
                                </Space>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
            {
                onShowReportnShare ? (
                    <ReportAndShare
                        getTabActive={getTabActive}
                        response={itemDetail?.response}
                        subject={itemDetail?.response?.subject}
                        params={itemDetail?.params}
                        properties={itemDetail?.response?.payload}
                    />
                ) : (
                    <Report
                        sendResultTable={e => {
                            setResultTable(e)
                            setOriginTableData(e)
                        }}
                        resultTable={resultTable}
                        getTabActive={e => setGetTabActive(e)}
                        tabActive={getTabActive}
                        onShowReportnShare={e => setOnShowReportnShare(e)}
                        response={itemDetail?.response}
                        subject={itemDetail?.response?.subject}
                        params={itemDetail?.params}
                        properties={itemDetail?.response?.payload}/>
                )
            }

            {/*{*/}
            {/*    (Object?.keys(itemDetail).length > 0) ? (*/}
            {/*        <Card style={{*/}
            {/*            marginTop: '16px',*/}
            {/*            backgroundColor: '#f0f0f0',*/}
            {/*        }}>*/}
            {/*            <Report*/}
            {/*                response={itemDetail?.response}*/}
            {/*                subject={itemDetail?.response?.subject}*/}
            {/*                params={itemDetail?.params}*/}
            {/*                properties={itemDetail?.response?.payload}/>*/}
            {/*        </Card>*/}
            {/*    ) : null*/}
            {/*}*/}
        </Spin>
    </>
}

export default Index
