import React from "react"
import { Card, Image as AntdImage, Typography } from "antd"
import { connect } from "react-redux";
import { MARKET } from "../../../../../constants/fastCMA";
import Image from "../../Image"

const { Title } = Typography

const gridStyles = {
  border: 'none',
  boxShadow: 'none',
  padding: '1.125em',
  display: 'flex',
  flexWrap: 'wrap',
  fontSize: '14px',
  flexDirection: 'column'
}

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const Grid = (props) => {
  const { style, isRow } = props
  let combineStyle = {
    ...gridStyles,
    ...style
  }
  if ( isRow ) {
    combineStyle.flexDirection = 'row';
  }

  return (
    <Card.Grid style={combineStyle}>
      {props.children}
    </Card.Grid>
  )
}

const DetailListing = (props) => {
  const { data } = props
  return (
    <ul style={{ listStyle: 'none', padding: 0 }}>
      {data.map((item, idx) => 
        <li key={`detail-listing-${idx}`}>
          {item.label && <strong>{item.label}: </strong>}
          {item.value}
        </li> 
      )}
    </ul>  
  )
}

const SubjectDetails = connect(state => {
  const data = state.fastCMAReducer
  return {
    market: data?.cma.market
  }
}, null)((props) => {
  const { subject, market } = props
  
  return (
    <>
      <Card title="Valuations" bordered={false} bodyStyle={{ padding: 0 }}>
        <Grid isRow={true}>
          <Grid style={{
            flexBasis: "30%",
            padding: '0 0.25em'
          }}>
            <Image
              id ={subject.uid}
            />
          </Grid>
          <Grid style={{
            padding: '0 0.25em',
            flexFlow: 'row-reverse',
            flexGrow: 1
          }}>
            <DetailListing data={[
              {
                label: 'Discount Index™',
                value: '0%'
              },
              {
                label: 'Gross discount',
                value: '$0'
              },
              {
                label: 'Rental Index™',
                value: '0'
              },
              {
                label: 'Est. monthly lease',
                value: '$0'
              }
            ]} />
          </Grid>
        </Grid>
        <Grid >
          <DetailListing data={[
            {
              label: 'List price',
              value: formatter.format(subject.list_price || 0)
            },
            {
              label: 'Est. price',
              value: '$0'
            }, 
            {
              label: 'List price per square foot',
              value: formatter.format((subject.list_price || 0)/(subject.square_feet || 1))
            },
            {
              label: 'Est. price per square foot',
              value: '$0'
            }
          ]} />
        </Grid>
        <Grid >
          <DetailListing data={[
            {
              label: 'Original list price',
              value: formatter.format(subject.list_price_orig || 0)
            },
            {
              label: 'List price to original list price',
              value: formatter.format((subject.list_price || 0) - (subject.list_price_orig || 0))
            }, 
            {
              label: 'List date',
              value: subject.list_date || ""
            }
          ]} />
        </Grid>
      </Card>

      <Card bordered={false} title="Property" bodyStyle={{ 
        paddingTop: '1.125em',
        paddingBottom: '1.125em' 
      }}>
        <Grid style={{ padding: 0 }}>
          <DetailListing data={[
            {
              value: `${subject.bedroom || 0} bed/${(subject.bathroom_full || 0) + (subject.bathroom_half || 0)} bath/${subject.garage || 0} garage/${subject.carport || 0} carport`
            },
            { label: 'Pool', value: subject.pool || "" },
            { label: 'Fireplace', value: subject.fireplace || "" },
            { label: 'Stories', value: subject.storie || "" },
          ]}
          />
        </Grid>
        <Grid style={{ padding: 0 }}>
          <DetailListing data={[
            { label: 'Property type', value: subject.prop_type || "" },
            { label: 'House type', value: subject.house_type || "" },
            { label: 'Year built', value: subject.year_built || "" },
            { label: 'Square feet', value: subject.square_feet || "" },
          ]}
          />
        </Grid>
        <Grid style={{ padding: 0 }}>
          <DetailListing data={[
            { label: 'Acre', value: (subject.acre || 0).toFixed(2) },
            { label: 'Construction', value: subject.construction_type || "" },
            { label: 'Roof', value: subject.roof || "" },
            { label: 'Foundation', value: subject.foundation || "" },
            { label: 'Seller type', value: subject.seller_type || "" },
          ]}
          />
        </Grid>
      </Card>

      <Card bordered={false} bodyStyle={{ 
        paddingTop: '1.125em',
        paddingBottom: '1.125em' 
      }}>
        <Grid style={{ padding: 0 }}>
          <Title level={5}>Subdivision and schools</Title>
          
          <DetailListing data={[
            {
              label: 'Subdivision',
              value: subject.subdivision_name || ""
            },
            {
              label: 'HOA Frequency/dues',
              value: `${subject.hoa_frequency || ""}/${subject.hoa || ""}`
            },
            {
              label: 'School District',
              value: ''
            },
            {
              label: 'Elementary school',
              value: ''
            },
            {
              label: 'Middle school',
              value: ''
            },
            {
              label: 'High school',
              value: ''
            },
          ]} />
        </Grid>
        <Grid style={{ padding: 0 }}>
          <Title level={5}>MLS</Title>
          
          <DetailListing data={[
            { label: 'MLS number', value: subject.mls_num || "" },
            { label: 'Status', value: subject.status || "" },
            { label: 'Status change', value: '' },
            { label: 'Days on market', value: subject.dom || "" },
            { label: 'Agent Name', value: subject.listing_agent_name || "" },
            { label: 'Agent Phone Number', value: subject.listing_agent_phone || "" },
          ]} />
        </Grid>
        <Grid style={{ padding: 0 }}>
          <Title level={5}>CMA</Title>
          <DetailListing data={[
            {
              label: 'CMA date',
              value: ''
            }
          ]} />
        </Grid>
      </Card>
      <Card title="Public remark" bordered={false} bodyStyle={{ 
        paddingTop: '1.125em',
        paddingBottom: '1.125em' 
      }}>
        {subject.public_remark || ""}
      </Card>
    </>
  )
})

export default SubjectDetails;