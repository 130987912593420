import React, {useEffect, useState} from "react"
import {Button, Form, Input, message, Modal, Spin, Statistic} from "antd";
import {ActionInit, ActionPasswordRequest, ActionPasswordReset} from "../../../data/hooks/auth";
import {useSelector} from "react-redux";
import {getPasswordRequest, getStatusAction} from "../../../data/reselects/authSelector";

const {Countdown} = Statistic;

const ForgotPassword = props => {
    const [isForgotPassword, setIsForgotPassword] = useState(false),
        [isPasswordRequest, setIsPasswordRequest] = useState(true),
        [isPasswordReset, setIsPasswordReset] = useState(false),
        actionInit = ActionInit(),
        itemStatusAction = useSelector(getStatusAction());

    useEffect(() => {
        return () => {
            // actionInit();
        }
    }, []);

    useEffect(() => {
        setIsPasswordRequest(!itemStatusAction.passwordRequestSuccess)
        setIsPasswordReset(itemStatusAction.passwordRequestSuccess)
    }, [itemStatusAction]);

    useEffect(() => {
        setIsForgotPassword(props.isForgotPassword)
    }, [props.isForgotPassword]);

    const actionModal = e => {
        setIsForgotPassword(e);
        actionInit();
        setIsPasswordRequest(true);
        setIsPasswordReset(false);
        props.actionModal(e)
    }

    return (
        <Modal
            maskClosable={false}
            destroyOnClose
            title="Password Reset"
            visible={isForgotPassword}
            footer={null}
            onCancel={() => actionModal(false)}
        >
            {
                isPasswordRequest && <PasswordRequest/>
            }
            {
                isPasswordReset && <PasswordReset actionModal={actionModal}/>
            }
        </Modal>
    )
}
export default ForgotPassword

const PasswordRequest = props => {

    const [onSubmit, setOnSubmit] = useState(false),
        actionPasswordRequest = ActionPasswordRequest(),
        itemStatusAction = useSelector(getStatusAction());

    useEffect(() => {
        setOnSubmit(itemStatusAction.isPasswordRequest)
    }, [itemStatusAction]);


    const onFinish = val => {
        setOnSubmit(true);
        actionPasswordRequest(val)
    }

    return (
        <Spin spinning={onSubmit}>
            <Form layout="vertical" name="nest-messages" onFinish={onFinish}>
                <Form.Item name="email" label="Email"
                           rules={[{required: true, type: 'email', message: '${label} is not a valid'}]}>
                    <Input placeholder="Please enter your password" autoFocus/>
                </Form.Item>
                <Form.Item className="button-color--transparent text-end" label="">
                    <Button type="primary" htmlType="submit">
                        Send
                    </Button>
                </Form.Item>
            </Form>
        </Spin>
    )
}

const PasswordReset = props => {

    const [onSubmit, setOnSubmit] = useState(false),
        actionPasswordReset = ActionPasswordReset(),
        itemPasswordRequest = useSelector(getPasswordRequest()),
        itemStatusAction = useSelector(getStatusAction());

    useEffect(() => {
        // setOnSubmit(itemStatusAction.passwordResetSuccess)
        if (itemStatusAction.passwordResetSuccess) {
            props.actionModal(false);
        }
    }, [itemStatusAction]);

    const onFinish = val => {
        setOnSubmit(true);
        actionPasswordReset({
            ...val,
            hash: itemPasswordRequest
        })
    }

    return (
        <Spin spinning={onSubmit}>
            <Countdown title="" className="text-center" format="mm:ss" value={Date.now() + 1000 * 60 * 10 * 2 + 1000} onFinish={() => {
                Modal.warning({
                    title: 'Notification',
                    content: 'Reset Password Request Is Expired. Please Try Again',
                    onOk: () => props.actionModal(false)
                });
            }}/>
            <p className="color--primary fs--15px"><i>*One Time Passcode (OTP) has been sent to your email. Please check your INBOX, also make sure to check your SPAM folder.</i></p>
            <Form layout="vertical" name="nest-messages" onFinish={onFinish}>
                <Form.Item name="otp" label="OTP"
                           rules={[{required: true, message: '${label} is not a valid'}]}>
                    <Input placeholder="Please enter a OTP"/>
                </Form.Item>
                <Form.Item name="password"
                           label="New Password"
                           rules={[
                               {required: true, message: '${label} is not a valid'},
                           ]}>
                    <Input.Password placeholder="Please enter your new password"/>
                </Form.Item>
                <Form.Item
                    name="confirm_password"
                    label="Confirm Password"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                        {required: true, message: '${label} is not a valid'},
                        ({getFieldValue}) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('The two passwords that you entered do not match!'));
                            },
                        }),
                    ]}>
                    <Input.Password placeholder="Please enter your confirm password"/>
                </Form.Item>
                <Form.Item className="button-color--transparent text-end" label="">
                    <Button type="primary" htmlType="submit">
                        Send
                    </Button>
                </Form.Item>
            </Form>
        </Spin>
    )
}
