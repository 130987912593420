import React, { useEffect } from "react";


const GoogleMap = (props) => {
  const { centerCoordinate, coordinates } = props

  const loadMap = (center) => {
    let container = document.getElementById("report-google-map");

    if ( container && window.google ) {
      let bounds = new window.google.maps.LatLngBounds();
      let infoWindow = new window.google.maps.InfoWindow();
      if ( center ) {
        center = new window.google.maps.LatLng(center[1], center[0]);
      }

      const map = new window.google.maps.Map(container, {
        zoom: 10,
        center: center
      });

      coordinates && coordinates.length && coordinates.map((cord, index) => { // The marker, positioned at Uluru
        let marker = new window.google.maps.Marker({
          position: { lat: cord[1], lng: cord[0]},
          map: map,
        });

        bounds.extend(marker.position)
        window.google.maps.event.addListener(marker, 'click', (function(marker, i) {
          return function() {
            infoWindow.setContent(cord[0]);
            infoWindow.open(map, marker);
          }
        })(marker, index));
      });

      map.fitBounds(bounds);

      
    }
  }

  useEffect(() => {
    window.addEventListener('google-map-api-loaded', loadMap)
    return () => {
      window.removeEventListener('google-map-api-loaded', loadMap);
    }
  }, []);

  useEffect(() => {
    loadMap();
  }, [coordinates]);

  return (
    <div id="report-google-map"></div>
  )
}

export default GoogleMap;
