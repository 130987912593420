import React, {useEffect, useState} from 'react';
import {Tabs, Breadcrumb} from "antd";
import {find} from "lodash";
import {capitalizeFirstLetter} from "../../../utils/functions";
import {HomeOutlined} from '@ant-design/icons';

import {getProfile} from '../../../data/reselects/authSelector'
import {useSelector} from "react-redux";

const {TabPane} = Tabs;

const MainLayout = (props) => {
    const itemAuthProfile = useSelector(getProfile()),
        [title, setTitle] = useState(props?.title || ""),
        [itemMenu, setItemMenu] = useState([]);

    useEffect(() => {
        if (!props?.disabledTab) {
            if (props?.pathname) {
                let generateSubMenu = (itemAuthProfile?.menu || []).map(value =>
                        value.items.map(child => Object.assign({group: value.group}, child))
                    ).reduce((l, n) => l.concat(n), []),
                    group = find(generateSubMenu, {route: (props?.pathname).replace("/", "")})?.group;
                setTitle(find(itemAuthProfile?.menu, {group: group})?.name)
                setItemMenu(generateSubMenu.filter(i => i?.group === group))
            }
        } else {
            setTitle(props?.title)
        }
    }, [itemAuthProfile?.menu, props]);

    return (
        <div className={props.className} >
            {
                (props.disabledTab && (props?.breadcrumb || []).length > 0) && (
                    <div className="px-3 pt-4">
                        <Breadcrumb>
                            <Breadcrumb.Item>Home</Breadcrumb.Item>
                            {
                                props?.breadcrumb.map((i, k) => <Breadcrumb.Item key={k}>{i}</Breadcrumb.Item>)
                            }
                        </Breadcrumb>
                    </div>
                )
            }
            <h3 className="px-3 pt-4">{title}</h3>
            {
                (!props.disabledTab && itemMenu.length) > 0 && (
                    <Tabs
                        activeKey={(props?.pathname).replace("/", "")}
                        onTabClick={(e) => props.history.push(`/${e}`)}
                        className="hn__tabs-menu">
                        {
                            itemMenu.map((i, k) => {
                                return (
                                    <TabPane tab={capitalizeFirstLetter(i?.name)} key={i?.route}>
                                        {
                                            props.children
                                        }
                                    </TabPane>
                                )
                            })
                        }
                    </Tabs>
                )
            }
            {
                (props.disabledTab) && (
                    <div className="px-3 pb-3">
                        {props.children}
                    </div>
                )
            }
        </div>
    )
}

export default MainLayout
