import {Redirect, Route, withRouter} from "react-router-dom";
import ContainerIndex from "../../ContainerIndex";
import {connect, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {getFetchingAuth, getProfile} from "../../../data/reselects/authSelector";
import {Button, Result} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import TAG_DEFINE from "../../../constants/common";
import queryString from "query-string";
import Login from "../../Login";
import {GoogleReCaptcha, GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import {ActionLogin} from "../../../data/hooks/auth";

export const Authentication = props => {
    const [urlCallback, setUrlCallback] = useState(""),
        [googleCaptcha, setGoogleCaptcha] = useState(""),
        [agent, setAgent] = useState(""),
        actionLogin = ActionLogin(),
        itemFetching = useSelector(getFetchingAuth()),
        itemProfile = useSelector(getProfile());

    useEffect(() => {
        let {location} = props,
            queryParams = queryString.parse(location?.search)
        if (queryParams?.code && googleCaptcha) {
            actionLogin({
                token_captcha: googleCaptcha,
                code: queryParams?.code,
                fullAddress: queryParams?.fullAddress,
            })
        }
        // actionCheckUser();
    }, [googleCaptcha]);

    return (
        <GoogleReCaptchaProvider
            useEnterprise
            reCaptchaKey={process.env.REACT_APP_GOOGLE_CAPTCHA_CLIENT_SECRET}>

            {
                !googleCaptcha ? <GoogleReCaptcha onVerify={token => setGoogleCaptcha(token)}/> : null
            }

            <div className="hn__authentication container">
                <div className="hn__authentication--screen">
                    {
                        (queryString.parse(props?.location?.search) || {})?.code ? (
                            <div className="hn__authentication--screen__content">
                                <div className="text-center mt-5 color--white">
                                    <div className="my-3">
                                        <img src="/layout/logo-login.svg"
                                             className="" width="200px"/>
                                    </div>
                                    <p className="mb-0 h5 mt-3">Please wait a second.</p>
                                    <p className="mb-0">The system is processing.</p>
                                    <hr/>
                                </div>
                                <div className="px-3">
                                    <div className="text-center" style={{
                                        fontSize: "50px"
                                    }}>
                                        <LoadingOutlined
                                            className="color--white"
                                        />
                                    </div>
                                    {/*{*/}
                                    {/*    !itemFetching ? (*/}
                                    {/*        <div className="text-center" style={{*/}
                                    {/*            paddingTop: "110px",*/}
                                    {/*            fontSize: "50px"*/}
                                    {/*        }}>*/}
                                    {/*            <LoadingOutlined*/}
                                    {/*                className="color--white"*/}
                                    {/*            />*/}
                                    {/*        </div>*/}
                                    {/*    ) : null*/}
                                    {/*}*/}
                                </div>
                            </div>
                        ) : (
                            <Result
                                className="color--white mt-4"
                                status="error"
                                title="Submission Failed"
                                subTitle="Please check and modify the following information before resubmitting."
                            />
                        )
                    }
                </div>
            </div>
        </GoogleReCaptchaProvider>
    )
}

const VerifyAuthRoute = ({component: Component, ...rest}) => (
    <Route {...rest} render={props => {
        const {authentication} = rest;
        return <Authentication {...props} />
    }}/>
);

const mapStateToProps = state => ({
    authentication: state.authReducer,
});

export default withRouter(connect(mapStateToProps)(VerifyAuthRoute));
