export default {
    LOGIN: '/api/v1/login',
    REGISTER: '/api/v1/register',
    LOGOUT: '/',
    PROFILE: '/api/v1/profile',
    REFRESH: 'api/admin/user/refresh',
    UPDATE_PROFILE: '/api/v1/profile/update',
    PASSWORD_REQUEST: '/api/v1/password/request',
    PASSWORD_RESET: '/api/v1/password/reset',

    HOTEL_LIST: '/api/v1/admin/hotel',
    HOTEL_ALL: '/api/v1/admin/hotel',
    HOTEL_MASTER: '/api/v1/admin/hotel/master',
    HOTEL_CREATE: '/api/v1/admin/hotel',
    HOTEL_DELETE: '/api/v1/admin/hotel/:id',
    HOTEL_UPDATE: '/api/v1/admin/hotel',
    HOTEL_DETAIL: '/api/v1/admin/hotel/:id',

    TICKET_LIST: '/api/v1/ticket',
    TICKET_DETAIL: '/api/v1/ticket/:id',
    TICKET_CREATE: '/api/v1/ticket/submit',
    TICKET_COMMENT: '/api/v1/ticket/:id',

    SUBSCRIPTION_CURRENT: '/api/v1/subscription/current',
    SUBSCRIPTION_DETAIL_UPGRADE: '/api/v1/subscription/detail/:date',
    SUBSCRIPTION_LIST_PACKAGES: '/api/v1/subscription/package',
    SUBSCRIPTION_LIST_TRANSACTIONS: '/api/v1/subscription/transaction',
    SUBSCRIPTION_WORDING_CONFIRM_PACKAGE: '/api/v1/subscription/confirm',

    PACKAGE_PAYMENT_CREATE_ORDER: '/api/v1/subscription/order',

    // Fast CMA
    ADDRESS_LOOKUP: '/api/v1/fast-cma/address-lookup-new',
    FIND_COMPARABLES: '/api/v1/fast-cma/find-comparables',
    CMA_EXPORT_PDF: '/api/v1/fast-cma/export-report',
    CMA_GET_PROFILES: '/api/v1/fast-cma/profiles',
    CMA_UPDATE_PROFILES: '/api/v1/fast-cma/profiles',
    CMA_DELETE_PROFILES: '/api/v1/fast-cma/profiles',

    COMPARABLES_REQUEST_LIST: '/api/v1/comp-request',
    COMPARABLES_REQUEST_DETAIL: '/api/v1/comp-request/:id',
}
