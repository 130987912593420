import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';
import {loginAction} from "../../data/actions/authAction";
import {ActionUpdateProfile} from "../../data/hooks/auth"
import {ROUTE_LIST} from "../../constants/define";
import MainLayout from "../common/layout/MainLayout";

const Index = (props) => {
    useEffect(() => {
        // ==> First page loading <===
        // props.history.push(ROUTE_LIST.PreForeClosure)
    }, []);

    return (
        <MainLayout pathname={props.location.pathname} history={props.history}>
            asd
        </MainLayout>
    )
    // return <PreForeClosureComponent {...props} key={Date.now()} getUpdateProfile={getUpdateProfile}/>
}

const mapStateToProps = state => {
    return {
        // categories: state.reducerCategory,
        auth: state.authReducer,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onLogin: (params) => {
            dispatch(loginAction(params))
        },
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Index))
