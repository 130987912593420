import React from "react"
import {connect} from "react-redux";

import {MARKET} from "../../../constants/fastCMA";
import {Image as AntdImage} from "antd";

const Image = (props) => {
    const {market, id, width, height, index, className} = props

    const getImageUrl = (id) => {
        let url = MARKET[market.toUpperCase()].STATIC_URI.replace('[id]', id)
        if (index) url = url.replace('img-1', `img-${index}`);
        return url;
    }
    return <AntdImage
        className={className}
        fallback="/img/default.png"
        width={width || 100}
        height={height || 100}
        src={getImageUrl(id)}/>
}

export default connect(state => {
    const data = state.FastCMAReducer
    return {
        market: data?.cma.market || 'houston'
    }
}, null)(Image);
