import React, {Component} from 'react';
import {connect} from "react-redux";
import {Switch, Route} from 'react-router-dom';
import routes from '../routers';
import {loginAction, registerAction} from "../data/actions/authAction";
import {
    Icon
} from './common';
import {message, Modal} from "antd";
import "../assets/pages/login.scss"
import TAG_DEFINE from "../constants/common";
import ForgotPassword from "./common/components/ForgotPassword";
import LoginComponent from "./pages/authentication/login";

// import '../assets/layout/css/pages/login-register-lock.css'

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isForgotPassword: false,
            fetchingLogin: true,
            email: "",
            password: "",
            code: "",
            checkAuth: false,
            registerSuccess: false,
            register: {
                email: "",
                password: "",
                confirm_password: "",
                first_name: "",
                last_name: ""
            }
        }
    }

    // componentDidMount() {
    //     const signUpButton = document.getElementById('signUp');
    //     const signInButton = document.getElementById('signIn');
    //     const container = document.getElementById('id_login_container');
    //
    //     signUpButton.addEventListener('click', () => {
    //         container.classList.add("right-panel-active");
    //     });
    //
    //     signInButton.addEventListener('click', () => {
    //         container.classList.remove("right-panel-active");
    //     });
    // }
    //
    // componentWillReceiveProps(nextProps) {
    //     if (nextProps) {
    //         this.setState({checkAuth: nextProps.checkAuth.logging})
    //     }
    // }
    //
    // componentDidUpdate(prevProps, prevState) {
    //     if ((this.props.checkAuth?.registerSuccess !== prevProps.checkAuth?.registerSuccess)) {
    //         this.setState({
    //             registerSuccess: this.props.checkAuth?.registerSuccess
    //         })
    //         if (this.props.checkAuth?.registerSuccess) {
    //             const container = document.getElementById('id_login_container');
    //             container.classList.remove("right-panel-active");
    //             this.setState({
    //                 register: {
    //                     email: "",
    //                     password: "",
    //                     confirm_password: "",
    //                     first_name: "",
    //                     last_name: ""
    //                 }
    //             })
    //             Modal.success({
    //                 title: TAG_DEFINE.VALIDATION.statusCode.code["201"],
    //                 content: `Thank you for creating the account, you can now log in with the provided information`,
    //             });
    //         }
    //     }
    // }
    //
    // onChange = (e) => {
    //     let target = e.target;
    //     let name = target.name;
    //     let value = target.value;
    //     this.setState({
    //         [name]: value
    //     });
    // };
    //
    // onChangeRegister = (e) => {
    //     let target = e.target;
    //     let name = target.name;
    //     let value = target.value;
    //     this.setState({
    //         register: {
    //             ...this.state.register,
    //             [name]: value
    //         }
    //     });
    // };
    //
    // onSubmit = (e) => {
    //     e.preventDefault();
    //     this.login();
    // };
    //
    // onRegister = (e) => {
    //     e.preventDefault();
    //     if (this.state.register.password) {
    //         if (this.state.register.password === this.state.register.confirm_password) {
    //             if ((/^\s+|\s+$/g.test(this.state.register.password[0]) || /^\s+|\s+$/g.test(this.state.register.password[this.state.register.password.length - 1])) || /^\s+|\s+$/g.test(this.state.register.confirm_password [0]) || /^\s+|\s+$/g.test(this.state.register.confirm_password [this.state.register.confirm_password.length - 1])) {
    //                 message.error("'password' cannot be empty")
    //             } else {
    //                 if ((this.state.register?.password || "").length < 6) {
    //                     message.error("Password minimum 6 characters")
    //                 } else {
    //                     this.props.onActionRegister(this.state.register);
    //                 }
    //             }
    //         } else {
    //             message.error("Password does not match")
    //         }
    //     } else {
    //         message.error("Password is required")
    //     }
    // };
    //
    // login() {
    //     this.props.onLogin(this.state);
    //     this.setState({fetchingLogin: false})
    // }

    render() {
        const {email, password, fetchingLogin, checkAuth, isForgotPassword} = this.state;
        return <LoginComponent />
        // return (
        //     <div className="hn__login">
        //         <ForgotPassword isForgotPassword={isForgotPassword} actionModal={(e) => this.setState({
        //             isForgotPassword: e
        //         })}/>
        //         <div className="text-center mb-3">
        //             <img src="./layout/logo.png"
        //                  className="" width="200px"/>
        //         </div>
        //         <div className="container login_container login__form" id="id_login_container">
        //             <div className="form-container sign-up-container">
        //                 <form method="POST"
        //                       onSubmit={this.onRegister}>
        //                     <h3 className="mb-2">Create Account</h3>
        //                     <input name="last_name" value={this.state.register.last_name} required type="text"
        //                            placeholder="Last Name"
        //                            onChange={this.onChangeRegister}/>
        //                     <input name="first_name" value={this.state.register.first_name} required type="text"
        //                            placeholder="First Name"
        //                            onChange={this.onChangeRegister}/>
        //                     <input name="email" value={this.state.register.email} type="email" required
        //                            placeholder="Email"
        //                            onChange={this.onChangeRegister}/>
        //                     <input name="password" value={this.state.register.password} type="password" max={6} required
        //                            placeholder="Password"
        //                            onChange={this.onChangeRegister}/>
        //                     <input name="confirm_password" value={this.state.register.confirm_password} type="password"
        //                            max={6} required
        //                            placeholder="Confirm password"
        //                            onChange={this.onChangeRegister}/>
        //                     <button className="mt-3 btn_sigup_page" disabled={checkAuth}>Sign Up {checkAuth &&
        //                         <Icon type="sync-outlined" spin/>}</button>
        //                 </form>
        //             </div>
        //             <div className="form-container sign-in-container">
        //                 <form method="POST"
        //                       onSubmit={this.onSubmit}>
        //                     <h1 className="mb-2">Sign in</h1>
        //                     <div style={{height: "130px"}}>
        //                         <input type="email" id="login" value={email} name="email" required placeholder="Email"
        //                                onChange={this.onChange} className="fadeIn second"/>
        //                         <input type="password" id="password" required name="password" value={password}
        //                                onChange={this.onChange} placeholder="Password" className="fadeIn third"/>
        //                     </div>
        //                     <p className="w-100 cursorPointer color--main text-start fs--14px"
        //                        onClick={() => this.setState({isForgotPassword: true})}>Forgot your password?</p>
        //                     <button className="mt-3 btn_init_page" disabled={checkAuth}>Login {checkAuth &&
        //                         <Icon type="sync-outlined" spin/>}</button>
        //                 </form>
        //             </div>
        //             <div className="overlay-container">
        //                 <div className="overlay">
        //                     <div className="overlay-panel overlay-left">
        //                         <h4 style={{color: "white", fontWeight: "bold", width: "100%"}}>Welcome <span
        //                             style={{color: "#FCCE01"}}>to Megalith</span></h4>
        //                         <div>
        //                             To keep connected with us please
        //                             login with your personal
        //                             info
        //                         </div>
        //                         <button className="ghost btn_sigup_page" id="signIn">Sign In</button>
        //                     </div>
        //                     <div className="overlay-panel overlay-right">
        //                         <h4 style={{color: "white", fontWeight: "bold", width: "100%"}}>Welcome <span
        //                             style={{color: "#FCCE01"}}>to Megalith</span></h4>
        //                         <div>
        //                             Enter your personal details and start
        //                             journey with us
        //                         </div>
        //                         <button className="ghost btn_init_page" id="signUp">Sign Up</button>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // );
    }
}

const mapStateToProps = state => {
    return {
        // categories: state.reducerCategory,
        checkAuth: state.authReducer,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onLogin: (params) => {
            dispatch(loginAction(params))
        },
        onActionRegister: (params) => {
            dispatch(registerAction(params))
        },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);

