import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define";
import {convertBooleanToInt, formatInt, removeObjectNullFull} from "../../../utils/functions";
import {checkNumber} from "../../../utils/commonUtils";

export default class ComparablesRequestResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.id = data?.id || "";
        this.code = data?.code || "";
        this.profile = data?.profile || "";
        this.market = data?.market || "";
        this.property_address = data?.property_address || "";
        this.status = data?.status || "";
        this.status_explain = data?.status_explain || "";
        this.created_at = moment(data.created_at).format(DATE_TIME_FORMAT.DATE_TIME)
        this.updated_at = moment(data.updated_at).format(DATE_TIME_FORMAT.DATE_TIME)
        //  ====== Detail response =====
        this.params = {
            market_name: data?.params?.market_name || "",
            profile: data?.params?.profile || "",
            type: data?.params?.type || "",
            latitude: data?.params?.latitude || "",
            longitude: data?.params?.longitude || "",
            year_built: data?.params?.year_built || "",
            square_feet: data?.params?.square_feet || "",
            property_type: data?.params?.property_type || "",
            property_subtype: data?.params?.property_subtype || "",
            property_address: data?.params?.property_address || "",
        };
        this.response = data?.response || {};
    }

    exportList() {
        return {
            id: this.id,
            code: this?.code,
            profile: this?.profile,
            market: this?.market,
            property_address: this?.property_address,
            status_explain: this?.status_explain,
            created_at: this?.created_at,
            status: this?.status,
        }
    }

    exportDetail() {
        return {
            id: this.id,
            code: this.code,
            params: this.params,
            response: this.response,
            status: this.status,
            status_explain: this.status_explain,
            created_at: this.created_at,
            updated_at: this.updated_at,
        }
    }
}

export const column = ["code", "profile", "market", "property_address", "status_explain", "created_at"];

export const fieldDetailRemove = [];

export const filtersResponse = (filters) => {
    return removeObjectNullFull({
        address: filters?.address || "",
        page_size: filters?.page_size || 10,
        page_index: filters?.page_index || 1,
        order_field: filters?.order_field || "",
        order_value: filters?.order_value || "",
    })
}
