import {createSelector} from 'reselect'

const stateReducer = state => state.authReducer;

export const getProfile = (initData) =>
    createSelector(
        stateReducer,
        authReducer => authReducer.user
    )

export const getFetchingAuth = (initData) =>
    createSelector(
        stateReducer,
        authReducer => authReducer.isFetching
    )

export const getStatusAction = (initData) =>
    createSelector(
        stateReducer,
        authReducer => authReducer.statusAction
    )

export const getPasswordRequest = (initData) =>
    createSelector(
        stateReducer,
        authReducer => authReducer.hash
    )

// export const getDataOrderItem = (initItems) =>
//     createSelector(
//         stateReducer,
//         orderReducer => Object.keys(initItems?.item || {}).length > 0 ? initItems?.item : orderReducer.detail?.item
//     )
//
// export const getErrors = (initItems) =>
//     createSelector(
//         stateReducer,
//         orderReducer => orderReducer.errors
//     )
//
// export const getDataUpdate = (initItems) =>
//     createSelector(
//         stateReducer,
//         orderReducer => orderReducer.url
//     )
