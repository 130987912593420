import React, { useState } from "react";
import {connect} from "react-redux";

import { Form, Button, Input, Row, Col, InputNumber } from "antd";

import { mls_lookup } from "../../../data/actions/fastCMA";

const MLSNumberLookUp = (props) => {
  const { onSubmitMLSNumber, status } = props;
  const [form] = Form.useForm();

  const escapeNumber = (event) => {
    const validatedNumber = event.target.value.replace(/[^0-9]/g, '');
    form.setFieldsValue({
      mlsnumber: validatedNumber
    });
  }

  const doLookup = (event) => {
    onSubmitMLSNumber(form.getFieldValue('mlsnumber'));
  }

	return (
		<Form 
      name="mls-lookup" layout="vertical"
      form={form}
      onFinish={doLookup}
    >
      <Row gutter={8}>
        <Col span={16}>
          <Form.Item
            name="mlsnumber"
            label="MLS#"
            rules={[
              {
                required: true,
                message: "Please input MLS Number",
              },
            ]}
          >
            <Input placeholder="MLS number" onChange={escapeNumber} />
          </Form.Item>
        </Col>
        <Col span={8} style={{ 
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'right'
          }}>
          <Form.Item shouldUpdate wrapperCol={24}>
            <Button type="primary" shape="round" htmlType="submit" style={{ width: 250 }}>
              Look up
            </Button>
          </Form.Item>
        </Col>
      </Row>
		</Form>
	);
};

const mapStatesToProps = (state) => {
  const data = state.FastCMAReducer
  return {
    status: data.status
  }
}
const mapDispatchToProps = (dispatch) => {
    return {
        onSubmitMLSNumber: () => {
            dispatch(mls_lookup())
        },
    }
};

export default connect(mapStatesToProps, mapDispatchToProps)(MLSNumberLookUp);