import React, {Component} from "react";
import {Switch, Route} from "react-router-dom";
import {PATHS, ROUTE_LIST} from "../constants/define";
import Layout from "./Main";
import LoginRoute from "./LoginRoute";
import PrivateRoute from "./PrivateRoute";

import "../assets/index.scss";
import ComparablesRequestList from "./pages/comparablesRequest";
import VerifyAuthRoute from "./pages/authentication";

class MainCheck extends Component {
    componentDidMount() {
        // window.resizeLoadBody();
    }

    render() {
        return (
            <Route>
                <Switch>
                    <LoginRoute path="/login" component={Layout.Login}/>
                    <VerifyAuthRoute path="/auth/:id" component={Layout.Authentication}/>
                    <PrivateRoute path="/" exact component={Layout.FastCMA}/>
                    <PrivateRoute
                        path="/fast-cma/profiles/:add"
                        component={Layout.FastCMAProfile}
                    />
                    <PrivateRoute
                        path="/fast-cma/profiles"
                        component={Layout.FastCMAProfile}
                    />
                    <PrivateRoute path="/fast-cma" component={Layout.FastCMA}/>

                    <PrivateRoute path="/comparables-request/list" component={Layout.ComparablesRequestList}/>
                    <PrivateRoute path="/comparables-request/action/:id" component={Layout.ComparablesRequestAction}/>
                    <PrivateRoute
                        path="/403"
                        exact
                        component={Layout.Error403}
                    />
                    <Route path="" exact={false} component={Layout.Error}/>
                </Switch>
            </Route>
        );
    }
}

export default MainCheck;
