import Index from "./pages";
import FastCMA from "./pages/FastCMA";
import { FastCMAProfile }  from "./pages/FastCMA/Profile";
import ComparablesRequestList from "./pages/comparablesRequest";
import ComparablesRequestAction from "./pages/comparablesRequest/action";
import Login from "./Login";
import Error403 from "./403";
import Error from "./Error";
import Authentication from "./pages/authentication";
// import Login from "../Login";

export default {
    Index,
    FastCMA,
    FastCMAProfile,
    ComparablesRequestList,
    ComparablesRequestAction,
    Login,
    Authentication,
    Error403,
    Error,
    // Login,
};
