import React, {useEffect} from "react";
import {connect} from "react-redux";
import {find_comparables} from "../../../data/actions/fastCMA";

import "./FindComparablesForm.scss";

import {Form, Row, Col, Button, Space, Radio, Input, InputNumber, Slider, Checkbox} from "antd";
import {CaretDownOutlined, CaretUpOutlined, LoadingOutlined} from "@ant-design/icons";
import {Typography, Select} from "antd";
import {useState} from "react";
import {MARKET} from "../../../constants/fastCMA";

const {Title} = Typography;
const {Option} = Select;

const TextField = (props) => {
    const {name, field, type, step, min, max} = props
    const numberParser = (value) => {
        return Math.round(parseFloat(value.replace(/[^0-9\.\,]*/g, '')) * 100) / 100
    }
    const onSliderChange = (value) => {

    }

    return (
        <>
            <Form.Item name={name} label={field.label} className="custom--input">
                {type === 'number' ?
                    <InputNumber
                        parser={numberParser}
                        placeholder={field.label}
                        step={step || false}
                        min={min || false}
                        max={max || false}
                    /> : <Input placeholder={field.label}/>}
            </Form.Item>
            {/* {typeof min !== 'undefined' && typeof max !== 'undefined' && <Slider
        min={min}
        max={max}
        value={23}
        onChange={onSliderChange}
      />} */}
        </>
    )
}

const SelectField = (props) => {
    const {name, field} = props
    const [getValues, setGetValues] = useState();
    const handleChange = (value) => {
        setGetValues(value)
        // console.log(value)
    }

    useEffect(() => {
        setGetValues(props?.form?.getFieldValue(name))
        // setGetValues(props?.form?.getFieldsValue(name))
    }, []);

    if (field.mode === "multiple") {
        return (
            <Form.Item name={name} label={field.label}>
                <Select
                    suffixIcon={e => e?.open ? <CaretUpOutlined/> : <CaretDownOutlined/>}
                    showArrow
                    className="custom--selector"
                    mode={field.mode}
                    style={{width: "100%"}}
                    onChange={handleChange}
                    optionLabelProp="label"
                >
                    {/*<Option value="-1">Select</Option>*/}
                    {/*{Object.keys(field.options).map((key) => <Option key={key}*/}
                    {/*                                                 value={key}> {field.options[key]}</Option>)}*/}
                    {Object.keys(field.options).map((key) => {
                        return (
                            <Option key={key} value={key} label={field.options[key]}
                                    className="custom--selector-hide-check">
                                <div className="row">
                                    <div className="col-10">
                                        {field.options[key]}
                                    </div>
                                    <div className="col-2 text-end">
                                        <Checkbox checked={(getValues || []).includes(key)}/>
                                    </div>
                                </div>
                            </Option>
                        )
                    })}
                </Select>
            </Form.Item>
        )
    } else {
        return (
            <Form.Item name={name} label={field.label}>
                <Select
                    suffixIcon={e => e?.open ? <CaretUpOutlined/> : <CaretDownOutlined/>}
                    className="custom--selector"
                    mode={field.mode}
                    style={{width: "100%"}}
                    onChange={handleChange}
                >
                    <Option value="-1">Select</Option>
                    {Object.keys(field.options).map((key) => <Option key={key}
                                                                     value={key}> {field.options[key]}</Option>)}
                </Select>
            </Form.Item>
        )
    }
}

const FindComparablesForm = (props) => {
    const {findComparables, cmaStatus, subject, defaultType, market} = props;
    const [type, setType] = useState(defaultType || "arv-distance");
    const [odlParams, setOdlParams] = useState({});
    const [form] = Form.useForm();
    useEffect(() => {
        return () => {
            form.resetFields();
        }
    }, []);

    useEffect(() => {
        setOdlParams(props?.params)
        if (Object.keys(props?.params || {}).length > 0) {
            form.setFieldsValue({
                "property-type": props?.params?.property_type,
                "property-sub-type": (props?.params?.property_subtype || "").split((",")),
                "year-build": props?.params?.year_built,
                "latitude": props?.params?.latitude,
                "longitude": props?.params?.longitude,
                "property_address": props?.params?.property_address,
                "square-feet": props?.params?.square_feet,
            })
        }
    }, [props.params]);


    const formFields = {
        "property-type": {
            label: "Property type",
            options: MARKET[market.toUpperCase()].PROPERTY_TYPE,
        },
        "property-sub-type": {
            label: "Property Sub type",
            mode: "multiple",
            options: MARKET[market.toUpperCase()].PROPERTY_SUB_TYPE,
        },
        "year-build": {
            label: "Year build",
            defaultValue: subject ? subject.year_built : "2006"
        },
        "square-feet": {
            label: "Square feet",
            defaultValue: subject ? subject.square_feet : "1694"
        },
        "recency": {
            label: 'Recency (days)',
            defaultValue: 180,
            tooltip: 'Number of days to find Sold/Leased properties from CMA date. Minimum value can be 0 days and maximum can be 1080 days. If it is 0, it will show all properties.'
        },
        'distance': {
            label: 'Distance range +/-(miles)',
            defaultValue: '0.25',
            tooltip: 'Distance is in miles. Maximum distance is 10 miles, minimum distance 0 of a mile. If it is 0, it will show all properties.'
        },
        'year-build-range': {
            label: 'Year built range +/-(years)',
            defaultValue: 10,
            tooltip: 'Year Built +-. If the property was built in 2000 and you have 10+-, it means the system will find comparables between 1990 and 2010. Maximum is 200 years. If it is 0, it will show all properties.'
        },
        'square-feet-range': {
            label: 'Square feet range (%)',
            defaultValue: 20,
            tooltip: 'Square Feet percentage. For example if it is 1000 sf and you have 20%. The system will search houses between 800 sf and 1,200 sf. Minimum is 0 maximum is 100%. If it is 0%, it will show all properties.'
        }
    };

    const initialValues = {};
    for (let key in formFields) {
        let defaultValue = (formFields[key].defaultValue || '');
        if (formFields[key].options) {
            defaultValue = Object.keys(formFields[key].options);
            if (formFields[key].mode !== 'multiple') {
                defaultValue = defaultValue[0]
            }
        }
        initialValues[key] = defaultValue;
    }

    const handleTypeChange = (event) => {
        const updateType = event.target.value;
        setType(updateType);
    };

    const onReset = () => {
        form.resetFields();
    };

    const onSubmitHandle = (values) => {
        if (Object.keys(props?.params || {}).length < 1) {
            values.subject = subject?.mls_num;
            values.latitude = subject?.latitude;
            values.longitude = subject?.longitude;
            values.property_address = props?.getAddress;
            values.idres_property = subject?.idres_property || "";
        }
        if (!!defaultType) {
            values = {
                ...subject,
                ...values
            };
        }
        if (Object.keys(props?.params || {}).length > 0) {
            findComparables(type, values, !!defaultType, values)
        } else {
            findComparables(type, values, !!defaultType)
        }
    }

    // useEffect(() => {
    //     form.resetFields();
    // }, [subject])
    return (
        <Form
            layout="vertical"
            // name="find-comparables"
            form={form}
            className="find-comparables-form"
            onFinish={onSubmitHandle}
            initialValues={initialValues}
        >
            {!defaultType &&
                <Radio.Group
                    style={{marginBottom: 12}}
                    value={type}
                    onChange={handleTypeChange}
                >
                    <Radio.Button value="arv-distance">ARV - Distance</Radio.Button>
                    <Radio.Button value="arv-subdivision" disabled="disabled">ARV - Subdivision</Radio.Button>
                    <Radio.Button value="custom" disabled="disabled">Custom CMA</Radio.Button>
                </Radio.Group>
            }

            <Title level={5} style={{marginBottom: 12}}>
                Property Information
            </Title>
            <Row gutter={24}>
                <Col span={6}>
                    <SelectField name={'property-type'} field={formFields['property-type']}/>
                </Col>
                <Col span={6}>
                    <SelectField form={form} name={'property-sub-type'} field={formFields['property-sub-type']}/>
                </Col>
                <Col span={6}>
                    <TextField name={'year-build'} field={formFields['year-build']}/>
                </Col>
                <Col span={6}>
                    <TextField name={'square-feet'} field={formFields['square-feet']}/>
                </Col>
            </Row>

            {/*{type === 'custom' &&*/}
            {/*    <>*/}
            {/*        <Title level={5} style={{marginBottom: 12}}>*/}
            {/*            Comparable criteria*/}
            {/*        </Title>*/}
            {/*        <Row gutter={24}>*/}
            {/*            <Col span={6}><TextField form={form} name="recency" field={formFields['recency']}/></Col>*/}
            {/*            <Col span={6}><TextField form={form} type="number" step={0.01} min={0} max={10} name="distance"*/}
            {/*                                     field={formFields['distance']}/></Col>*/}
            {/*            <Col span={6}><TextField form={form} name="year-build-range"*/}
            {/*                                     field={formFields['year-build-range']} type="number" step={1} min={0}*/}
            {/*                                     max={50}/></Col>*/}
            {/*            <Col span={6}><TextField form={form} name="square-feet-range"*/}
            {/*                                     field={formFields['square-feet-range']} type="number" min={0}*/}
            {/*                                     max={50}/></Col>*/}
            {/*        </Row>*/}
            {/*    </>*/}
            {/*}*/}

            <div className="row">
                <div className="col-12 text-end">
                    <Button className="me-3" type="primary" ghost onClick={onReset}>Reset</Button>
                    <Button htmlType="submit" type="primary" ghost>
                        {cmaStatus === 'searching' ? <LoadingOutlined/> : 'Apply'}
                    </Button>
                </div>
            </div>
        </Form>
    );
};

const mapStatesToProps = state => {
    const data = state.FastCMAReducer
    return {
        cmaStatus: data.cma.status,
        subject: data.address_lookup.subject,
        market: data.cma.market || 'houston'
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        findComparables: (type, params, showResult, popupValue) => {
            dispatch(find_comparables({
                type: type,
                filter: params,
                showResult: showResult,
                popupValue: popupValue
            }));
        }
    }
}

export default connect(mapStatesToProps, mapDispatchToProps)(FindComparablesForm);
