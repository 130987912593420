import React, {Component, Fragment, useEffect, useState} from 'react';
import {connect, useSelector} from "react-redux";
import {Switch, Route, withRouter} from 'react-router-dom';
import {checkUserAction, refreshTokenAction} from "../data/actions/authAction";
import {logoutAction} from "../data/actions/authAction";
import DEFINE, {CONFIG_LOCAL_STORAGE} from "../constants/define";
import $ from "jquery";
import * as LocalStorage from "../utils/localStorage";
import {Spin, Tabs} from "antd"
import {Header} from "./common";
import Navigation from "./common/layout/Navigation";
import {capitalizeFirstLetter} from "../utils/functions";

const {TabPane} = Tabs;

const ContainerIndex = props => {

    const [collapsed, setCollapsed] = useState(false),
        [listTabs, setListTabs] = useState([]),
        [activeTab, setActiveTab] = useState(0);

    const toggleCollapsed = () => {
        setCollapsed(!collapsed)
    };

    useEffect(() => {
        let e = (window.innerHeight > 0 ? window.innerHeight : window.screen.height) - 1;
        $(".page-wrapper").css("min-height", e + "px");
        console.log('VERSION:', process.env.REACT_APP_VERSION);
        // props.onCheckUser();

        // const profile = LocalStorage.get(CONFIG_LOCAL_STORAGE.PROFILE);
        const token = LocalStorage.get(CONFIG_LOCAL_STORAGE.ACCESS_TOKEN);
        // const refreshToken = LocalStorage.get(CONFIG_LOCAL_STORAGE.REFRESH_TOKEN);
        // const expires = LocalStorage.get(CONFIG_LOCAL_STORAGE.EXPIRES);
        //TODO: Ad-hoc fix, recheck
        if (!token) {
            LocalStorage.remove(CONFIG_LOCAL_STORAGE.PROFILE);
            LocalStorage.remove(CONFIG_LOCAL_STORAGE.EXPIRES);
            LocalStorage.remove(CONFIG_LOCAL_STORAGE.REFRESH_TOKEN);
            LocalStorage.remove(CONFIG_LOCAL_STORAGE.ACCESS_TOKEN);
        } else
            props.onCheckUser();
    }, [])

    if (Object.keys(props.authLogin?.user || {}).length > 0) {
        return (
            <>
                <Header toggleCollapsed={toggleCollapsed} collapsed={collapsed}/>
                {/*<div className="hn__navigation float-left">*/}
                {/*<Navigation toggleCollapsed={toggleCollapsed} collapsed={collapsed} history={props.history}/>*/}
                {/*</div>*/}
                <div className={`hn__content ${collapsed ? "hn__content--expanded" : ""}`} style={{
                    minHeight: "calc(100vh - 50px)"
                }}>
                    {
                        props.children
                    }
                </div>
                <div className="text-end hn__footer">
                    <a href="https://megalithinc.com" target="_blank" className="text-decoration-none" style={{
                        color: "#a0a0a0"
                    }}>
                        Powered by Megalith Solutions
                    </a>
                </div>
            </>
        );
    } else {
        return null
    }
}

const mapStateToProps = state => {
    return {
        authLogin: state.authReducer,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onCheckUser: () => {
            dispatch(checkUserAction())
        },
        onLogoutRequest: () => {
            dispatch(logoutAction());
        },
        refreshToken: token => dispatch(refreshTokenAction(token)),
    }
};

// export default ContainerIndex;
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContainerIndex));

