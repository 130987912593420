import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {loginAction} from "../../../data/actions/authAction";
import {switch_type} from "../../../data/actions/fastCMA";

import {Tabs, Layout, Typography, Card, Select, Anchor, Button} from "antd";

import AddressLookup from "./AddressLookUp";
import MLSNumberLookUp from "./MLSNumberLookUp";
import Report, {ReportAndShare} from "./Report";

import BatchCMA from "./BatchCMA";
import FindComparablesForm from "./FindComparablesForm";
import {useScript} from "../../../utils/commonUtils";
import {STEPS, STEP_VISIBLE} from "../../../constants/fastCMA";
import {change_market_channel, address_lookup_reset, run_cma} from "../../../data/actions/fastCMA";

import MainLayout from "../../common/layout/MainLayout";
import CMAResults from "./CMAResults";
import "./FastCMA.scss";
import ProfileSelect from "./Profile/ProfileSelect";
import {CaretDownOutlined, CaretUpOutlined, ReloadOutlined} from "@ant-design/icons";
import {ActionInit} from "../../../data/hooks/fastCMA";
import {ActionInit as ActionInitComparables} from "../../../data/hooks/comparablesRequest";

const {TabPane} = Tabs;


const blockStyles = {
    marginTop: '16px',
    backgroundColor: '#f0f0f0',
}

const showStep = (checker, currentStep) => {
    return STEP_VISIBLE[checker] && STEP_VISIBLE[checker].indexOf(currentStep) >= 0;
}
// change_market_channel
const MarketSwitcher = connect(state => {
    const data = state.FastCMAReducer
    return {
        cmaStep: data.step
    }
}, dispatch => {
    return {
        changeMarket: (market) => {
            dispatch(change_market_channel({
                market: market
            }))
        }
    }
})((props) => {
    const {changeMarket, cmaStep} = props;

    useEffect(() => {
        let storeSearch = localStorage.getItem("search_eva");
        if (storeSearch) {
            storeSearch = JSON.parse(storeSearch)
            changeMarket(storeSearch?.market)
        }
    }, [])

    const handleMarketChange = (value) => {
        let storeSearch = localStorage.getItem("search_eva");
        if (storeSearch) {
            storeSearch = JSON.parse(storeSearch)
            storeSearch = {
                ...storeSearch,
                market: value
            }
            localStorage.setItem("search_eva", JSON.stringify(storeSearch))
        } else {
            localStorage.setItem("search_eva", JSON.stringify({market: value}))
        }
        changeMarket(value)
    }

    return (
        <Select
            suffixIcon={e => e?.open ? <CaretUpOutlined/> : <CaretDownOutlined/>}
            className={`custom--selector ${props?.className ? props?.className : ""}`}
            defaultValue={`${localStorage.getItem("search_eva") ? JSON.parse(localStorage.getItem("search_eva") || {})?.market ? JSON.parse(localStorage.getItem("search_eva") || {})?.market : "houston" : "houston"}`}
            disabled={cmaStep !== STEPS.LOOKUP}
            style={{width: 200}}
            onChange={handleMarketChange}>
            <Select.Option value="austin">Austin Metro</Select.Option>
            <Select.Option value="houston">Houston Metro</Select.Option>
            <Select.Option value="dfw">DFW Metro</Select.Option>
            <Select.Option value="sanantonio">SanAntonio Metro</Select.Option>
        </Select>
    )
})

const FastCMA = (props) => {
    const {cmaStep, onTabChange, restart, showCMAResult} = props,
        actionInitComparables = ActionInitComparables(),
        actionInit = ActionInit();

    const [getAddress, setGetAddress] = useState("");

    useEffect(() => {
        actionInitComparables();
        actionInit();
        // ==> First page loading <===
        // props.history.push(ROUTE_LIST.PreForeClosure)
        return () => {
            actionInit();
            setGetAddress("");
        }
    }, []);

    const onChangeHandle = (activeKey) => {
        onTabChange(activeKey)
    }

    const onResetFilter = (evt) => {
        evt.preventDefault();
        restart()
    }

    const onShowCMAResult = (evt) => {
        evt.preventDefault();
        showCMAResult()
    }

    const onGGLoad = () => {

        window.dispatchEvent(new Event('google-map-api-loaded'))
    }

    // Load script of google map library
    useScript('https://maps.googleapis.com/maps/api/js?key=' + process.env.REACT_APP_GOOGLE_API_KEY + '&libraries=places', onGGLoad);

    return (
        <MainLayout
            pathname={props.location.pathname}
            history={props.history}
            disabledTab={true}
            title={<div className="fast-cma-title-wrapper">
                <Anchor className="fast-cma-title" affix={false} onClick={onResetFilter}><Anchor.Link href="#reset"
                                                                                                      title="Look Up Property"/></Anchor>
                {cmaStep === STEPS.SHOW_CMA_REPORT &&
                    <>
                        &nbsp;&gt; <Anchor className="btn-back-to-cma-result" affix={false}
                                           onClick={onShowCMAResult}><Anchor.Link href="#reset"
                                                                                  title="CMA"/></Anchor> &nbsp;&gt; Report
                        & Share
                    </>
                }
                <Button className="new-search-btn" onClick={onResetFilter}><ReloadOutlined
                    className="custom--icon"/></Button>
            </div>
            }
        >
            {
                showStep(STEPS.LOOKUP, cmaStep) && (
                    <div className="mb-4">
                        <Tabs defaultActiveKey="1" onChange={onChangeHandle}>
                            <TabPane tab="Address" key="address">
                                <AddressLookup
                                    location={props?.location}
                                    cmaStep={cmaStep}
                                    setGetAddress={e => setGetAddress(e)}
                                    selectProfile={<ProfileSelect disabled={cmaStep !== STEPS.LOOKUP}
                                                                  className="w-100"/>}
                                    selectMarket={<MarketSwitcher className="w-100"/>}
                                />
                            </TabPane>
                            <TabPane tab="MLS Number" key="mls-number" disabled="disabled">
                                <MLSNumberLookUp/>
                            </TabPane>
                            <TabPane tab="Batch CMA" key="batch-cma" disabled="disabled">
                                <BatchCMA/>
                            </TabPane>
                        </Tabs>
                    </div>
                )
            }

            {/*{showStep(STEPS.FIND_COMPARABLES, cmaStep) &&*/}
            {/*    <div className="mb-4">*/}
            {/*        <p className="fs--25px">Find comparables</p>*/}
            {/*        <FindComparablesForm getAddress={getAddress} isHome={true}/>*/}
            {/*    </div>*/}
            {/*}*/}


            {/*{showStep(STEPS.RUN_CMA, cmaStep) &&*/}
            {/*    <div className="mb-4">*/}
            {/*        <p className="fs--25px">Run CMA</p>*/}
            {/*        <CMAResults/>*/}
            {/*    </div>*/}
            {/*}*/}

            {/*{showStep(STEPS.SHOW_CMA_RESULTS, cmaStep) &&*/}
            {/*    <Card style={blockStyles}>*/}
            {/*        <Report/>*/}
            {/*    </Card>*/}
            {/*}*/}

            {/*{showStep(STEPS.SHOW_CMA_REPORT, cmaStep) &&*/}
            {/*    <Card style={blockStyles}>*/}
            {/*        <ReportAndShare/>*/}
            {/*    </Card>*/}
            {/*}*/}

        </MainLayout>
    );
    // return <PreForeClosureComponent {...props} key={Date.now()} getUpdateProfile={getUpdateProfile}/>
};

const mapStateToProps = (state) => {
    const data = state.FastCMAReducer
    return {
        // categories: state.reducerCategory,
        auth: state.authReducer,
        cmaStep: data.step
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onLogin: (params) => {
            dispatch(loginAction(params));
        },
        onTabChange: (active) => {
            dispatch(switch_type())
        },
        restart: () => {
            // dispatch(address_lookup_reset())
            window.location.reload();
        },
        showCMAResult: () => {
            dispatch(run_cma())
        }
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(FastCMA)
);
