import React, {Component} from 'react';
import {SearchOutlined, DeleteOutlined, FormOutlined, MoreOutlined, CloseCircleOutlined, IdcardOutlined, SyncOutlined, FilterOutlined, ZoomInOutlined, CloseOutlined, LoadingOutlined, PlusOutlined, UploadOutlined, EditOutlined, EyeOutlined, CarryOutOutlined} from "@ant-design/icons";
import {omit} from 'lodash';

export default class Icon extends Component {

    render() {
        const {className, type} = this.props;
        const props = omit(this.props, ['className']);
        switch (type) {
            case "carry-out-outlined":
                return (
                    <CarryOutOutlined {...props}
                                      className={`hms-modal ${className || ''}`}>{this.props.children}</CarryOutOutlined>
                );
            case "edit-outlined":
                return (
                    <EditOutlined {...props}
                                  className={`hms-modal ${className || ''}`}>{this.props.children}</EditOutlined>
                );
            case "eye-outlined":
                return (
                    <EyeOutlined {...props}
                                 className={`hms-modal ${className || ''}`}>{this.props.children}</EyeOutlined>
                );
            case "upload-outlined":
                return (
                    <UploadOutlined {...props}
                                    className={`hms-modal ${className || ''}`}>{this.props.children}</UploadOutlined>
                );
            case "search":
                return (
                    <SearchOutlined {...props}
                                    className={`hms-modal ${className || ''}`}>{this.props.children}</SearchOutlined>
                );
            case "delete":
                return (
                    <DeleteOutlined {...props}
                                    className={`hms-modal ${className || ''}`}>{this.props.children}</DeleteOutlined>
                );
            case "form":
                return (
                    <FormOutlined {...props}
                                  className={`hms-modal ${className || ''}`}>{this.props.children}</FormOutlined>
                );
            case "more":
                return (
                    <MoreOutlined {...props}
                                  className={`hms-modal ${className || ''}`}>{this.props.children}</MoreOutlined>
                );
            case "close-circle":
                return (
                    <CloseCircleOutlined {...props}
                                         className={`hms-modal ${className || ''}`}>{this.props.children}</CloseCircleOutlined>
                );
            case "id-card-outlined":
                return (
                    <IdcardOutlined {...props}
                                    className={`hms-modal ${className || ''}`}>{this.props.children}</IdcardOutlined>
                );
            case "sync-outlined":
                return (
                    <SyncOutlined {...props}
                                  className={`hms-modal ${className || ''}`}>{this.props.children}</SyncOutlined>
                );
            case "filter-outlined":
                return (
                    <FilterOutlined {...props}
                                    className={`hms-modal ${className || ''}`}>{this.props.children}</FilterOutlined>
                );
            case "zoom-in":
                return (
                    <ZoomInOutlined {...props}
                                    className={`hms-modal ${className || ''}`}>{this.props.children}</ZoomInOutlined>
                );
            case "close-outlined":
                return (
                    <CloseOutlined {...props}
                                   className={`hms-modal ${className || ''}`}>{this.props.children}</CloseOutlined>
                );
            case "loading-outlined":
                return (
                    <LoadingOutlined {...props}
                                     className={`hms-modal ${className || ''}`}>{this.props.children}</LoadingOutlined>
                );
            case "plus-outlined":
                return (
                    <PlusOutlined {...props}
                                  className={`hms-modal ${className || ''}`}>{this.props.children}</PlusOutlined>
                );
            default:
                return "";
        }
    }
}
