import ApiService from '../../utils/ApiService';
import Urls from '../../utils/Urls';

export function addressLookup(data) {
    let endpoint = Urls.ADDRESS_LOOKUP;
    if ( data ) {
        let params = [];
        for(let key in data) {
            params.push(`${key}=${data[key]}`);
        }
        endpoint += '?' + params.join('&');
    }
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: endpoint
    }).get();
}

export function exportCMAReportPDF(data) {
    let endpoint = Urls.CMA_EXPORT_PDF

    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: endpoint,
        isDownload: true,
        // parser: (data) => new Token(data),
    }).post({data});
}

export function findComparables(data) {
    let endpoint = Urls.FIND_COMPARABLES;
    if ( data ) {
        let params = [];
        for(let key in data) {
            if ( Array.isArray(data[key]) ) {
                params.push(`${key}=${data[key].join(',')}`);
            } else {
                params.push(`${key}=${data[key]}`);
            }
        }
        endpoint += '?' + params.join('&');
    }
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: endpoint
    }).get();
}

export function getProfiles() {
    let endpoint = Urls.CMA_GET_PROFILES;
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: endpoint
    }).get();
}

export function updateProfiles(data) {
    let endpoint = Urls.CMA_UPDATE_PROFILES;
    data.profile_id = data.id || null;

    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: endpoint
    }).post(data);
}

export function deleteProfile(id) {
    let endpoint = Urls.CMA_DELETE_PROFILES;

    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: `${endpoint}/${id}`
    }).delete()
}