import React from "react";

import { Typography, Form, Button, Input, Table, Layout, Row, Col, Tabs, Upload, Card } from "antd";
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import './BatchCMA.scss';

const { Content } = Layout
const { Title, Text, Link } = Typography
const { TabPane } = Tabs;



const UploadCMA = (props) => {
  const [form] = Form.useForm();

  return (
    <>
      <Text type="secondary" >Upload a list of addresses and have Batch CMA perform automated valuation on each address using the ARV-Distance and ARV-Subdivision algorithms. Each address in the input file will deduct one from the Batch CMAs Available counter.</Text>
      <Form form={form} layout="vertical" style={{ marginTop: 24 }}>
        <Row style={{ marginBottom: 24 }}>
          <Col span={16}>
            <Text strong>Download template <br />
            Download our template and add multiple addresses and re-upload it for searching batch cma.</Text>
          </Col>
          <Col span={8}>
            <Link href="https://download" target="_blank">
              <DownloadOutlined /> Download CSV template here
            </Link>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item 
              label="Batch CMA Title"
              tooltip="Provide a batch cma title name, the batch will be saved in your 'Saved CMAs' section which can be later viewed and downloaded."
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item 
              label="Upload CSV file"
              tooltip="Re-upload the csv file with multiple addresses for searching the batch cma."
            >
              <Upload style={{ width: '100%'}}>
                <Button icon={<UploadOutlined />}>Select file to upload</Button>
              </Upload>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Button type="primary" ghost shape="round">Upload</Button>
          </Col>
        </Row>
      </Form>
    </>
  )
}

const BatchCMA = (props) => {
  const columns = [
    {
      title: 'Batch CMAs Run',
      dataIndex: 'batch-run',
      key: 'batch-run'
    },
    {
      title: 'Batch CMAs Available',
      dataIndex: 'batch-available',
      key: 'batch-available'
    },
  ];
  const data = [
    {
      'batch-run': 18,
      'batch-available': 982
    }
  ]
  return  (
    <Card>
      <Row>
        <Col span={8}>
          <Title level={3}>My CMAs</Title>
          <Table style={{ marginBottom: 6 }} pagination={{ position: [ 'none', 'none' ] }} columns={columns} dataSource={data} />
          <Button type="primary" shape="round" >Buy more BatchCMA credits</Button>
        </Col>
      </Row>
      <Row className="upload">
        <Col span={24} style={{ marginTop: 24, marginBottom: 24 }}>
          <Tabs defaultActiveKey="1" type="card" size="small">
            <TabPane tab="Upload CMA" key="upload" width="100%">
              <UploadCMA />
            </TabPane>
            <TabPane tab="Saved CMAs" key="saved-cmas">
              
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </Card>
  )
}

export default BatchCMA;