import ApiService from './../../utils/ApiService';
import Urls from './../../utils/Urls';
// import Token from './../models/Token';
import User from './../../data/mapping/User';
import AuthResponse from "../mapping/Response/AuthResponse";


export function login(email = "", password = "", code, token_captcha) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.LOGIN,
        // parser: (data) => new Token(data),
    }).post({email, password, code, token_captcha});
}

export function logout() {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.LOGOUT,
    }).post({});
}

export function getUserProfile() {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.PROFILE,
        parser: data => new User(data.data.result),
    }).get();
}

export function refreshToken(refreshToken) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.REFRESH,
        withoutAuth: true,
        // parser: (data) => new Token(data),
    }).post({refreshToken});
}

export function register(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.REGISTER,
    }).post(data);
}

export function passwordRequest(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.PASSWORD_REQUEST,
        parser: data => new AuthResponse(data.data.result).exportPasswordRequest(),
    }).post(data);
}

export function passwordReset(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.PASSWORD_RESET,
    }).post(data);
}

export function update(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.UPDATE_PROFILE,
        endpointParams: {id: data?.id},
    }).put(data);
}
